import { Component, OnInit } from '@angular/core';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';

import { ExpertSearchForm } from '../../_models/expertSearchForm';
import { ExpertSearchService } from '../../_services/expert-search.service';

import { ExpertService } from '../../_services/expert.service'
import { ExpertMatch } from '../../_models/expertMatch';

@Component({
  selector: 'app-experts-web-result',
  templateUrl: './experts-web-result.component.html',
  styleUrls: ['./experts-web-result.component.css']
})
export class ExpertsWebResultComponent implements OnInit {

  buyer : Buyer;
  expertSearchForm : ExpertSearchForm;
  //Temporary critère, to be replaced by a function which retruns the SupplierMatch in the right service.
  resultFound : boolean;

  //Result display static
  displayedColumns: string[] = ['id', 'regions', 'industries', 'coreCompetency', 'matching_rate', 'actions'];
  dataSource : ExpertMatch[];
  dataNumber : number;

  constructor(
    private buyerService: BuyerService,
    private expertSearchService: ExpertSearchService,
    private expertService : ExpertService
  ) { }

  ngOnInit(): void {
    this.buyer = this.buyerService.buyerValue;
    this.expertSearchForm = this.expertSearchService.expertSearchFormValue;
    // If engineering checked, then we consider the result is found for example
    // this.resultFound = this.expertSearchForm.technologies.options.find(x => x.name === "Engineering").checked;
    this.resultFound = false;

    // static result
    this.expertService.getExpertMatchs().subscribe(experts => this.dataSource = experts);
    this.dataNumber = this.dataSource.length;
  }

}
