<main class="main" *ngIf="expert">
  <!-- section start-->
  <section class="hero-block">
    <picture>
      <source srcset="assets/contact-bg.jpg" media="(min-width: 992px)"/><img class="img--bg" src="assets/contact-bg.jpg" alt="map"/>
    </picture>
    <div class="hero-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item"><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title">Send a mission</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section">
    <div class="container">
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-lg-12">
          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12">
                <h5 class="contact-form__subtitle">Send a mission</h5>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="title">Title</mat-label>
                  <input matInput formControlName="title" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.title.errors }">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="mission_type">Type of mission</mat-label>
                  <mat-select formControlName="mission_type" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mission_type.errors }">
                    <mat-option *ngFor="let missionTypeKey of missionTypeKeys" [value]="missionTypeKey">
                      {{missionType[missionTypeKey]}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="budget">Budget</mat-label>
                  <input matInput formControlName="budget" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.budget.errors }">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="currency">Currency</mat-label>
                  <mat-select formControlName="currency" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.currency.errors }">
                    <mat-option *ngFor="let currency of currencyList" [value]="currency">
                      {{currency}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label for="place">Place of execution</mat-label>
                  <input matInput formControlName="place" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.place.errors }">
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="fill">
                  <mat-label>Mission execution deadline</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="deadline">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-md-12">
                <mat-form-field appearance="fill">
                  <mat-label for="description">Mission description & deliverable expected or means to be provided</mat-label>
                  <textarea formControlName="description" matInput rows="5"></textarea>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-label for="place">Attachment : </mat-label>
                <div type="submit" class="button button--green" (click)="upload()"><span>Upload</span>
                  <svg class="icon">
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
            <div class="row top-30">
              <div class="col-xl-3 col-md-push-3">
                <div type="submit" class="button button--filled" [disabled]="loading" (click)="send()">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  <span>Send the proposal </span>
                </div>
              </div>
              <div class="col-xl-2 col-md-pull-9">
                <div type="submit" class="button button--green" (click)="goBack()"><span>Go Back</span>
                  <svg class="icon">
                    <use xlink:href="#arrow"></use>
                  </svg>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>