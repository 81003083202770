import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//Custom Module
import { RunningModule } from './running/running.module';
import { InformationModule } from './information/information.module';
import { WebcrawlingModule } from './webcrawling/webcrawling.module';
import { ExpertsWebModule } from './experts-web/experts-web.module';
import { BuyerModule } from './buyer/buyer.module';
import { ExpertModule } from './expert/expert.module';
import { UserModule } from './user/user.module';

//Custom component
import { AppComponent } from './app.component';
import { AlertComponent } from './_components/alert/alert.component';

//Authentification by JWT TOKEN
import { authInterceptorProviders } from './_helpers/auth.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

//Cookies consent
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import {ParameterCookiesComponent} from './parameter-cookies/parameter-cookies.component';

/*const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: 'localhost' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'edgeless',
  position: "bottom",
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{parameter}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">{{message}} 
      <p>{{cookiePolicyText}}
      <a aria-label="Paramétrer cookies" tabindex="0" class="cc-link" href="{{cookiePolicyHref}}" target="_blank" rel="noopener">
       {{cookiePolicyLink}}
      </a></p>
      <p>{{privacyPolicyText}}
      <a aria-label="Plus d'info privacy policy" tabindex="0" class="cc-link" href="{{privacyPolicyHref}}" target="_blank" rel="noopener">
        {{privacyPolicyLink}}
      </a></p>
    </span>
    `,
    parameter:`
    <a aria-label="allow cookies" role="button" tabindex="0" class="cc-btn cc-dismiss ">{{parameter}}</a>
    `
  },
  content: {
    message: `
    <p>Pour améliorer votre expérience, ASKAFOX accède et /ou stocke des informations sur votre terminal via des cookies, notamment pour faciliter l’utilisation de sa Plateforme, avec votre consentement ou sur la base de son intérêt légitime, lorsque vous naviguez sur la plateforme.</p>
    <p>Votre choix d’accepter ou de refuser les cookies n’affecte pas votre navigation sur la plateforme.</p>
    <p>Vous pouvez accepter ou refuser l’ensemble des traitements de vos données personnelles.</p>
    <p>Le bouton « PARAMÉTRER LES COOKIES » vous permet par ailleurs de paramétrer individuellement les finalités de traitement des cookies que vous souhaitez accepter. Votre choix d’accepter ou de refuser les cookies sera conservé pendant une durée maximale de 13  mois à l’issue de laquelle un nouveau bandeau cookies vous sera présenté.</p>
    `,
    deny: "REFUSER TOUS LES COOKIES",
    allow: "PARAMÉTRER LES COOKIES",
    parameter: "ACCEPTER TOUS LES COOKIES",
    cookiePolicyText: "Vous pouvez à tout moment modifier vos paramètres sur les cookies que nous installons en cliquant ici",
    cookiePolicyLink: 'Cookie Policy',
    cookiePolicyHref: '/information/privacy-terms',
    privacyPolicyText: "Pour plus d’informations sur la gestion de nos cookies, des données et informations que nous utilisons et sur les traitements que nous réalisons, nous vous invitons à consulter notre politique de confidentialité",
    privacyPolicyLink: 'Privacy Policy',
    privacyPolicyHref: '/information/privacy-terms'
  }
};*/

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#FF9100'
    }
  },
  position: 'bottom-right',
  theme: 'edgeless',
  type: 'opt-out',
  content:{
    href: '/information/privacy-terms'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    ParameterCookiesComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    //Lib
    NgcCookieConsentModule.forRoot(cookieConfig),
    //Custom module
    RunningModule,
    InformationModule,
    WebcrawlingModule,
    ExpertsWebModule,
    BuyerModule,
    ExpertModule,
    UserModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    // fakeBackendProvider
    authInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
