import { ExpertSearchForm } from '../_models/expertSearchForm';
import { TECHNOLOGIES_OPTIONS, REGIONS_OPTIONS, INDUSTRIES_OPTIONS, CORECOMPETENCY_OPTIONS} from './commonOption'

export const EXPERT_SEARCH_FORM : ExpertSearchForm = {
  technologies: {
    label : "Technologies",
    hint : "Multiple choices possible",
    colsNumber : 5,
    height : "250px",
    options : TECHNOLOGIES_OPTIONS,
    firstLevel : true
  },
  regions: {
    label : "Regions",
    hint : "Multiple choices possible",
    colsNumber : 2,
    height : "1300px",
    options : REGIONS_OPTIONS,
    firstLevel : true
  },
  industries: {
    label : "Industries",
    hint : "Multiple choices possible",
    colsNumber : 4,
    height : "400px",
    options : INDUSTRIES_OPTIONS,
    firstLevel : true
  },
  coreCompentencies : {
    label : "Core competencies",
    hint : "Multiple choices possible",
    colsNumber : 4,
    height : "30px",
    options : CORECOMPETENCY_OPTIONS,
    firstLevel : true
  },
  reference : {
    label : "Reference",
    hint : "Company example the expert has worked for",
    companys : [
      {label : "First company", input : ""},
      {label : "Second company", input : ""},
      {label : "Third company", input : ""}
    ]
  },
  additionalComment: {
    label : "Additional comment",
    input : ""
  }
};


