<main class="main">
  <!-- section start-->
<section class="section " style="padding: 140px 0 70px 0;">
<div class="container">
<div class="row bottom-70">
  <div class="col-12">
    <div class="heading heading--center"><span class="heading__pre-title">What we do</span>
      <h3 class="heading__title">We empower your channels</h3><span class="heading__layout">Solutions</span>
    </div>
  </div>
</div>
<div class="row offset-70">
  <div class="col-lg-4">
    <div class="advantages-item text-center">
      <div class="advantages-item__img"><img src="assets/AskaFox-1.svg" alt="icon"/></div>
      <h6 class="advantages-item__subtitle">Prevetted customers</h6>
      <p class="advantages-item__text">Tired of quoting fake RFQs and waste your time?<br>
        With AskaFox, if a customer reaches to you, he already paid the <b>"finder's fee" </b>So he is not just browsing, but "way down the funnel" as we say.  </p>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="advantages-item text-center">
      <div class="advantages-item__img"><img src="assets/AskaFox-1.svg" alt="icon"/></div>
      <h6 class="advantages-item__subtitle">Connected ecosystem</h6>
      <p class="advantages-item__text">Looking to increase your band awareness?<br>
        Meet with your peers, partners and leverage your professional relationship & industry exposure</p>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="advantages-item text-center">
      <div class="advantages-item__img"><img src="assets/AskaFox-1.svg" alt="icon"/></div>
      <h6 class="advantages-item__subtitle">Optimal collaboration</h6>
      <p class="advantages-item__text">Exceeded by the same inspection for different customers over and over again?<br>
        Prebuild your off-the-shelf audit report for whoever you authorise to access</p>
    </div>
  </div>
</div>
</div>
</section>
<!-- section end-->
<!-- section start-->
<section class="section bg--lgray" style="padding: 70px 0;">
<div class="container">
  <div class="row">
    <div class="col-xl-6 top-50 top-xl-0">
      <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
        <h3 class="heading__title">Invite-only network</h3>
      </div>
      <p class="bottom-0">It's no clubhouse but you can't register on our site.<br>
        You can either be "found" by our discovery search tool or recommanded by one of validated experts.<br>
        Only advice: be relevant and be yourself. Opportunites would knock on your door!</p>
    </div>
    <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/car_1.png" alt="img"/></div>
  </div>
</div>
</section>
<!-- section end-->
<!-- section start-->
<section class="section bg--lgray" style="padding: 70px 0;">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/car_1.png" alt="img"/></div>
      <div class="col-xl-6 top-50 top-xl-0">
        <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
          <h3 class="heading__title">Streamlined workflow</h3>
        </div>
        <p class="bottom-0">Once you're invited, complete your profile and get ready for work!</p>
      </div>
    </div>
  </div>
</section>
  <!-- section end-->
</main>