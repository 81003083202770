import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ExpertService } from '../_services/expert.service';

@Injectable({ providedIn: 'root' })
export class ExpertAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private expertService: ExpertService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const expert = this.expertService.expertValue;
        if (expert) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/expert/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}