import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Mission, MissionStatus, RejectionReason, MissionInformation } from '../_models/mission';
import { ExpertService } from './expert.service'
import { Expert } from '../_models/expert';

@Injectable({
  providedIn: 'root'
})
export class ExpertMissionsService {

  private missionsSubject: BehaviorSubject<Mission[]>;
  public missions: Observable<Mission[]>;
  private expert: Expert;
  enumMissionStatus = MissionStatus;

  constructor(
    private router: Router,
    private http: HttpClient,
    private expertService: ExpertService
  ) {
    // Subsribe to the expert logined
    this.expertService.expert$.subscribe(expert => this.expert = expert);

    this.missionsSubject = new BehaviorSubject<Mission[]>(JSON.parse(localStorage.getItem('expert_missions')));
    this.missions = this.missionsSubject.asObservable();

    // Get all the current buyer's missions
    this.getCurrentExpertMissions().subscribe();
  }

  public get missionsValue(): Mission[] {
    return this.missionsSubject.value;
  }

  getCurrentExpertMissions() {
    let expert_id = this.expert.expertId.toString();
    return this.http.get<Mission[]>(`${environment.apiUrl}/missions/expert_id/${expert_id}`).pipe(map(expert_missions => {
      // store expert missions
      localStorage.setItem('expert_missions', JSON.stringify(expert_missions || []));
      this.missionsSubject.next(expert_missions || []);
      return expert_missions;
    }));
  }

  getMissionById(mission_id : number) : Mission {
    return this.missionsValue.find(x => x.mission_id == mission_id);
  }

  // Only change status
  changeStatus(mission_id: string, newStatus : MissionStatus) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status != newStatus) {
      missionToUpdate.status = newStatus;
      return this.update(mission_id, missionToUpdate);
    }
  }

  // Change status to EXPERT_REJECTED with reason only if the previous status is MISSION_SENT
  // rejection_reason should be set in the input data
  decline(mission_id: string, data : MissionInformation) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status == MissionStatus.MISSION_SENT) {
      if (missionToUpdate.information == null) {
        missionToUpdate.information = {};
      }
      missionToUpdate.information.rejection_reason = data.rejection_reason;
      missionToUpdate.status = MissionStatus.EXPERT_REJECTED;
      return this.update(mission_id, missionToUpdate);
    }
  }

  // Change status to COUNTEROFFER with new budget only if the previous status is MISSION_SENT
  // new_budget and new_currency should be set in the input data
  counteroffer(mission_id: string, data : MissionInformation) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status == MissionStatus.MISSION_SENT) {
      if (missionToUpdate.information == null) {
        missionToUpdate.information = {};
      }
      missionToUpdate.information.new_budget = data.new_budget;
      missionToUpdate.information.new_currency = data.new_currency;
      missionToUpdate.status = MissionStatus.COUNTEROFFER;
      return this.update(mission_id, missionToUpdate);
    }
  }

  // Change status to MISSION_ACCEPTED_AND_QUIZ_ANSWERED with new budget only if the previous status is MISSION_ACCEPTED_AND_QUIZ_SENT
  // quiz_answer should be set in the input data
  answerQuiz(mission_id: string, data : MissionInformation) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status == MissionStatus.MISSION_ACCEPTED_AND_QUIZ_SENT) {
      if (missionToUpdate.information == null) {
        missionToUpdate.information = {};
      }
      // Update the current budget by the new budget
      missionToUpdate.information.quiz_answer = data.quiz_answer;
      missionToUpdate.status = MissionStatus.MISSION_ACCEPTED_AND_QUIZ_ANSWERED;
      return this.update(mission_id, missionToUpdate);
    }
  }

  private update(mission_id : string, params : Object) {
    return this.http.put(`${environment.apiUrl}/missions/${mission_id}`, params).pipe(map(x => {
          // update mission
          let missionToUpdate: Mission = this.missionsValue.find(y => y.mission_id.toString() == mission_id);
          if (missionToUpdate) {
              // update mission
              Object.assign(missionToUpdate, params);
              localStorage.setItem('expert_missions', JSON.stringify(this.missionsValue));

              // publish updated expert_missions to subscribers
              this.missionsSubject.next(this.missionsValue);
              return this.missionsValue;
          }
        }
      ));
  }

}
