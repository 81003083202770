<main class="main">
		
  <!--section start-->
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
  
      <div class="row">
        <div class="col-lg-5 location-item__lower">
          <h5 class="contacts__title" i18n>Buyer Profile Page</h5>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Compagny name :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.companyName}}</li>
            </ul>
          </div>
          <div class="col-lg-12 top-30">
            <h6 class="location-item__subtitle" i18n>Company summary :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.companySummary}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Régions :</h6>
            <ul *ngFor="let region of buyer.regions" class="list list--check list--reset">
              <li class="list__item">{{region.country}}</li>
            </ul>
          </div>
          <div class="col-sm-6 top-30">
            <h6 class="location-item__subtitle" i18n>Industries :</h6>
            <ul *ngFor="let industry of buyer.industries" class="list list--check list--reset">
              <li class="list__item">{{industry.value}}</li>
            </ul>
          </div>
          <div class="col-sm-12 top-30">
            <h6 class="location-item__subtitle" i18n>Size :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item" *ngIf="buyer.size">{{buyer.size.value}}</li>
            </ul>
          </div>
          <div class="col-sm-12 top-30">
            <h6 class="location-item__subtitle" i18n>Position in the related value chain :</h6>
            <ul *ngFor="let position of buyer.positions" class="list list--check list--reset">
              <li class="list__item">{{position.value}}</li>
            </ul>
          </div>
        </div>
        <!-- Right Block-->
        <div class="col-lg-5 location-item__lower">
          <h5 class="contacts__title" i18n>Security account</h5>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Contact person :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.firstName}} {{buyer.lastName}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Job level :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item" *ngIf="buyer.jobLevel">{{buyer.jobLevel.value}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Job Function :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item" *ngIf="buyer.jobFunction">{{buyer.jobFunction.value}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Email :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.email}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Phone :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">+{{buyer.countryCallingCode}} {{buyer.phoneNumber}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>LinkedIn :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.linkedin}}</li>
            </ul>
          </div>
          <div class="col-lg-6 top-30">
            <h6 class="location-item__subtitle" i18n>Company Website :</h6>
            <ul class="list location-list list--reset">
              <li class="list__item">{{buyer.websiteUrl}}</li>
            </ul>
          </div>
          <div class="col-sm-6 top-30">
            <h6 class="location-item__subtitle" i18n>Buyer status :</h6>
            <ul class="list list--check list--reset">
              <li class="list__item" *ngIf="buyer.buyerChartSigned" i18n>Buyer Chart signed</li>
              <li class="list__item" *ngIf="buyer.emailChecked" i18n>Email checked</li>
              <li class="list__item" *ngIf="buyer.phoneChecked" i18n>Phone checked</li>
              <li class="list__item" *ngIf="buyer.linkedInVerified" i18n>LinkedIn verified</li>
              <li class="list__item" *ngIf="buyer.alreadyCustomer" i18n>Already customer on the platform</li>
            </ul>
          </div>
        </div>
        <button class="button button--green top-20" routerLink="/buyer/account-modify" i18n>Modify 
          <svg class="icon">y
            <use xlink:href="#arrow"></use>
          </svg>
        </button>
        <!--button class="button button--green top-20 left-20" routerLink="/expert/account-resetpwd">Change password
          <svg class="icon left-20">
            <use xlink:href="#arrow"></use>
          </svg>
        </button-->
      </div>
    </div>
  </section>
  <!-- section end-->
</main>