import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared-module.module';
import { MatTableModule } from '@angular/material/table';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule} from '@angular/material/tabs';

import { ExpertsWebRoutingModule } from './experts-web-routing.module';
import { ExpertsWebChoiceComponent } from './experts-web-choice/experts-web-choice.component';
import { ExpertsWebResultComponent } from './experts-web-result/experts-web-result.component';
import { ExpertDetailComponent } from './expert-detail/expert-detail.component';

@NgModule({
  declarations: [
    ExpertsWebChoiceComponent,
    ExpertsWebResultComponent,
    ExpertDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ExpertsWebRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatListModule,
    MatIconModule,
    MatChipsModule,
    MatInputModule,
    MatTabsModule
  ]
})
export class ExpertsWebModule { }
