import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ExpertService } from '../../_services/expert.service';
import { Expert } from '../../_models/expert';

import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { passwordValidator } from '../../shared/password-validator.directive';

@Component({
  selector: 'app-expert-account-resetpwd',
  templateUrl: './expert-account-resetpwd.component.html',
  styleUrls: ['./expert-account-resetpwd.component.css']
})
export class ExpertAccountResetpwdComponent implements OnInit {

  expert : Expert;
  expertForm: FormGroup;
  expertHide = true;

  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private expertService: ExpertService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    // Current expert
    this.expert = this.expertService.expertValue;

    // Expert modify form
    this.expertForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8),passwordValidator()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8),passwordValidator()]]
    }, { validator: this.checkPasswords });
  }

  // Here we have the 'passwords' group
  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  get expertFormControl() { return this.expertForm.controls; }

  // Submit expert
  onExpertSubmit() {

    console.log(JSON.stringify(this.expertForm.value));

  }
}
