import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ExpertService } from '../../_services/expert.service';
import { Expert } from '../../_models/expert';

import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { JobFunction, JobLevel, Technology, Region, Industry, CoreCompetency } from '../../_models/enumBO';

@Component({
  selector: 'app-expert-account-modify',
  templateUrl: './expert-account-modify.component.html',
  styleUrls: ['./expert-account-modify.component.css']
})
export class ExpertAccountModifyComponent implements OnInit {

  expert : Expert;
  expertForm: FormGroup;

  loading = false;
  submitted = false;

  // Récupérer les enum de form
  technology = Technology;
  technologyLevelKeys = Object.keys(this.technology);
  region = Region;
  regionKeys = Object.keys(this.region);
  industry = Industry;
  industryKeys = Object.keys(this.industry);
  coreCompetency = CoreCompetency;
  coreCompetencyKeys = Object.keys(this.coreCompetency);
  jobFunction = JobFunction;
  jobFunctionKeys = Object.keys(this.jobFunction);
  jobLevel = JobLevel;
  jobLevelKeys = Object.keys(this.jobLevel);

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private expertService: ExpertService,
    private dialog: MatDialog) { }

  ngOnInit(): void {

    // Current expert
    this.expert = this.expertService.expertValue;

    // Expert modify form
    this.expertForm = this.formBuilder.group({
      companyName: [''],
      expertSummary: [''],
      expertRegions: ['', Validators.required],
      expertTechnologies: ['', Validators.required],
      expertIndustries: ['', Validators.required],
      expertCoreCompetencies: ['', Validators.required],
      yearsExperience: ['', [Validators.pattern("[0-9]*"), Validators.maxLength(2)]],
      references: this.formBuilder.group({
        company1: [''],
        company2: [''],
        company3: ['']
      }),
      firstName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      lastName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      jobLevel: [''],
      jobFunction: [''],
      //email: ['', Validators.required], used as username
      countryCallingCode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.maxLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      linkedIn: ['']
    });

    // Add default value
    this.initExpertForm();
  }

  get expertFormControl() { return this.expertForm.controls; }

  // Submit expert
  onExpertSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.expertForm.invalid) {
      return;
    }

    this.loading = true;
    
    //Identifier les modifications
    var modified = false;
    var contentModified= {};
    var expertModification = this.expertForm.value;
    var expertOriginal = this.expert;
    
    // Compare company name ignore case
    if (expertModification.companyName.toLowerCase() != expertOriginal.companyName.toLowerCase()) {
      contentModified["companyName"] = expertModification.companyName;
      modified = true;
    }
    if (expertModification.expertSummary != expertOriginal.expertSummary ) {
      contentModified["expertSummary"] = expertModification.expertSummary;
      modified = true;
    }
    if (expertModification.yearsExperience != expertOriginal.yearsOfExperience ) {
      contentModified["yearsExperience"] = expertModification.yearsExperience;
      modified = true;
    }
    if (expertModification.firstName != expertOriginal.firstName ) {
      contentModified["firstName"] = expertModification.firstName;
      modified = true;
    }
    if (expertModification.lastName != expertOriginal.lastName ) {
      contentModified["lastName"] = expertModification.lastName;
      modified = true;
    }
    if (expertModification.countryCallingCode != expertOriginal.countryCallingCode ) {
      contentModified["countryCallingCode"] = expertModification.countryCallingCode;
      modified = true;
    }
    if (expertModification.phoneNumber != expertOriginal.phoneNumber ) {
      contentModified["phoneNumber"] = expertModification.phoneNumber;
      modified = true;
    }
    if (expertModification.linkedIn != expertOriginal.linkedin ) {
      contentModified["linkedIn"] = expertModification.linkedIn;
      modified = true;
    }

    // Simple choice compare, loose compare to check both null or undefined, all the fields are not required
    if (expertModification.jobFunction == null && expertOriginal.jobFunction != null) {
      contentModified["jobFunction"] = null;
      modified = true;
    } else if (expertModification.jobFunction != null && expertOriginal.jobFunction == null) {
      contentModified["jobFunction"] = expertModification.jobFunction;
      modified = true;
    } else if (expertModification.jobFunction != null && expertOriginal.jobFunction != null) {
      if (expertModification.jobFunction != expertOriginal.jobFunction.value) {
        contentModified["jobFunction"] = expertModification.jobFunction;
        modified = true;
      }
    }

    if (expertModification.jobLevel == null && expertOriginal.jobLevel != null) {
      contentModified["jobLevel"] = null;
      modified = true;
    } else if (expertModification.jobLevel != null && expertOriginal.jobLevel == null) {
      contentModified["jobLevel"] = expertModification.jobLevel;
      modified = true;
    } else if (expertModification.jobLevel != null && expertOriginal.jobLevel != null) {
      if (expertModification.jobLevel != expertOriginal.jobLevel.value) {
        contentModified["jobLevel"] = expertModification.jobLevel;
        modified = true;
      }
    }

    // Multiple choice compare, all the fields are required
    if (expertModification.expertRegions.length != expertOriginal.expertRegions.length 
      || !expertOriginal.expertRegions.every(element => expertModification.expertRegions.includes(element.country))) {
      contentModified["expertRegions"] = expertModification.expertRegions;
      modified = true; 
    }
    if (expertModification.expertTechnologies.length != expertOriginal.expertTechnologies.length 
      || !expertOriginal.expertTechnologies.every(element => expertModification.expertTechnologies.includes(element.value))) {
      contentModified["expertTechnologies"] = expertModification.expertTechnologies;
      modified = true; 
    }
    if (expertModification.expertIndustries.length != expertOriginal.expertIndustries.length 
      || !expertOriginal.expertIndustries.every(element => expertModification.expertIndustries.includes(element.value))) {
      contentModified["expertIndustries"] = expertModification.expertIndustries;
      modified = true; 
    }
    if (expertModification.expertCoreCompetencies.length != expertOriginal.expertCoreCompetencies.length 
      || !expertOriginal.expertCoreCompetencies.every(element => expertModification.expertCoreCompetencies.includes(element.value))) {
      contentModified["expertCoreCompetencies"] = expertModification.expertCoreCompetencies;
      modified = true; 
    }

    // Expert references check
    if (expertModification.references == null && expertOriginal.expertReferences !=null) {
      contentModified["references"] = [];
      modified = true; 
    }
    if (expertModification.references != null && (expertOriginal.expertReferences == null 
      || expertModification.references.company1 != expertOriginal.expertReferences[0].reference
      || expertModification.references.company2 != expertOriginal.expertReferences[1].reference
      || expertModification.references.company3 != expertOriginal.expertReferences[2].reference)) {
      contentModified["references"] = [];
      contentModified["references"] [0] = this.expertForm.value.references.company1;
      contentModified["references"] [1] = this.expertForm.value.references.company2;
      contentModified["references"] [2] = this.expertForm.value.references.company3;
      modified = true;
    }

    if (modified) {
      this.expertService.update(contentModified)
      .subscribe(
        data => {
          console.log("Redirect");
          this.router.navigate(['/expert/account'], { relativeTo: this.route });
          window.scrollTo(0, 0);
          this.confirmModificationOK();
        },
        error => {
          console.log("error", error);
          this.loading = false;
        }
      );

    } else {
      console.log("No modification");
      console.log("Redirect");
      this.router.navigate(['/expert/account'], { relativeTo: this.route });
    }

  }

  // Init the expert form value
  private initExpertForm() {
    this.expertFormControl.companyName.setValue(this.expert.companyName);
    
    let expertTechnologies = [];
    this.expert.expertTechnologies.forEach(element => expertTechnologies.push(element.value));
    this.expertFormControl.expertTechnologies.setValue(expertTechnologies);

    let expertRegions = [];
    this.expert.expertRegions.forEach(element => expertRegions.push(element.country));
    this.expertFormControl.expertRegions.setValue(expertRegions);

    let expertIndustries = [];
    this.expert.expertIndustries.forEach(element => expertIndustries.push(element.value));
    this.expertFormControl.expertIndustries.setValue(expertIndustries);

    let expertCoreCompetencies = [];
    this.expert.expertCoreCompetencies.forEach(element => expertCoreCompetencies.push(element.value));
    this.expertFormControl.expertCoreCompetencies.setValue(expertCoreCompetencies);

    let expertReferences = {};
    if (this.expert.expertReferences[0] != null) {
      expertReferences['company1'] = this.expert.expertReferences[0].reference;
    }
    if (this.expert.expertReferences[1] != null) {
      expertReferences['company2'] = this.expert.expertReferences[1].reference;
    }
    if (this.expert.expertReferences[2] != null) {
      expertReferences['company3'] = this.expert.expertReferences[2].reference;
    }
    this.expertFormControl.references.setValue(expertReferences);

    if (this.expert.yearsOfExperience) {
      this.expertFormControl.yearsExperience.setValue(this.expert.yearsOfExperience);
    }
    this.expertFormControl.expertSummary.setValue(this.expert.expertSummary);
    this.expertFormControl.firstName.setValue(this.expert.firstName);
    this.expertFormControl.lastName.setValue(this.expert.lastName);
    if (this.expert.jobLevel) {
      this.expertFormControl.jobLevel.setValue(this.expert.jobLevel.value);
    }
    if (this.expert.jobFunction) {
      this.expertFormControl.jobFunction.setValue(this.expert.jobFunction.value);
    }
    this.expertFormControl.phoneNumber.setValue(this.expert.phoneNumber);
    this.expertFormControl.countryCallingCode.setValue(this.expert.countryCallingCode);
    this.expertFormControl.linkedIn.setValue(this.expert.linkedin);

  }

  private confirmModificationOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Successful!',
      message: 'Your modifications have been saved!'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
