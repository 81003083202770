<main class="main mobile-top mobile-top">
  <!-- section start-->
  <section class="section bg--lgray">
    <div class="container">
      <div class="row bottom-50">
        <div class="col-12">
          <div class="heading heading--center"><span class="heading__pre-title" i18n>Ask an expert</span>
            <h4 i18n>Pick your <span class="color--green">criterion</span> and find the best match</h4>
            <div *ngIf="!buyer">
              <p i18n> For the results, please <a routerLink="/user/login">log in</a> first.</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="expertSearchForm">
        <!--tabs start-->
        <mat-tab-group mat-align-tabs="left" animationDuration="0ms">
          <mat-tab label="{{expertSearchForm.technologies.label}}">
            <app-checkbox-group [checkboxGroup]="expertSearchForm.technologies"></app-checkbox-group>
          </mat-tab>
          <mat-tab label="{{expertSearchForm.regions.label}}">
            <app-checkbox-group [checkboxGroup]="expertSearchForm.regions"></app-checkbox-group>
          </mat-tab>
          <mat-tab label="{{expertSearchForm.industries.label}}">
            <app-checkbox-group [checkboxGroup]="expertSearchForm.industries"></app-checkbox-group>
          </mat-tab>
          <mat-tab label="{{expertSearchForm.coreCompentencies.label}}">
            <app-checkbox-group [checkboxGroup]="expertSearchForm.coreCompentencies"></app-checkbox-group>
          </mat-tab>
          <mat-tab label="{{expertSearchForm.reference.label}}">
            <div class="row"></div>
            <div class="row">
              <div class="col-12">
                <p class="bottom-10"><strong>{{expertSearchForm.reference.hint}}</strong></p>
              </div>
              <div class="col-md-4" *ngFor="let company of expertSearchForm.reference.companys">
                <mat-form-field>
                  <input class="form__field" matInput placeholder="{{company.label}}" [(ngModel)]="company.input">
                </mat-form-field>
              </div>
            </div>
          </mat-tab>
        </mat-tab-group>
        <!--tab end-->
        <div class="col-12 top-10">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label for="expertSearchForm.additionalComment">{{expertSearchForm.additionalComment.label}}</mat-label>
            <textarea [(ngModel)]="expertSearchForm.additionalComment.input" matInput rows="1"></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 bottom-10">
          <div type="submit" class="button button--promo" (click)="onSubmit()"><span i18n>Find it</span>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>