import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

declare let Calendly: any;

@Component({
  selector: 'app-calendly-widget',
  templateUrl: './calendly-widget.component.html',
  styleUrls: ['./calendly-widget.component.css']
})
export class CalendlyWidgetComponent implements OnInit {

  @ViewChild('container') container: ElementRef;

  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    console.log(this.container);

    Calendly.initInlineWidget({
      url: 'https://calendly.com/askafox?hide_gdpr_banner=1&text_color=000000&primary_color=ff9100',
      parentElement: this.container.nativeElement
    });
  }

}
