import { Component, OnInit } from '@angular/core';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

@Component({
  selector: 'app-buyer-presentation',
  templateUrl: './buyer-presentation.component.html',
  styleUrls: ['./buyer-presentation.component.css']
})
export class BuyerPresentationComponent implements OnInit {

  questionAndAnwsers = [
    {
      question: "What is AskaFox?",
      anwser: "ASKaFOX is an AI-powered SaaS tool to help industrial companies find critical suppliers.<br/><br/>We generate shortlists matching your criterion via our proprietary aggregation tool.<br/><br/>Then we qualify each lead by performing an on-site audit thanks to an extended industrial expert network nearby the sources.<br/><br/>We're best suited for search of \"five-legged sheep\" and innovative production technologies."
    },
    {
      question: "How do I start?",
      anwser: "1. Register freely as a buyer in 10 min<br/><br/>2. Go to \"Solutions\" - \"Find a supplier\": enter your search criterion<br/><br/>3. Click on \"Find it\"<br/><br/>Your shortlist will be sent to you in a maximum of 72 hours."
    },
    {
      question: "Where do you get supplier data?",
      anwser: "First of all, we DON'T get supplier data through registrations on our site, contrary to most.<br/><br/>We always search for the best sources in real-time in 3 ways:<br/><br/>- Open data points: Paid databases or consented screening from manufacturers' websites, trade shows, marketplaces, etc<br/><br/>- Membership lists from our partners: trade associations of various sectors in different countries.  That's because we believe not all good suppliers know what SEO is, or even have a website.<br/><br/>- Global trade transaction records"
    },
    {
      question: "Can I trust 100% your supplier data?",
      anwser: "No, you can't. They're only as good as a supplier claims to be.<br/><br/>That's why we provide our on-site expert audit as a service. We don't believe in validating data by crossing other data. We do it with real-world industrial know-how."
    },
    {
      question: "Who are the industry experts?",
      anwser: "Freelance or employee, retired or at work, we only match the best and you get to choose your favorite from our talent pool.<br/><br/>Depending on the vertical, we strive to find the expert with the right knowledge from the right industry at the right place for your customized mission."
    },
    {
      question: "How do I pay?",
      anwser: "Let's start with when you DON'T pay:<br/>As long as the search results are not fully satisfying, you can get your money back.<br/><br/>If you just hate googling but love supplier visits despite the covid, you only pay for the shortlist.<br/><br/>If you want a turnkey solution with an audited supplier on a silver plate, then you pay by mission.<br/><br/>If you want to be creative, contact us. We're all ears."
    }
  ];


  constructor(private googleAnalyticsEventsService : GoogleAnalyticsEventsService) { }

  ngOnInit(): void {
  }

  expand() {
    this.googleAnalyticsEventsService.emitEvent("buyer-presentation", "buyer_qA_expand", "Buyer Q&A expansion", 0);
  }

}
