<div *ngFor="let question of questions">
  <div *ngIf="question">
    <mat-label>{{question.question}}</mat-label>
    <section class="webcrawlingchoice-section">
      <span class="webcrawlingchoice-list-section">
        <mat-checkbox class="webcrawlingchoice-margin"
                      [checked]="question.allchecked"
                      (change)="setAll(question, $event.checked)">
          {{question.options.name}}
        </mat-checkbox>
      </span>
      <span class="webcrawlingchoice-list-section">
        <ul>
          <li *ngFor="let suboption of question.options.subOptions">
            <mat-checkbox [(ngModel)]="suboption.checked" (ngModelChange)="verifyAllOptions(question)">
              {{suboption.name}}
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </section>
  </div>
</div>
<button mat-button routerLink="/webcrawling/result">Submit my choice</button>