import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExpertsWebChoiceComponent } from './experts-web-choice/experts-web-choice.component';
import { ExpertsWebResultComponent } from './experts-web-result/experts-web-result.component';
import { ExpertDetailComponent } from './expert-detail/expert-detail.component';

const routes: Routes = [
  { 
    path: 'choice', 
    component: ExpertsWebChoiceComponent,
    data: {
      title: 'Expert Mission Request - ASKaFOX',
      description:'ASKaFOX connects with the most competent experts and assigns your sourcing related missions nearby the suppliers’ factory.'
    }
  },
  { path: 'result', component: ExpertsWebResultComponent },
  { path: 'expert-detail/:id', component: ExpertDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpertsWebRoutingModule { }
