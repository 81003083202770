import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { SupplierSearchResult } from '../_models/supplierSearchResult';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor() { }

  /*getSupplierMatchs(): Observable<SupplierSearchResult[]> {
    return of(SUPPLIER_MATCH);
  }

  getSupplier(id: number): Observable<SupplierSearchResult> {
    return of(SUPPLIER_MATCH.find(SupplierMatch => SupplierMatch.id === id));
  }*/
}
