import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Buyer } from '../_models/buyer';
import { TokenStorageService } from './token-storage.service';

const BUYER_KEY = 'auth-buyer';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  private buyerSubject$: BehaviorSubject<Buyer>;
  public buyer$: Observable<Buyer>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenStorageService : TokenStorageService
  ) {
    this.buyerSubject$ = new BehaviorSubject<Buyer>(JSON.parse(window.sessionStorage.getItem(BUYER_KEY)) || null);
    this.buyer$ = this.buyerSubject$.asObservable();
  }

  public get buyerValue(): Buyer {
    if (this.buyerSubject$) {
      return this.buyerSubject$.value;
    } else {
      return null;
    }
    
  }

  login(username: string, password: string): Observable<any> {
    var formData: FormData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return this.http.post<any>(`${environment.apiUrl}/public/buyers/login`, formData)
      .pipe(
        //tap((data: any) => {
        //  console.log("login", data);
        //}),
        map((data: any) => {
          this.tokenStorageService.saveToken(data.token);
          return data;
        })
      );
  }

  getCurrentBuyer(): Observable<Buyer> {
    return this.http.get<Buyer>(`${environment.apiUrl}/buyers/currentBuyer`)
      .pipe(
        map((data: Buyer) => {
          window.sessionStorage.setItem(BUYER_KEY, JSON.stringify(data));
          this.buyerSubject$.next(data);
          return data;
        })
      )
  }

  logout() {
    // remove user from local storage and set current user to null
    this.buyerSubject$.next(null);
    this.tokenStorageService.signOut();
  }

  register(buyer: Buyer) {
    return this.http.post(`${environment.apiUrl}/public/buyers/register`, buyer);
  }

  
  update(expert : Object) {
    return this.http.post(`${environment.apiUrl}/buyers/modify`, expert)
      .pipe(
        map((data: Buyer) => {
          window.sessionStorage.setItem(BUYER_KEY, JSON.stringify(data));
          this.buyerSubject$.next(data);
          return data;
      }));
  }

  /*getAll() {
    return this.http.get<Buyer[]>(`${environment.apiUrl}/buyers`);
  }

  getById(buyer_id: string) {
    return this.http.get<Buyer>(`${environment.apiUrl}/buyers/${buyer_id}`);
  }

  delete(buyer_id: string) {
    return this.http.delete(`${environment.apiUrl}/buyers/${buyer_id}`)
      .pipe(map(x => {
        // auto logout if the logged in user deleted their own record
        if (buyer_id == this.buyerValue.user_name) {
          this.logout();
        }
        return x;
      }));
  }*/

}
