import { Options } from '../_models/options';

export const TECHNOLOGIES_OPTIONS : Options[] = [
  { name : "Engineering", checked : false},
  { name : "Sheetmetal working, cutting", checked : false},
  { name : "Boilermaking, forging", checked : false},
  { name : "Welding", checked : false},
  { name : "Machining", checked : false},
  { name : "Casting", checked : false},
  { name : "Surface treatment finishing", checked : false},
  { name : "Gears wires, springs", checked : false},
  { name : "Tube & pipes", checked : false},
  { name : "Fasteners", checked : false},
  { name : "Electrical industry", checked : false},
  { name : "Fittings", checked : false},
  { name : "Hydraulics & pneumatics", checked : false},
  { name : "Robotics & automatism", checked : false},
  { name : "3d printing", checked : false},
  { name : "Polymer", checked : false},
  { name : "Electronic", checked : false},
  { name : "Electronic: printed circuit boards", checked : false},
  { name : "Electronic: others", checked : false},
  { name : "Wood, textile & ceramic", checked : false},
  { name : "Assembly", checked : false},
  { name : "Wire harness", checked : false},
  { name : "Controlling & measurement", checked : false},
  { name : "Tooling", checked : false},
  { name : "Packaging", checked : false},
  { name : "On -site servicing", checked : false},
  { name : "Special purpose machine", checked : false},
  { name : "Others", checked : false}
];

export const REGIONS_OPTIONS : Options[] = [{
  name: 'Leading competitive countries (LCC)',
  checked: false,
  subOptions: 
  [{
    name: 'Asia',
    checked: false,
    subOptions: [
      {name: 'Bangladesh', checked: false},
      {name: 'Cambodia', checked: false},
      {name: 'China (PRC)', checked: false},
      {name: 'Hong Kong S.A.R of China', checked: false},
      {name: 'India', checked: false},
      {name: 'Indonesia', checked: false},
      {name: 'Malaysia', checked: false},
      {name: 'Pakistan', checked: false},
      {name: 'Philippines', checked: false},
      {name: 'Sri Lanka', checked: false},
      {name: 'Taiwan', checked: false},
      {name: 'Thailand', checked: false},
      {name: 'Vietnam', checked: false}
    ]
  },
  {
    name: 'Central and Eastern Europe',
    checked: false,
    subOptions: [
      {name: 'Albania', checked: false},
      {name: 'Bosnia & Herzegovina', checked: false},
      {name: 'Bulgaria', checked: false},
      {name: 'Croatia', checked: false},
      {name: 'Czech Republic', checked: false},
      {name: 'Hungary', checked: false},
      {name: 'Latvia', checked: false},
      {name: 'Lithuania', checked: false},
      {name: 'Poland', checked: false},
      {name: 'Romania', checked: false},
      {name: 'Serbia', checked: false},
      {name: 'Slovak Republic', checked: false},
      {name: 'Slovenia', checked: false},
      {name: 'Ukraine', checked: false}
    ]
  },
  {
    name: 'Middle East & Africa',
    checked: false,
    subOptions: [
      {name: 'Algeria', checked: false},
      {name: 'Egypt', checked: false},
      {name: 'Israel', checked: false},
      {name: 'Morocco', checked: false},
      {name: 'South Africa', checked: false},
      {name: 'Tunisia', checked: false},
      {name: 'Turkey', checked: false}
    ]
  }, 
  {
    name: 'Latin America', 
    checked: false,
    subOptions: [
      {name: 'Argentina', checked: false},
      {name: 'Brazil', checked: false},
      {name: 'Chile', checked: false},
      {name: 'Mexico', checked: false}
    ]
  }]
},
{
  name: 'Other countries',
  checked: false,
  subOptions: [
    {name: 'Australia', checked: false},
    {name: 'Austria', checked: false},
    {name: 'Belgium', checked: false},
    {name: 'Canada', checked: false},
    {name: 'Finland', checked: false},
    {name: 'Denmark', checked: false},
    {name: 'France', checked: false},
    {name: 'Netherlands', checked: false},
    {name: 'Germany', checked: false},
    {name: 'Greece', checked: false},
    {name: 'Italy', checked: false},
    {name: 'Ireland', checked: false},
    {name: 'Japan', checked: false},
    {name: 'New Zealand', checked: false},
    {name: 'Norway', checked: false},
    {name: 'Portugal', checked: false},
    {name: 'Russia', checked: false},
    {name: 'Singapore', checked: false},
    {name: 'South Korea', checked: false},
    {name: 'Spain', checked: false},
    {name: 'Sweden', checked: false},
    {name: 'Switzerland', checked: false},
    {name: 'UK', checked: false},
    {name: 'USA', checked: false}
  ]
}
];

export const INDUSTRIES_OPTIONS : Options[] = [
  { name : "Aerospace and aviation", checked : false},
  { name : "Agricultural and forestry machinery", checked : false},
  { name : "Automation and control", checked : false},
  { name : "Automotive and vehicle", checked : false},
  { name : "Boiler, container, and tank", checked : false},
  { name : "Clean room technology", checked : false},
  { name : "Construction and architectural supplies", checked : false},
  { name : "Consumer goods", checked : false},
  { name : "Chemical industry", checked : false},
  { name : "Defense", checked : false},
  { name : "Distribution", checked : false},
  { name : "Environment", checked : false},
  { name : "Electrical industry", checked : false},
  { name : "Fasteners", checked : false},
  { name : "Food", checked : false},
  { name : "Furniture", checked : false},
  { name : "Handling/logistics/storage", checked : false},
  { name : "Heating, ventilation, and air conditioning", checked : false},
  { name : "Household appliance industry", checked : false},
  { name : "Hydraulic and pneumatic industry", checked : false},
  { name : "ICT (Information and communication technology)", checked : false},
  { name : "Infrastructure (bridges, highway, tunnel)", checked : false},
  { name : "Lighting industry", checked : false},
  { name : "Machine tool manufacturing", checked : false},
  { name : "Maintenance Repair & Overhaul", checked : false},
  { name : "Mechanical engineering", checked : false},
  { name : "Medical & pharmaceutical technology", checked : false},
  { name : "Measurement and control", checked : false},
  { name : "Motors and Transmission", checked : false},
  { name : "Naval & shipbuilding", checked : false},
  { name : "Non-fossil energy", checked : false},
  { name : "Office supply", checked : false},
  { name : "Oil & Gas", checked : false},
  { name : "Packaging industry", checked : false},
  { name : "Paper and printing", checked : false},
  { name : "Plant & process construction", checked : false},
  { name : "Precision, mechatronics, and optics", checked : false},
  { name : "Railway & rolling stock", checked : false},
  { name : "Special purpose machinery", checked : false},
  { name : "Others", checked : false}
];

export const CERTIFICATIONS_OPTIONS : Options[] = [
  { name : "DIN 18800-7:2008 Class A-C (minor welding cert.)", checked : false},
  { name : "DIN 18800-7:2008 Class D_E (major welding cert.)", checked : false},
  { name : "DIN 4113", checked : false},
  { name : "DIN EN 1090", checked : false},
  { name : "DIN EN ISO 3834", checked : false},
  { name : "DIN 6701-2", checked : false},
  { name : "EN 15085", checked : false},
  { name : "EN 9100", checked : false},
  { name : "EN ISO 13485", checked : false},
  { name : "IATF 16949 :2016", checked : false},
  { name : "ISO 14001", checked : false},
  { name : "ISO 9001:2008", checked : false},
  { name : "ISO 9001:2015", checked : false},
  { name : "ISO-TS 16949:2002", checked : false},
  { name : "ISO-TS 16949:2009", checked : false},
  { name : "IRIS", checked : false},
  { name : "CEFRI", checked : false},
  { name : "JAR 145", checked : false},
  { name : "OHSAS 18000", checked : false},
  { name : "OHSAS 18001", checked : false},
  { name : "PART 145", checked : false},
  { name : "QSF-A", checked : false},
  { name : "QSF-B", checked : false},
  { name : "SA 8000", checked : false},
  { name : "VDA 6.1", checked : false},
  { name : "VDA 6.2", checked : false},
  { name : "VDA 6.4", checked : false}
];

export const SIZE_OPTIONS : Options[] = [
  { name : "Small & medium (<250p)", checked : false},
  { name : "Not so small (250-1000p)", checked : false},
  { name : "Sizable (1000p-5000p)", checked : false},
  { name : "Big guy (>5000p)", checked : false}
];

export const BUSINESS_MODEL_OPTIONS : Options[] = [
  { name : "Trader", checked : false},
  { name : "Manufacturer", checked : false},
  { name : "Integrator", checked : false}
];

export const POSITION_OPTIONS : Options[] = [
  { name : "Tier 1 Supplier", checked : false},
  { name : "Tier 2 Supplier", checked : false},
  { name : "Tier 3 Supplier", checked : false}
];

export const COMPETITIVE_FORCE_OPTIONS : Options[] = [
  { name : "Price", checked : false},
  { name : "Leadtime", checked : false},
  { name : "Technical capabilities", checked : false},
  { name : "Production capacity", checked : false},
  { name : "Production flexibility", checked : false},
  { name : "Customer responsiveness", checked : false},
  { name : "Operation integrity", checked : false}
];

export const CORECOMPETENCY_OPTIONS : Options[] = [
  { name : "Quality System", checked : false},
  { name : "Market Insight", checked : false},
  { name : "Technology Knowhow", checked : false}
]