<main class="main">
  <!-- section start-->
  <section class="hero-block" style="padding: 0 0 70px 0;">
    <picture>
      <source srcset="assets/become_buyer.jfif" media="(min-width: 992px)"/><img class="img--bg" src="assets/become_buyer.jfif" alt="img"/>
    </picture>
    <div class="hero-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item"><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title">Become our buyer</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section" style="padding: 70px 0;">
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12">
          <div class="heading heading--center"><span class="heading__pre-title">What we do</span>
            <h3 class="heading__title">We Provide Answers</h3><span class="heading__layout">Solutions</span>
          </div>
        </div>
      </div>
      <div class="row offset-70">
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO1.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Pre-screened supplier discovery</h6>
            <p class="advantages-item__text">Exclusive access to certified supplier base<br>
              Fast-track scout via open yet selective community<br>
              Market scan through intelligence agregation tool</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO1.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Geo-specific locational expert network</h6>
            <p class="advantages-item__text">Connect business with skilled experts<br>
              Hunt for actionable insight, on-demand knowledge<br>
              Retrieve relevant, timely, economical answers</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO1.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Satisfied or free policy</h6>
            <p class="advantages-item__text">We aim to accomplish "right the first time"<br>
              However if not, we commit to work on your case until full satisfaction</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgrays" style="padding: 70px 0;">
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-6 top-50 top-xl-0">
          <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
            <h3 class="heading__title">Technology - driven supplier search engine</h3>
          </div>
          <div class="row top-40">
            <div class="col-sm-6 col-lg-5 col-xl-10">
              <h6 class="bottom-20">Features</h6>
              <ul class="list list--check list--reset">
                <li class="list__item">We enhance the depth of search capability through our consolidated data points on open web</li>
                <li class="list__item">We simplify the process by adding industry specific , category related filters to keep only the relevant</li>
                <li class="list__item">We shortlist potential sources for review and further screening</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/wf_supplier_search.gif" alt="img"/></div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgrays" style="padding: 70px 0;">
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/wf_send_mission.gif" alt="img"/></div>
        <div class="col-xl-6 top-50 top-xl-0">
          <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
            <h3 class="heading__title">Expert-assigned sourcing mission</h3>
          </div>
          <div class="row top-40">
            <div class="col-sm-6 col-lg-5 col-xl-10">
              <h6 class="bottom-20">Features</h6>
              <ul class="list list--check list--reset">
                <li class="list__item">Find, assign, manage, and pay experts for procurement advisory</li>
                <li class="list__item">On-site audit, market insight, ...etc through a few clicks</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section faq">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="faq__title">Q & A</h3>
          <div *ngFor="let qA of questionAndAnwsers">
            <mat-accordion class="accordion accordion--primary" multi>
              <mat-expansion-panel class="accordion__text-block" (afterExpand)="expand()">
                <mat-expansion-panel-header>
                  <div class="accordion__title-block">
                    <h5 class="accordion__title">{{qA.question}}</h5>
                  </div>
                </mat-expansion-panel-header>
                  <div [innerHTML]="qA.anwser"></div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>
