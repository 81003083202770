import { Expert } from '../_models/expert';
import { JobFunction, JobLevel, Industry, Region, Technology, CoreCompetency } from '../_models/enumBO';

export const EXPERTS: Expert[] = [
  { 
    expertId: 1,
    companyName: "BeeINGO",
    expertSummary: "Sourcing expert on LCC countries with knowledge on rail, nuclear and fastener business.Ready to perform mission of sourcing, auditing in Europe & Asia.",
    expertTechnologies: [
      {technologyId : 1, value : Technology.SHEETMETAL_WORKING_CUTTING},
      {technologyId : 2, value : Technology.WELDING},
      {technologyId : 3, value : Technology.FASTENERS},
      {technologyId : 4, value : Technology.ASSEMBLY}
    ],
    expertRegions: [
      {
        regionId : 1,
        region : "",
        category : "",
        country : Region.CHINA
      },
      {
        regionId : 1,
        region : "",
        category : "",
        country : Region.FRANCE
      }
    ],
    expertIndustries : [
      {industryId : 1, value :Industry.FASTENERS},
      {industryId : 2, value :Industry.NON_FOSSIL_ENERGY},
      {industryId : 3, value :Industry.RAILWAY_AND_ROLLING_STOCK}
    ],
    expertCoreCompetencies : [
      {coreCompetencyId : 3, value : CoreCompetency.MARKET_INSIGHT}
    ],
    yearsOfExperience : 10,
    expertReferences : [{expertReferenceId : 1, expertId: 1, reference :"Spie"},
      {expertReferenceId : 2, expertId: 1, reference :"Bombardier"},
      {expertReferenceId : 3, expertId: 1, reference :"Bufab"}],
    expertChartSigned: true,
    "emailChecked": true,
    "phoneChecked": true,
    "linkedInVerified": true,
    missionPerformed : false,
    userId: 201,
    firstName: "Hanyin",
    lastName: "CHEN",
    jobLevel: {jobLevelId : 3, value : JobLevel.VP_DIRECTOR},
    jobFunction : {jobFunctionId : 3, value : JobFunction.SUPPLY_CHAIN},
    email: "panesonic@gmail.com",
    countryCallingCode: 33,
    phoneNumber: 645645874,
    linkedin: "https://www.linkedin.com/in/hanyin-chen/"
  },
  { 
    expertId: 2,
    companyName: "Bombardier Transport",
    expertSummary: "Sourcing expert on LCC countries with knowledge on rail, nuclear and fastener business.Ready to perform mission of sourcing, auditing in Europe & Asia.",
    expertTechnologies: [
      {technologyId : 1, value : Technology.SHEETMETAL_WORKING_CUTTING},
      {technologyId : 2, value : Technology.WELDING},
      {technologyId : 4, value : Technology.ASSEMBLY}
    ],
    expertRegions: [
      {
        regionId : 1,
        region : "",
        category : "",
        country : Region.FRANCE
      }
    ],
    expertIndustries : [
      {industryId : 3, value :Industry.RAILWAY_AND_ROLLING_STOCK}
    ],
    expertCoreCompetencies : [
      {coreCompetencyId : 3, value : CoreCompetency.QUALITY_SYSTEM}
    ],
    yearsOfExperience : 15,
    expertReferences : [{expertReferenceId : 4, expertId: 1, reference :"Valeo"},
          {expertReferenceId : 5, expertId: 1, reference :"Bombardier"}],
    "expertChartSigned": true,
    "emailChecked": true,
    "phoneChecked": true,
    "linkedInVerified": true,
    "missionPerformed": false,
    userId: 202,
    firstName: "Christophe",
    lastName: "Lefevre",
    jobLevel: {jobLevelId : 3, value : JobLevel.MANAGER},
    jobFunction : {jobFunctionId : 3, value : JobFunction.QUALITY_AUDIT},
    email: "christophe.lefevre@bombardier.com",
    countryCallingCode: 33,
    phoneNumber: 327235300,
    linkedin: "linkedin.com/in/christophe-lefevre-6127787a"
  }/*,
   { 
    expertId: 3,
    companyName: "Bufab",
    expertSummary: "Quality Management in fasteners & petrochemical environment, 4 languages speaking",
    expertTechnologies: [
      Technology.FASTENERS
    ],
    expertRegions: [
      Region.SWEDEN,
      Region.FRANCE
    ],
    expertIndustries : [
      Industry.FASTENERS
    ],
    expertCoreCompetencies : [
      CoreCompetency.QUALITY_SYSTEM
    ],
    yearsOfExperience : 10,
    expertReferences : ["Solvay","Bufab"],
    "expertChartSigned": true,
    "emailChecked": true,
    "phoneChecked": true,
    "linkedInVerified": true,
    "missionPerformed": false,
    userId: 203,
    firstName: "Stephanie",
    lastName: " DOFS",
    jobLevel: JobLevel.MANAGER,
    jobFunction : JobFunction.QUALITY_AUDIT,
    email: "stephanie.dofs@bufab.com",
    countryCallingCode: 33,
    phoneNumber: 678910123,
    linkedin: "linkedin.com/in/stephanie-dofs-b777a5b4"
  }*/
];
