import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InformationRoutingModule } from  './information-routing.module';
import { SharedModule } from '../shared/shared-module.module';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule} from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';

// Lib 
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AboutComponent } from './about/about.component';
import { ProductComponent } from './product/product.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { GeneralTermsComponent } from './general-terms/general-terms.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { BuyerPresentationComponent } from './buyer-presentation/buyer-presentation.component';
import { ExpertPresentationComponent } from './expert-presentation/expert-presentation.component';
import { SupplierPresentationComponent } from './supplier-presentation/supplier-presentation.component';
import { HomeComponent } from './home/home.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

@NgModule({
  declarations: [
    AboutComponent,
    ProductComponent,
    PrivacyTermsComponent,
    LearnMoreComponent,
    GeneralTermsComponent,
    BlogComponent,
    ContactComponent,
    BuyerPresentationComponent,
    ExpertPresentationComponent,
    SupplierPresentationComponent,
    HomeComponent,
    MentionsLegalesComponent
  ],
  imports: [
    CommonModule,
    InformationRoutingModule,
    SharedModule,
    MatListModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTabsModule,
    MatExpansionModule,
    SlickCarouselModule
  ]
})
export class InformationModule { }
