<header class="page-header">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-8 col-md-6 col-lg-3 d-flex align-items-center">
        <div class="page-header__logo"><a routerLink="/information/home"><img src="assets/logo_black.svg"
              alt="Logo ASKaFOX" /></a></div>
      </div>
      <div class="col-lg-5 d-none d-flex justify-content-between align-items-center">
        <!-- main menu start-->
        <ul class="main-menu">
          <li class="main-menu__item main-menu__item--has-child" routerLinkActive="main-menu__item--active"><a class="main-menu__link"
              href="javascript:void(0);"><span i18n>Product</span></a>
            <!-- sub menu start-->
            <ul class="main-menu__sub-list sub-list">
              <li><a routerLink="/supplier-search/choice"><span i18n>Supplier search</span></a></li>
              <li><a routerLink="/experts-web/choice"><span i18n>Expert audit</span></a></li>
            </ul>
            <!-- sub menu end-->
          </li>
          <li class="main-menu__item" routerLinkActive="main-menu__item--active">
            <a class="main-menu__link" routerLink="/information/about"><span i18n>About</span></a>
          </li>
          <li class="main-menu__item " routerLinkActive="main-menu__item--active">
            <a class="main-menu__link" routerLink="/information/contact"><span i18n>Contact</span></a>
          </li>
          <li class="main-menu__item" routerLinkActive="main-menu__item--active">
            <a class="main-menu__link" routerLink="/information/blog"><span i18n>Blog</span></a>
          </li>
        </ul>
        <!-- main menu end-->
      </div>
      <div class="col-4 col-md-6 col-lg-4 d-none d-lg-flex justify-content-end align-items-center">
        <!--div class="lang-block">
          <div class="lang-icon"><img class="img--contain" src="assets/gbr.png" alt="img" /></div>
          <ul class="lang-select">
            <li class="lang-select__item lang-select__item--active"><span>EN</span>
              <!ul class="lang-select__sub-list">
                <li ><a class="lang-select__sub-list_a" routerLink="/information/home" (click)="languageFRClick()">FR</a></li>
              </ul>
            </li>
          </ul>
        </div-->

        <!--Login-->
        <div *ngIf="buyer || expert; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <div *ngIf="buyer; then buyerBlock else expertBlock"></div>
          <ng-template #buyerBlock>
            <div class="page-header__profil"> 
              <!-- main menu start-->
              <ul class="main-menu">
                <li class="main-menu__item main-menu__item--has-child">
                  <div class="main-menu__link"><span i18n>Profile</span></div>
                  <!-- sub menu start-->
                  <ul class="main-menu__sub-list sub-list">
                    <li><a routerLink="/buyer/account"><span i18n>Account</span></a></li>
                    <li><a routerLink="/buyer/profile"><span i18n>Mission</span></a></li>
                    <li><a routerLink="/user/login" (click)="logout()"><span i18n>Log out</span></a></li>
                  </ul>
                  <!-- sub menu end-->
                </li>
              </ul>
            </div>
          </ng-template>
          <ng-template #expertBlock>
            <div class="page-header__profil"> 
              <!-- main menu start-->
              <ul class="main-menu">
                <li class="main-menu__item main-menu__item--has-child">
                  <div class="main-menu__link"><span i18n>Profile</span></div>
                  <!-- sub menu start-->
                  <ul class="main-menu__sub-list sub-list">
                    <li><a routerLink="/expert/account"><span i18n>Account</span></a></li>
                    <li><a routerLink="/expert/profile"><span i18n>Mission</span></a></li>
                    <li><a routerLink="/user/login" (click)="logout()"><span i18n>Log out</span></a></li>
                  </ul>
                  <!-- sub menu end-->
                </li>
              </ul>
            </div>
          </ng-template>
        </ng-template>
        <ng-template #elseBlock>
          <div class="main-menu__item" routerLinkActive="main-menu__item--active">
            <a class="main-menu__link" routerLink="/user/login" (click)="login()"><span i18n>Login</span></a>
          </div>
          <div type="submit" class="button button--filled" routerLink="/user/registry" i18n>Sign up</div>
        </ng-template>
      </div>
    </div>
  </div>
</header>