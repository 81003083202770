import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { BuyerService } from '../../_services/buyer.service';
import { ExpertService } from '../../_services/expert.service';
import { AlertService } from '../../_services/alert.service';
import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { JobFunction, JobLevel, Technology, Region, Industry, Size, Position, CoreCompetency } from '../../_models/enumBO';
import { passwordValidator } from '../../shared/password-validator.directive';

declare let gtag: Function;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return (invalidCtrl || invalidParent);
  }
}

@Component({
  selector: 'app-user-registry',
  templateUrl: './user-registry.component.html',
  styleUrls: ['./user-registry.component.css']
})
export class UserRegistryComponent implements OnInit {

  buyerForm: FormGroup;
  buyerHide = true;

  expertForm: FormGroup;
  expertHide = true;

  loading = false;
  submitted = false;

  // Récupérer les enum de form
  jobFunction = JobFunction;
  jobFunctionKeys = Object.keys(this.jobFunction);
  jobLevel = JobLevel;
  jobLevelKeys = Object.keys(this.jobLevel);
  technology = Technology;
  technologyLevelKeys = Object.keys(this.technology);
  region = Region;
  regionKeys = Object.keys(this.region);
  industry = Industry;
  industryKeys = Object.keys(this.industry);
  size = Size;
  sizeKeys = Object.keys(this.size);
  position = Position;
  positionKeys = Object.keys(this.position);
  coreCompetency = CoreCompetency;
  coreCompetencyKeys = Object.keys(this.coreCompetency);

  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService,
    private expertService: ExpertService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService
  ) { }

  ngOnInit(): void {

    // Buyer form
    this.buyerForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      companyName: ['', Validators.required],
      companySummary: [''],
      regions: [''],
      industries: [''],
      size: [''],
      positions: [''],
      firstName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      lastName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      jobLevel: [''],
      jobFunction: [''],
      //email: ['', Validators.required], used as username
      countryCallingCode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.maxLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      linkedIn: [''],
      website: [''],
      password: ['', [Validators.required, Validators.minLength(8), passwordValidator()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), passwordValidator()]],
      // termServiceAccepted: ['', Validators.requiredTrue]
    }, { validator: this.checkPasswords });

    // Expert Form
    this.expertForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
      companyName: [''],
      expertSummary: [''],
      expertRegions: ['', Validators.required],
      expertTechnologies: ['', Validators.required],
      expertIndustries: ['', Validators.required],
      expertCoreCompetencies: ['', Validators.required],
      yearsExperience: ['', [Validators.pattern("[0-9]*"), Validators.maxLength(2)]],
      references: this.formBuilder.group({
        company1: [''],
        company2: [''],
        company3: ['']
      }),
      firstName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      lastName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      jobLevel: [''],
      jobFunction: [''],
      //email: ['', Validators.required], used as username
      countryCallingCode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.maxLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      linkedIn: [''],
      password: ['', [Validators.required, Validators.minLength(8),passwordValidator()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8),passwordValidator()]],
      //termServiceAccepted: ['', Validators.requiredTrue]
    }, { validator: this.checkPasswords });
  }

  // convenience getter for easy access to form fields
  get buyerFormControl() { return this.buyerForm.controls; }
  get expertFormControl() { return this.expertForm.controls; }

  // here we have the 'passwords' group
  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  onBuyerSubmit() {

    this.googleAnalyticsEventsService.emitEvent("register", "buyer_sign_up_before", "Buyer resgister before", 0);

    this.submitted = true;
    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.buyerForm.invalid) {
      return;
    }

    this.loading = true;

    var buyerDTO = this.buyerForm.value;
    buyerDTO.email = buyerDTO.userName;
    this.buyerService.register(buyerDTO)
      .subscribe(
        data => {
          this.alertService.success('Registration successful', { keepAfterRouteChange: true });
          console.log("Redirect");
          this.router.navigate(['/user/login'], { relativeTo: this.route });
          window.scrollTo(0, 0);
          this.googleAnalyticsEventsService.emitEvent("register", "buyer_sign_up_ok", "Buyer resgister after OK", 1);
          this.confirmRegistrationOK();
        },
        error => {
          this.googleAnalyticsEventsService.emitEvent("register", "buyer_sign_up_failed", "Buyer resgister after KO", 2);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  onExpertSubmit() {

    this.googleAnalyticsEventsService.emitEvent("register", "expert_sign_up_before", "Expert resgister before", 0);

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.expertForm.invalid) {
      return;
    }

    this.loading = true;

    var expertDTO = this.expertForm.value;
    expertDTO.email = expertDTO.userName;
    // Transform references to array
    var referencesDTO = new Array();
    referencesDTO[0] = this.expertForm.value.references.company1;
    referencesDTO[1] = this.expertForm.value.references.company2;
    referencesDTO[2] = this.expertForm.value.references.company3;
    expertDTO.references = referencesDTO;

    this.expertService.register(expertDTO)
      .subscribe(
        data => {
          this.alertService.success('Registration successful', { keepAfterRouteChange: true });
          console.log("Redirect");
          this.router.navigate(['/user/login'], { relativeTo: this.route });
          window.scrollTo(0, 0);
          this.googleAnalyticsEventsService.emitEvent("register", "expert_sign_up_ok", "Expert resgister after OK", 1);
          this.confirmRegistrationOK();
        },
        error => {
          console.log("error", error);
          this.googleAnalyticsEventsService.emitEvent("register", "expert_sign_up_failed", "Expert resgister after OK", 2);
          this.alertService.error(error);
          this.loading = false;
        }
      );
  }

  private confirmRegistrationOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Registration successful',
      message: 'Congratulations! Your registration is completed and you\'re now offcially among the foxes.'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
