import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AuthService } from '../../_services/auth.service';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { passwordValidator } from '../../shared/password-validator.directive';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm : FormGroup;
  resetPasswordHide : boolean = true;
  loading : boolean = false;
  token : string;

  constructor(
    private formBuilder: FormBuilder,
    private authService : AuthService,
    private route : ActivatedRoute,
    private router: Router,
    private dialog : MatDialog
  ) { }

  ngOnInit(): void {
    // Buyer form
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8), passwordValidator()]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), passwordValidator()]]
    }, { validator: this.checkPasswords });
    
    // Params token
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
  }

  // here we have the 'passwords' group
  checkPasswords(group: FormGroup) {
    let pass = group.controls.password.value;
    let confirmPass = group.controls.confirmPassword.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  // convenience getter for easy accesss to form fields
  get resetPasswordFormControl() { return this.resetPasswordForm.controls; }

  onSubmit(): void {
    if (!this.loading && this.resetPasswordForm.valid) {
      this.loading = true;

      //Add current URL
      var value = this.resetPasswordForm.value;
      value["token"] = this.token;

      console.log(value);
      this.authService.resetPassword(value).subscribe(
        data => {
          console.log("email sent");
          if (data) {
            this.router.navigate(['/user/login'], { relativeTo: this.route });
            window.scrollTo(0, 0);
            this.resetPasswordOK();
          } else {
            this.errorResetPassword();
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  private resetPasswordOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Password reset',
      message: 'Your password has been modified successfully.'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }

  private errorResetPassword(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    var messageErreur = "Something went wrong...Contact us or try later please.";

    dialogConfig.data = {
      title: 'Oops!',
      message: messageErreur
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
