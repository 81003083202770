import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-account-resetpwd',
  templateUrl: './buyer-account-resetpwd.component.html',
  styleUrls: ['./buyer-account-resetpwd.component.css']
})
export class BuyerAccountResetpwdComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
