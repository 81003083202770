import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CheckboxGroupComponent } from './checkbox-group/checkbox-group.component';
import { DialogMessageComponent } from './dialog-message/dialog-message.component';
import { CalendlyWidgetComponent } from './calendly-widget/calendly-widget.component';
import { MaxChoiceValidatorDirective } from './max-choice-validator.directive';
import { PasswordValidatorDirective } from './password-validator.directive';

@NgModule({
  declarations: [CheckboxGroupComponent, DialogMessageComponent, CalendlyWidgetComponent, MaxChoiceValidatorDirective, PasswordValidatorDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatExpansionModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [ 
    CheckboxGroupComponent,
    DialogMessageComponent,
    CalendlyWidgetComponent,
    MaxChoiceValidatorDirective,
    PasswordValidatorDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatGridListModule,
    MatExpansionModule,
    MatButtonModule,
    MatDialogModule
  ],
  entryComponents: [DialogMessageComponent]
})
export class SharedModule { }
