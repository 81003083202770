import { Component, OnInit } from '@angular/core';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

@Component({
  selector: 'app-expert-presentation',
  templateUrl: './expert-presentation.component.html',
  styleUrls: ['./expert-presentation.component.css']
})
export class ExpertPresentationComponent implements OnInit {

  questionAndAnwsers = [
    {
      question: "What is AskaFox?",
      anwser: "ASKaFOX is an AI-powered SaaS tool to help industrial companies find critical suppliers.<br/><br/>We generate shortlists matching your criterion via our proprietary aggregation tool.<br/><br/>Then we qualify each lead by performing an on-site audit thanks to an extended industrial expert network nearby the sources.<br/><br/>We're best suited for search of \"five-legged sheep\" and innovative production technologies."
    },
    {
      question: "How do you find us?",
      anwser: "\"Good wine needs no bush.\" - W.Shakespeare<br/>If you are well recognized in your domain of expertise, we will know about you :-)<br/><br/>Other than that, we use the following channels:<br/>- Word of mouth referrals through other experts<br/>- Both online and offline professional networks (LinkedIn, APICS,...)<br/>- Your registrations on our website"
    },
    {
      question: "Why become a Fox?",
      anwser: "Other than getting paid and helping a peer, you also establish your personal brand in your specific domain of expertise.<br/><br/>Later on, you can exploit that fame and authority for yourself as well as for the company you work for.<br/><br/>In popular words, it's the best \"inbound marketing\" tool for an industry expert."
    },
    {
      question: "How to become a Fox?",
      anwser: "1.  You already are if you have:  skills in one technology, knowledge about an industry, availability within a zone<br/><br/>2. Take 10 min to register freely on our website as an expert"
    },
    {
      question: "How do I get paid?",
      anwser: "You don't need to have your own company, you only need a bank account.<br/><br/>We would pay the pre-agreed fees to you 30 days upon confirmation of customer success in the currency you desire."
    },
    {
      question: "Am I allowed to work as a Fox?",
      anwser: "The short answer is YES. Depending on which country you live in,  just make sure:<br/><br/>1. You stay away from conflicts of interest with your current employer. We would help you identify those topics.<br/><br/>2. You declare your earned revenue, pretty much the same way as if you were an Airbnb host.<br/><br/>Any other questions? Reach out to us, our legal partner may provide advice for you.<br/><br/>Take France as an example, for a full-time employee questioning his aptitude to run part-time missions, here is the answer:<br/><br/><a href=\"https://leparticulier.lefigaro.fr/jcms/p1_1322958/je-suis-salarie-et-une-activite-occasionnelle-de-consultant-m-est-proposee-a-l-exterieur-de-la-societe-qui-m-emploie-puis-je-cumuler-ces-deux-activites\">Clause d'exclusivité</a>"
    }
  ];

  constructor(private googleAnalyticsEventsService : GoogleAnalyticsEventsService) { }

  ngOnInit(): void {
  }

  expand() {
    this.googleAnalyticsEventsService.emitEvent("expert-presentation", "expert_qA_expand", "Expert Q&A expansion", 0);
  }

}
