import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ExpertService } from '../../_services/expert.service';
import { ExpertMissionsService } from '../../_services/expert-missions.service'
import { AlertService } from '../../_services/alert.service';
import { Expert } from '../../_models/expert';
import { Mission, MissionStatus } from '../../_models/mission';

@Component({
  selector: 'app-counter-offer',
  templateUrl: './counter-offer.component.html',
  styleUrls: ['./counter-offer.component.css']
})
export class CounterOfferComponent implements OnInit {

  expert : Expert;
  mission : Mission;
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private expertService: ExpertService,
    private expertMissionsService : ExpertMissionsService,
    private alertService: AlertService
  ) { }

  ngOnInit(): void {
    this.expert = this.expertService.expertValue;
    this.getMission();
    // New budget
    this.form = this.formBuilder.group({
      newBudget: ['', Validators.required]
    });
  }

  getMission(){
    const id = +this.route.snapshot.paramMap.get('id');
    this.mission = this.expertMissionsService.getMissionById(id);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.submitted = true;

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;

    /*this.expertMissionsService.updateBudget(this.mission.mission_id.toString(), this.form.value.newBudget).subscribe(
      data => {
          this.alertService.success('Send mission successful', { keepAfterRouteChange: true });
          console.log("Redirect");
          this.router.navigate(['/expert/profile'], { relativeTo: this.route });
      },
      error => {
          this.alertService.error(error);
          this.loading = false;
      });*/
  }
}
