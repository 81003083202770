import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const informationModule = () => import('./information/information.module').then(x => x.InformationModule);
const webcrawlingModule = () => import('./webcrawling/webcrawling.module').then(x => x.WebcrawlingModule);
const supplierSearchModule = () => import('./supplier-search/supplier-search.module').then(x => x.SupplierSearchModule);
const expertsWebModule = () => import('./experts-web/experts-web.module').then(x => x.ExpertsWebModule);
const buyerModule = () => import('./buyer/buyer.module').then(x => x.BuyerModule);
const expertModule = () => import('./expert/expert.module').then(x => x.ExpertModule);
const userModule = () => import('./user/user.module').then(x => x.UserModule);

const routes: Routes = [
  { 
    path: '', 
    redirectTo: '/information/home', 
    pathMatch: 'full'
  },
  { path: 'information', loadChildren: informationModule },
  { path: 'webcrawling', loadChildren: webcrawlingModule },
  { path: 'supplier-search', loadChildren: supplierSearchModule },
  { path: 'experts-web', loadChildren: expertsWebModule },
  { path: 'buyer', loadChildren: buyerModule },
  { path: 'expert', loadChildren: expertModule },
  { path: 'user', loadChildren: userModule },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  imports: [RouterModule.forRoot(routes, {
    // Restore the last scroll position
    scrollPositionRestoration: "enabled",
    scrollOffset: [0, 0],
    // Enable scrolling to anchors
    anchorScrolling: "enabled",
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
