<main class="main">
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-12 location-item__lower">
            <h5 class="contacts__title">Modifier your password</h5>
            <div class="col-lg-3 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="password">New password*</mat-label>
                <input matInput placeholder="New Password" formControlName="password" class="form-control"
                  [ngClass]="{ 'is-invalid': loading && resetPasswordFormControl.password.errors }"
                  [type]="resetPasswordHide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="resetPasswordHide = !resetPasswordHide"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="resetPasswordHide">
                  <mat-icon>{{resetPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.controls.password.hasError('required')">
                  Please enter your new password
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.controls.password.hasError('minlength') || resetPasswordForm.controls.password.hasError('passwordError')">
                  The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-3 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="confirmPassword">Confirm password*</mat-label>
                <input matInput placeholder="Confirm password" formControlName="confirmPassword"
                  class="form-control"
                  [ngClass]="{ 'is-invalid': loading && resetPasswordFormControl.confirmPassword.errors }"
                  [type]="resetPasswordHide ? 'password' : 'text'">
                <button mat-icon-button matSuffix (click)="resetPasswordHide = !resetPasswordHide"
                  [attr.aria-label]="'Hide password'" [attr.aria-pressed]="resetPasswordHide">
                  <mat-icon>{{resetPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="resetPasswordForm.controls.password.hasError('required')">
                  Please confirm your new password
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.hasError('notSame')">
                  Passwords do not match
                </mat-error>
                <mat-error *ngIf="resetPasswordForm.controls.password.hasError('minlength') || resetPasswordForm.controls.password.hasError('passwordError')">
                  The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <button class="button button--green top-20" type="submit">Confirmer 
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </section>
</main>
