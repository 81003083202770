import { Component, OnInit } from '@angular/core';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';

@Component({
  selector: 'app-buyer-account',
  templateUrl: './buyer-account.component.html',
  styleUrls: ['./buyer-account.component.css']
})
export class BuyerAccountComponent implements OnInit {

  buyer : Buyer;

  constructor(private buyerService: BuyerService) { }

  ngOnInit(): void {
    this.buyer = this.buyerService.buyerValue;
  }

}
