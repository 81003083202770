export enum MissionStatus {
  MISSION_SENT = "Mission sent to expert",
  EXPERT_REJECTED = "Expert Rejected",
  EXPERT_ACCEPTED = "Expert Accepted",
  COUNTEROFFER = "Counteroffer",
  BUYER_ACCEPTED_COUNTEROFFER = "Buyer Accepted counteroffer",
  BUYER_REJECTED_COUNTEROFFER = "Buyer Rejected counteroffer",
  MISSION_ACCEPTED_AND_QUIZ_SENT = "Mission accepted and quiz sent",
  MISSION_ACCEPTED_AND_QUIZ_ANSWERED = "Mission accepted and quiz answered",
  MISSION_ACCEPTED_AND_ANSWER_VALIDATED = "Mission accepted and answer validated",
  MISSION_ACCEPTED_BUT_ANSWER_NOT_VALIDATED = "Mission accepted but answer not validated",
  MISSION_ACCEPTED_AND_NOT_PAID_FOR = "Mission accepted and not paid for",
  MISSION_ACCEPTED_AND_PAID_FOR = "Mission accepted and paid for"
}

export enum RejectionReason {
  NOT_AVAILABLE = "Not available",
  NOT_INTERSETED = "Not interested",
  CONFLICT_OF_INTEREST = "Conflict of interest",
  OTHERS = "Others"
}

export enum MissionType {
  SUPPLY_MARKET_INSIGHT = "Supply market insight: 1-hour phone call",
  TECHNOLOGY_CONSULTING = "Technology consulting: 1-hour phone call",
  ON_SITE_AUDIT_HALF_DAY = "On site Audit: half day",
  ON_SITE_AUDIT_WHOLE_DAY = "On site Audit: 1 day"
}

export interface MissionInformation {
  quiz? : string;
  quiz_answer? : string;
  rejection_reason? : RejectionReason;
  new_budget?: number;
  new_currency?: string;
}

export interface Mission {
  mission_id: number;
  expert_id: number;
  buyer_id: number;
  title: string;
  sent_date: Date;
  mission_type: MissionType;
  budget: number;
  currency: string;
  place: string;
  deadline: Date;
  description: string;
  status : MissionStatus;
  livrable?: string;
  information?: MissionInformation
}