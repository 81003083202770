import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';
import { ExpertSearchForm } from '../../_models/expertSearchForm';
import { ExpertSearchService } from '../../_services/expert-search.service';

@Component({
  selector: 'app-experts-web-choice',
  templateUrl: './experts-web-choice.component.html',
  styleUrls: ['./experts-web-choice.component.css']
})
export class ExpertsWebChoiceComponent implements OnInit {

  buyer : Buyer;
  expertSearchForm : ExpertSearchForm;
  loading = false;

  constructor(
    private buyerService : BuyerService,
    private expertSearchService : ExpertSearchService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.buyer = this.buyerService.buyerValue;
    this.expertSearchForm = this.expertSearchService.expertSearchFormValue;
  }

  onSubmit() : void {

    if (!this.loading) {
      this.loading = true;
      this.expertSearchService.searchExpert(this.expertSearchForm, this.buyer).subscribe(
        data => {
          console.log("Search info sent");
          this.router.navigate(["experts-web/result"]);
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

}
