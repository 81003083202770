import { Question } from '../_models/question';

export const WEBCRAWLING_QUESTIONS : Question[] = [
  { 
    question: "What location are you looking for ?", 
    allchecked : false, 
    options : {
      name: 'I don\'t care',
      checked: false,
      subOptions: [
        {name: 'Europe', checked: false},
        {name: 'Asia', checked: false}
      ]
    }
  },
  { 
    question: "What industriel secteur are you focusing on ?", 
    allchecked : false, 
    options : {
      name: 'I don\'t care',
      checked: false,
      subOptions: [
        {name: 'Electrique1', checked: false},
        {name: 'Electrique2', checked: false}
      ]
    }
  }
];

export const EXPERT_WEB_QUESTIONS : Question[] = [
  { 
    question: "What location are you ready to work for?", 
    allchecked : false, 
    options : {
      name: 'I don\'t care',
      checked: false,
      subOptions: [
        {name: 'Europe', checked: false},
        {name: 'Asia', checked: false}
      ]
    }
  },
  { 
    question: "What industrial sectors are your focusing on ?", 
    allchecked : false, 
    options : {
      name: 'I don\'t care',
      checked: false,
      subOptions: [
        {name: 'Auto', checked: false},
        {name: 'Pharma', checked: false},
        {name: 'Aero', checked: false}
      ]
    }
  }
];
