import { Component, OnInit } from '@angular/core';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';
import { ExpertService } from '../../_services/expert.service';
import { Expert } from '../../_models/expert';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

interface Language {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  buyer : Buyer = null;
  expert : Expert = null
  
  languages: Language[] = [{value : "french", viewValue : "FR"}, {value : "english", viewValue : "EN"}];
  selected = 'french';

  constructor(
    private buyerService : BuyerService, 
    private expertService : ExpertService,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService) { }

  ngOnInit(): void {
    this.buyerService.buyer$.subscribe(data => this.buyer = data);
    this.expertService.expert$.subscribe(data => this.expert = data);
  }

  logout() {

    if (this.buyer) {
      this.buyerService.logout();
    }
    
    if (this.expert) {
      this.expertService.logout();     
    }

  }

  languageFRClick(){
    this.googleAnalyticsEventsService.emitEvent("header", "language_fr_click", "Language FR click", 0);
  }

  login() {
    this.googleAnalyticsEventsService.emitEvent("header", "login", "Login in", 0);
  }

}
