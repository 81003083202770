<div class="horizontal-tabs__item" id="horizontal-tabs__item-1">
  <p class="bottom-30"> <i >{{checkboxGroup.hint}}</i></p>
  <!--Block 1 Start-->
  <mat-grid-list cols="{{checkboxGroup.colsNumber}}" rowHeight="fit" class="grid-list-checkbox" [ngStyle]="{'height' : checkboxGroup.height}">
    <mat-grid-tile *ngFor="let subOptionLevel0 of checkboxGroup.options" >
      <div *ngIf="subOptionLevel0.subOptions; then subOptionsBlock else checkboxBlock"></div>
      <ng-template #subOptionsBlock>
        <ul>
          <mat-checkbox [(ngModel)]="subOptionLevel0.checked"
                        (change)="setAllOptionsLevel1(subOptionLevel0, $event.checked)">
            {{subOptionLevel0.name}}
          </mat-checkbox>
          <li *ngFor="let subOptionLevel1 of subOptionLevel0.subOptions" class="suboption-margin">
            <mat-checkbox [(ngModel)]="subOptionLevel1.checked"
                          (change)="setAllOptionsLevel2AndVerifyAllOptionsLevel1(subOptionLevel0, subOptionLevel1, $event.checked)">
              {{subOptionLevel1.name}}
            </mat-checkbox>
            <ul>
              <li *ngFor="let subOptionLevel2 of subOptionLevel1.subOptions">
                <mat-checkbox [(ngModel)]="subOptionLevel2.checked"
                          (change)="verifyAllOptionsLevel2(subOptionLevel0, subOptionLevel1, $event.checked)">
                  {{subOptionLevel2.name}}
                </mat-checkbox>
              </li>
            </ul>
          </li>
        </ul>
      </ng-template>
      <ng-template #checkboxBlock>
        <mat-checkbox [(ngModel)]="subOptionLevel0.checked" >
          {{subOptionLevel0.name}}
        </mat-checkbox>
      </ng-template>
    </mat-grid-tile>
  </mat-grid-list>
</div>