<main class="main mobile-top">
  <!-- section start-->
  <section class="section team pb-0">
    <!--change css ligne 11458 - 19022021-->
    <div class="team__bg">
      <img class="section--bg t50 r0" src="assets/testimonials-bg.png" alt="map" /></div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-sm-12 col-lg-12 col-xl-12">
          <div class="team-item">
            <div class="team-item__img"><img class="about_us_img" 
                src="assets/about/Photographe_General_Linkedin_Banniere.png" alt="ASKaFOX Headoffice Lyon" />
            </div>
            <div class="team-item__description">
              <h6 class="team-item__name" i18n>The Social Fox</h6><span class="team-item__position"></span>
              <ul class="socials socials--lblue list--reset">
                <li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/in/hanyin-chen" target="_blank" title="LinkedIN">
                  <svg class="icon">
                    <use xlink:href="#linkedin"></use>
                  </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://twitter.com/HanyinChen" target="_blank" title="Twitter">
                    <svg class="icon">
                      <use xlink:href="#twitter"></use>
                    </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://askafox.medium.com" target="_blank" title="Medium">
                  <svg class="icon">
                    <use xlink:href="#medium"></use>
                  </svg></a></li>
                <li class="socials__item"><a class="socials__link" href="https://askafox.substack.com" target="_blank" title="Substack">
                    <svg class="icon">
                      <use xlink:href="#substack"></use>
                    </svg></a></li>
              </ul>
            </div>
          </div>
          <!--div class="col-xl-12">
            <div class="heading"><span class="heading__pre-title">Team</span>
              <h3 class="heading__title bottom-20">Story about the fox</h3>
              <p><b>AskaFox</b> was founded by Hanyin & Sean in 2020 in Lyon, France, where the 18th-century silk merchants
                invented the profession of international sourcing.
              </p>
              <p><b>Hanyin</b> was a procurement executive in nuclear and rail industry, who joined several eminent procurement
                programs like European Pressurized Reactor and Bombardier OMNEO trains.</p>
              <p>Exhausted by endless travel of supplier due diligence and "mouth-to-ear" sourcing artisanship , he
                decided to tackle the problem head-on.</p>
              <p><b>Sean</b>, had 15 year expertise helping major players like IBM and SNCF developping their market leading
                MRO software - Maximo. And he hates travelling too :-)</p>
              <p>Together they're leading adventurers to explore the unchartered water of <b>sourcing 4.0</b>, where AI-powered
                tech meets industrial know-how.</p>
            </div>
          </div-->
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgrays" style="padding: 70px 0;">
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-6 top-50 top-xl-0">
          <div class="heading bottom-20"><span class="heading__pre-title" i18n>Who we are</span>
            <h3 class="heading__title" i18n>Who we are</h3>
          </div>
          <div class="row top-40">
            <div class="col-sm-6 col-lg-5 col-xl-10">
              <p i18n>AskaFox was founded by Hanyin & Sean in late 2020 at Lyon, 
                France - where the 18th-century silk merchants invented the profession of international sourcing.</p>
              <p i18n>Hanyin is a procurement executive in nuclear and rail industry 
                with proven record on eminent industrial programs like European Pressurized Reactor and Bombardier OMNEO trains.</p>
              <p i18n>Sean, as the chief technical officer, draws inspirations from his long years’ expertise developing 
                top MRO software for IBM and SNCF.</p>
            </div>
          </div>
        </div>
        <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/about/about_us_1.jpg" alt="Global sourcing"/></div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgrays" style="padding: 70px 0;">
    <img class="section--bg t50 r0" src="assets/contacts-map.png" alt="map" />
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/about/about_us_3.png" alt="Supply chain risk"/></div>
        <div class="col-xl-6 top-50 top-xl-0">
          <div class="heading bottom-20"><span class="heading__pre-title" i18n>What we believe</span>
            <h3 class="heading__title" i18n>What we believe</h3>
          </div>
          <div class="row top-40">
            <div class="col-sm-6 col-lg-5 col-xl-10">
              <p i18n>“All eggs shall not be in the same basket”  - We always keep one eye on opportunity and the other on risk.</p>
              <p i18n>Fully conscious of data quality issues from manufacturing world, we are vigilant on “Garbage in, Garbage out”.</p>
              <p i18n>That’s why we aim to provide the ultimate answer to global supply chain crisis 
                combining data efficiency and human insight.</p>
              <p i18n>Let us help you take back control, from the source.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section faq" style="padding: 70px 0;">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="faq__title" i18n>Q & A</h3>
          <mat-tab-group>
            <mat-tab label="Buyers">
              <div *ngFor="let qA of buyerQuestionAndAnwsers">
                <mat-accordion class="accordion accordion--primary" multi>
                  <mat-expansion-panel class="accordion__text-block" (afterExpand)="buyerExpand()">
                    <mat-expansion-panel-header>
                      <div class="accordion__title-block">
                        <h5 class="accordion__title">{{qA.question}}</h5>
                      </div>
                    </mat-expansion-panel-header>
                      <div [innerHTML]="qA.anwser"></div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
            <mat-tab label="Experts">
              <div *ngFor="let qA of expertQuestionAndAnwsers">
                <mat-accordion class="accordion accordion--primary" multi>
                  <mat-expansion-panel class="accordion__text-block" (afterExpand)="expertExpand()">
                    <mat-expansion-panel-header>
                      <div class="accordion__title-block">
                        <h5 class="accordion__title">{{qA.question}}</h5>
                      </div>
                    </mat-expansion-panel-header>
                      <div [innerHTML]="qA.anwser"></div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>