import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Mission, MissionStatus, MissionInformation } from '../_models/mission';
import { BuyerService } from './buyer.service'
import { Buyer } from '../_models/buyer';

@Injectable({
  providedIn: 'root'
})
export class BuyerMissionsService {

  private missionsSubject: BehaviorSubject<Mission[]>;
  public missions: Observable<Mission[]>;
  private buyer: Buyer;

  constructor(
    private router: Router,
    private http: HttpClient,
    private buyerService: BuyerService
  ) {
    // Subsribe to the buyer logined
    this.buyerService.buyer$.subscribe(buyer => this.buyer = buyer);
    this.missionsSubject = new BehaviorSubject<Mission[]>(JSON.parse(localStorage.getItem('buyer_missions')));
    this.missions = this.missionsSubject.asObservable();

    // Get all the current buyer's missions
    this.getCurrentBuyerMissions().subscribe();
  }

  public get missionsValue(): Mission[] {
    return this.missionsSubject.value;
  }

  getCurrentBuyerMissions() {
    let buyer_id = this.buyer.buyerId.toString();
    return this.http.get<Mission[]>(`${environment.apiUrl}/missions/buyer_id/${buyer_id}`).pipe(map(buyer_missions => {
      // store buyer missions
      localStorage.setItem('buyer_missions', JSON.stringify(buyer_missions || []));
      this.missionsSubject.next(buyer_missions || []);
      return buyer_missions;
    }));
  }

  addMission(mission : Mission) {
    return this.http.post(`${environment.apiUrl}/missions/add`, mission);
  }

  // Only change status
  changeStatus(mission_id: string, newStatus : MissionStatus) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status != newStatus) {
      missionToUpdate.status = newStatus;
      return this.update(mission_id, missionToUpdate);
    }
  }

  // Change the status to BUYER_ACCEPTED_COUNTEROFFER only if the previous status is COUNTEROFFER
  updateBudget(mission_id: string) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && missionToUpdate.status == MissionStatus.COUNTEROFFER) {
      // Update the current budget by the new budget
      missionToUpdate.budget = missionToUpdate.information.new_budget;
      missionToUpdate.currency = missionToUpdate.information.new_currency;
      // Clear the new budget
      delete missionToUpdate.information.new_budget
      delete missionToUpdate.information.new_currency
      
      missionToUpdate.status = MissionStatus.BUYER_ACCEPTED_COUNTEROFFER;
      return this.update(mission_id, missionToUpdate);
    }
  }

  // Change the status to MISSION_ACCEPTED_AND_QUIZ_SENT only if the previous status is BUYER_ACCEPTED_COUNTEROFFER or EXPERT_ACCEPTED
  // quiz should be set in the input data
  sendQuiz(mission_id: string, data : MissionInformation) {
    let missionToUpdate: Mission = this.missionsValue.find(x => x.mission_id.toString() == mission_id);
    if (missionToUpdate && (missionToUpdate.status == MissionStatus.EXPERT_ACCEPTED || missionToUpdate.status == MissionStatus.BUYER_ACCEPTED_COUNTEROFFER)) {
      if (missionToUpdate.information == null) {
        missionToUpdate.information = {};
      }
      // Update the current budget by the new budget
      missionToUpdate.information.quiz = data.quiz;
      missionToUpdate.status = MissionStatus.MISSION_ACCEPTED_AND_QUIZ_SENT;
      return this.update(mission_id, missionToUpdate);
    }
  }

  private update(mission_id : string, params : Object) {
    return this.http.put(`${environment.apiUrl}/missions/${mission_id}`, params).pipe(map(x => {
        // update mission
        let missionToUpdate: Mission = this.missionsValue.find(y => y.mission_id.toString() == mission_id);
        if (missionToUpdate) {
            // update mission
            Object.assign(missionToUpdate, params);
            localStorage.setItem('expert_missions', JSON.stringify(this.missionsValue));
            // publish updated expert_missions to subscribers
            this.missionsSubject.next(this.missionsValue);
            return this.missionsValue;
        }
      }
    ));
  }

}
