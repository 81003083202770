<main class="main">
		
  <!--section start-->
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
      <form [formGroup]="buyerForm" (ngSubmit)="onBuyerSubmit()">
        <div class="row">
          <div class="col-lg-5 location-item__lower">
            <h5 class="contacts__title" i18n>Modify your profile</h5>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="companyName" i18n>Company name*</mat-label>
                <input matInput placeholder="Company name" formControlName="companyName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.companyName.errors }">
                <mat-error *ngIf="buyerForm.controls.companyName.hasError('required')" i18n>
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="companySummary" i18n>Company summary (A brief introduction about your activities)</mat-label>
                <textarea class="form__message message--large" formControlName="companySummary"
                  matInput></textarea>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="regions" i18n>Region</mat-label>
                <mat-select formControlName="regions" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.regions.errors }" multiple>
                  <mat-option *ngFor="let regionKey of regionKeys" [value]="region[regionKey]" 
                    [disabled]="buyerFormControl.regions.value?.length > 2 && !buyerFormControl.regions.value?.includes(region[regionKey])">
                    {{region[regionKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="industries" i18n>Industry</mat-label>
                <mat-select formControlName="industries" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.industries.errors }" multiple>
                  <mat-option *ngFor="let industryKey of industryKeys" [value]="industry[industryKey]"
                    [disabled]="buyerFormControl.industries.value?.length > 2 && !buyerFormControl.industries.value?.includes(industry[industryKey])">
                    {{industry[industryKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="size" i18n>Size</mat-label>
                <mat-select formControlName="size" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.size.errors }">
                  <mat-option *ngFor="let sizeKey of sizeKeys" [value]="size[sizeKey]">
                    {{size[sizeKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="positions" i18n>Position in the related value chain :</mat-label>
                <mat-select formControlName="positions" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.positions.errors }" multiple>
                  <mat-option *ngFor="let positionKey of positionKeys" [value]="position[positionKey]">
                    {{position[positionKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <!-- Right Block-->
          <div class="col-lg-5 location-item__lower">
            <!--h5 class="contacts__title"></h5-->
            <div class="col-lg-12 top-60">
              <mat-form-field appearance="fill">
                <mat-label for="firstName" i18n>First name*</mat-label>
                <input matInput placeholder="First name" formControlName="firstName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.firstName.errors }">
                <mat-error *ngIf="buyerForm.controls.firstName.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="lastName" i18n>Last name*</mat-label>
                <input matInput placeholder="Last name" formControlName="lastName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.lastName.errors }">
                <mat-error *ngIf="buyerForm.controls.lastName.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="jobLevel" i18n>Job Level</mat-label>
                <mat-select formControlName="jobLevel" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.jobLevel.errors }">
                  <mat-option *ngFor="let jobLevelKey of jobLevelKeys" [value]="jobLevel[jobLevelKey]">
                    {{jobLevel[jobLevelKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="jobFunction" i18n>Job Function</mat-label>
                <mat-select formControlName="jobFunction" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.jobFunction.errors }">
                  <mat-option *ngFor="let jobFunctionKey of jobFunctionKeys" [value]="jobFunction[jobFunctionKey]">
                    {{jobFunction[jobFunctionKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="countryCallingCode" i18n>Country code*</mat-label>
                <input matInput placeholder="Number after 00" size="3" maxLength="3"
                  formControlName="countryCallingCode" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.countryCallingCode.errors }">
                  <mat-error *ngIf="buyerForm.controls.countryCallingCode.hasError('required')">
                    The field is required.
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="phoneNumber" i18n>Phone number*</mat-label>
                <input matInput placeholder="Phone number" formControlName="phoneNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.phoneNumber.errors }">
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error *ngIf="buyerForm.controls.phoneNumber.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="linkedIn" i18n>LinkedIn</mat-label>
                <input matInput placeholder="LinkedIn" formControlName="linkedIn" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.linkedIn.errors }">
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="website" i18n>Web site</mat-label>
                <input matInput placeholder="Web site" formControlName="website" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && buyerFormControl.website.errors }">
              </mat-form-field>
            </div>
          </div>
          <button class="button button--green top-20" type="submit" i18n>Confirm
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
          <button class="button button--green top-20 left-20" routerLink="/buyer/account">
            <span i18n>Cancel</span>
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </section>
  <!-- section end-->
</main>
