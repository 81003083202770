<footer class="page-footer"><img class="section--bg b0 r0" src="assets/footer-bg.png" alt="map"/>
  <div class="container">
    <div class="row">
      <div class="col-md-6 col-lg-4">
        <h6 class="page-footer__title title--white" i18n>Discover</h6>
        <ul class="page-footer__menu list--reset">
          <li><a routerLink="/information/home" i18n>Home</a></li>
          <li><a routerLink="/user/login" i18n>Login</a></li>
          <li><a routerLink="/information/about" i18n>About</a></li>
          <li><a routerLink="/supplier-search/choice" i18n>Supplier search</a></li>
          <li><a routerLink="/information/user/registry" i18n>Sign up</a></li>
          <li><a routerLink="/information/blog" i18n>Blog</a></li>
          <li><a routerLink="/experts-web/choice" i18n>Expert audit</a></li>
          <li><a routerLink="/information/contact" i18n>Contact</a></li>
        </ul>
      </div>
      <div class="col-md-6 col-lg-5 col-xl-4 offset-xl-1 top-40 top-md-0">
        <h6 class="page-footer__title title--white" i18n>France</h6>
        <div class="page-footer__details">
          <p><span>2bis Rue Tête d'Or, 69006 Lyon</span></p>
          <p><a href="tel:++33678158718">+33 6 78 15 87 18</a></p>
        </div>
        <h6 class="page-footer__title title--white" i18n>Canada</h6>
        <div class="page-footer__details">
          <p><span>3500-800 rue du Square-Victoria, Montréal H4Z 1A1</span></p>
          <p><a href="tel:++33678158718">+1 438 372 8658</a></p>
          <p><a href="mailto:contact@askafox.com"><b>contact@askafox.com</b></a></p>
        </div>
      </div>
      <div class="col-lg-3 d-flex flex-column justify-content-between align-items-sm-center align-items-lg-end top-40 top-lg-0">
        <div class="page-footer__logo"><a href="index.html"><img src="assets/logo_white.svg" alt="Logo ASKaFOX"/></a></div>
        <ul class="socials list--reset">
          <li class="socials__item"><a class="socials__link" href="https://www.linkedin.com/in/hanyin-chen" target="_blank" title="LinkedIN">
            <svg class="icon">
              <use xlink:href="#linkedin"></use>
            </svg></a></li>
          <li class="socials__item"><a class="socials__link" href="https://twitter.com/HanyinChen" target="_blank" title="Twitter">
              <svg class="icon">
                <use xlink:href="#twitter"></use>
              </svg></a></li>
          <li class="socials__item"><a class="socials__link" href="https://askafox.medium.com" target="_blank" title="Medium">
            <svg class="icon">
              <use xlink:href="#medium"></use>
            </svg></a></li>
          <li class="socials__item"><a class="socials__link" href="https://askafox.substack.com" target="_blank" title="Substack">
              <svg class="icon">
                <use xlink:href="#substack"></use>
              </svg></a></li>
        </ul>
      </div>
    </div>
    <div class="row top-50">
      <div class="col-lg-6 text-sm-center text-lg-left">
        <div class="page-footer__privacy"><a routerLink="/information/general-terms" i18n>Terms and conditions</a><a routerLink="/information/privacy-terms" i18n>Privacy policy</a><a routerLink="/information/mentions-legales">Mentions légales</a></div>
      </div>
      <div class="col-lg-6 text-sm-center text-lg-right">
        <div class="page-footer__copyright">© AskaFox. All rights reserved. Version 3.2.5</div>
      </div>
    </div>
  </div>
</footer>