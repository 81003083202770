import { Component, OnInit } from '@angular/core';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  buyerQuestionAndAnwsers = [
    {
      question: "What is AskaFox?",
      anwser: "ASKaFOX is an AI-powered SaaS tool to help industrial companies find critical suppliers.<br/><br/>We generate shortlists matching your criterion via our proprietary aggregation tool.<br/><br/>Then we qualify each lead by performing an on-site audit thanks to an extended industrial expert network nearby the sources.<br/><br/>We're best suited for search of \"five-legged sheep\" and innovative production technologies."
    },
    {
      question: "How do I start?",
      anwser: "1. Register freely as a buyer in 10 min<br/><br/>2. Go to \"Product\" - \"Supplier search\": enter your search criterion<br/><br/>3. Click on \"Find it\"<br/><br/>Your shortlist will be sent to you in a maximum of 72 hours upon confirmation."
    },
    {
      question: "Where do you get supplier data?",
      anwser: "First of all, we DON'T get supplier data through self registrations on our site, contrary to most.<br/><br/>We always search for the best sources in real-time in 3 ways:<br/><br/>- Open data points: Paid databases or consented screening from manufacturers' websites, trade shows, marketplaces, etc<br/><br/>- Membership lists from our partners: trade associations of various sectors in different countries.  That's because we believe a \"SEO-good \" supplier may not be a \"TCO-good\" one.<br/><br/>- Global trade transaction records"
    },
    {
      question: "Can I trust 100% your supplier data?",
      anwser: "No, you can't. They're only as good as a supplier claims to be.<br/><br/>That's why we provide our on-site expert audit as a service. We don't believe in validating data by crossing other data. We do it with real-world industrial know-how."
    },
    {
      question: "Who are the industry experts?",
      anwser: "Freelance or employee, retired or at work, we only match the best and you get to choose your favorite from our talent pool.<br/><br/>Depending on the vertical, we strive to find the expert with the right knowledge from the right industry at the right place for your customised mission."
    },
    {
      question: "How do I pay?",
      anwser: "Let's start with when you DON'T pay:<br/>As long as the search results are not fully satisfying, you can get your money back.<br/><br/>Either you pay for a specific search query, then you get the curated shortlist.<br/><br/Or you want a turnkey solution with a supplier audited in factory by a nearby expert, then you pay by mission.<br/><br/>If you want to be creative, contact us. We're all ears."
    }
  ];

  expertQuestionAndAnwsers = [
    {
      question: "What is AskaFox?",
      anwser: "ASKaFOX is an AI-powered SaaS tool to help industrial companies find critical suppliers.<br/><br/>We generate shortlists matching your criterion via our proprietary aggregation tool.<br/><br/>Then we qualify each lead by performing an on-site audit thanks to an extended industrial expert network nearby the sources.<br/><br/>We're best suited for search of \"five-legged sheep\" and innovative production technologies."
    },
    {
      question: "How do you find us?",
      anwser: "\"Good wine needs no bush.\" - W.Shakespeare<br/>If you are well recognized in your domain of expertise, we will know about you :-)<br/><br/>Other than that, we use the following channels:<br/>- Word of mouth referrals through other experts<br/>- Both online and offline professional networks (LinkedIn, APICS,...)<br/>- Your registrations on our website"
    },
    {
      question: "Why become a Fox?",
      anwser: "Other than getting paid and helping a peer, you also establish your personal brand in your specific domain of expertise.<br/><br/>Later on, you can exploit that fame and authority for yourself as well as for the company you work for.<br/><br/>In popular words, it's the best \"inbound marketing\" tool for an industry expert."
    },
    {
      question: "How to become a Fox?",
      anwser: "1.  You already are if you have:  skills in one technology, knowledge about an industry, availability within a zone<br/><br/>2. Take 10 min to register freely on our website as an expert"
    },
    {
      question: "How do I get paid?",
      anwser: "You don't need to have your own company, you only need a bank account.<br/><br/>We would pay the pre-agreed fees to you 30 days upon confirmation of customer success in the currency you desire."
    },
    {
      question: "Am I allowed to work as a Fox?",
      anwser: "The short answer is YES. Depending on which country you live in,  just make sure:<br/><br/>1. You stay away from conflicts of interest with your current employer. We would help you identify those topics.<br/><br/>2. You declare your earned revenue, pretty much the same way as if you were an Airbnb host.<br/><br/>Any other questions? Reach out to us, our legal partner may provide advice for you.<br/><br/>Take France as an example, for a full-time employee questioning his aptitude to run part-time missions, here is the answer:<br/><br/><a href=\"https://leparticulier.lefigaro.fr/jcms/p1_1322958/je-suis-salarie-et-une-activite-occasionnelle-de-consultant-m-est-proposee-a-l-exterieur-de-la-societe-qui-m-emploie-puis-je-cumuler-ces-deux-activites\">Clause d'exclusivité</a>"
    }
  ];

  constructor(private googleAnalyticsEventsService : GoogleAnalyticsEventsService) { }

  ngOnInit(): void {
  }

  buyerExpand() {
    this.googleAnalyticsEventsService.emitEvent("buyer-presentation", "buyer_qA_expand", "Buyer Q&A expansion", 0);
  }

  expertExpand() {
    this.googleAnalyticsEventsService.emitEvent("expert-presentation", "expert_qA_expand", "Expert Q&A expansion", 0);
  }

}
