import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ExpertProfileComponent } from './expert-profile/expert-profile.component';
import { ExpertAccountComponent } from './expert-account/expert-account.component';
import { ExpertAccountModifyComponent } from './expert-account-modify/expert-account-modify.component';
import { ExpertAccountResetpwdComponent } from './expert-account-resetpwd/expert-account-resetpwd.component';
import { CounterOfferComponent } from './counter-offer/counter-offer.component';

import { ExpertAuthGuard } from '../_helper/expert-auth-guard';

const routes: Routes = [
  { path: 'profile', component: ExpertProfileComponent, canActivate: [ExpertAuthGuard] },
  { path: 'account', component: ExpertAccountComponent, canActivate: [ExpertAuthGuard] },
  { path: 'account-modify', component: ExpertAccountModifyComponent, canActivate: [ExpertAuthGuard] },
  { path: 'account-resetpwd', component: ExpertAccountResetpwdComponent, canActivate: [ExpertAuthGuard] },
  { path: 'counter-offer/:id', component: CounterOfferComponent, canActivate: [ExpertAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExpertRoutingModule { }
