import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { BuyerService } from '../../_services/buyer.service';
import { BuyerMissionsService } from '../../_services/buyer-missions.service';
import { Buyer } from '../../_models/buyer';
import { Mission, MissionStatus, MissionType, RejectionReason, MissionInformation } from '../../_models/mission';
import { DialogSendQuizComponent } from '../dialog-send-quiz/dialog-send-quiz.component';

@Component({
  selector: 'app-buyer-profile',
  templateUrl: './buyer-profile.component.html',
  styleUrls: ['./buyer-profile.component.css']
})
export class BuyerProfileComponent implements OnInit {

  buyer : Buyer;
  buyerMissions : Mission[];
  displayedColumns: string[] = ['title', 'missoinType', 'deadline', 'status', 'information', 'action'];
  dataNumber : number;
  enumMissionStatus = MissionStatus;
  enumMissionType = MissionType;
  enumRejectionReason = RejectionReason;

  constructor(
    private buyerService: BuyerService,
    /*private buyerMissionsService: BuyerMissionsService,*/
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.buyer = this.buyerService.buyerValue;
    /*this.buyerMissionsService.getCurrentBuyerMissions().subscribe(
      data => {
        this.buyerMissions = this.buyerMissionsService.missionsValue;
        this.dataNumber = this.buyerMissions.length;
        console.log(this.buyerMissions);
      },
      error => {
        console.error(error);
      }
    );*/
  }

/*  acceptCounterOffer(mission_id : number): void {
    this.buyerMissionsService.updateBudget(mission_id.toString()).subscribe(
      data => {
        this.buyerMissions = this.buyerMissionsService.missionsValue;
        this.dataNumber = this.buyerMissions.length;
      },
      error => {
        console.error(error);
      } 
    );
  }

  declineCounterOffer(mission_id : number): void {
    this.buyerMissionsService.changeStatus(mission_id.toString(), MissionStatus.BUYER_REJECTED_COUNTEROFFER).subscribe(
      data => {
        this.buyerMissions = this.buyerMissionsService.missionsValue;
        this.dataNumber = this.buyerMissions.length;
      },
      error => {
        console.error(error);
      } 
    );
  }

  sendQuiz(mission_id : number): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DialogSendQuizComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        // Do nothing if rejection_reason is not set
        if (data.quiz != null) {
          // Transform data with right input
          let informationData : MissionInformation = {
            quiz : data.quiz
          }
          this.buyerMissionsService.sendQuiz(mission_id.toString(), informationData).subscribe(
            data => {
              this.buyerMissions = this.buyerMissionsService.missionsValue;
              this.dataNumber = this.buyerMissions.length;
            },
            error => {
              console.error(error);
            } 
          );
        }
      }
    );
  }

  validateQuiz(mission_id : number): void {
    this.buyerMissionsService.changeStatus(mission_id.toString(), MissionStatus.MISSION_ACCEPTED_AND_ANSWER_VALIDATED).subscribe(
      data => {
        this.buyerMissions = this.buyerMissionsService.missionsValue;
        this.dataNumber = this.buyerMissions.length;
      },
      error => {
        console.error(error);
      } 
    );
  }

  disapproveQuiz(mission_id : number): void {
    this.buyerMissionsService.changeStatus(mission_id.toString(), MissionStatus.MISSION_ACCEPTED_BUT_ANSWER_NOT_VALIDATED).subscribe(
      data => {
        this.buyerMissions = this.buyerMissionsService.missionsValue;
        this.dataNumber = this.buyerMissions.length;
      },
      error => {
        console.error(error);
      } 
    );
  }

  pay(): void {
    console.log("Custumer pay");
  }*/

  logout() {

    if (this.buyer) {
      this.buyerService.logout();
    }

  }

}