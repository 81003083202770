<main class="main">
		
  <!--section start-->
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
      <form [formGroup]="expertForm" (ngSubmit)="onExpertSubmit()">
        <div class="row">
          <div class="col-lg-5 location-item__lower">
            <h5 class="contacts__title" i18n>Modify your profile</h5>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="companyName" i18n>Company name</mat-label>
                <input matInput placeholder="Company name" formControlName="companyName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.companyName.errors }">
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="expertTechnologies" i18n>Technology*</mat-label>
                <mat-select formControlName="expertTechnologies" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertTechnologies.errors }" multiple>
                  <mat-option *ngFor="let technologyLevelKey of technologyLevelKeys" [value]="technology[technologyLevelKey]"
                    [disabled]="expertFormControl.expertTechnologies.value?.length > 2 && !expertFormControl.expertTechnologies.value?.includes(technology[technologyLevelKey])">
                    {{technology[technologyLevelKey]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="expertForm.controls.expertTechnologies.hasError('required')" i18n>
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="expertRegions" i18n>Region*</mat-label>
                <mat-select formControlName="expertRegions" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertRegions.errors }" multiple>
                  <mat-option *ngFor="let regionKey of regionKeys" [value]="region[regionKey]"
                    [disabled]="expertFormControl.expertRegions.value?.length > 2 && !expertFormControl.expertRegions.value?.includes(region[regionKey])">
                    {{region[regionKey]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="expertForm.controls.expertRegions.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="expertIndustries" i18n>Industry*</mat-label>
                <mat-select formControlName="expertIndustries" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertIndustries.errors }" multiple>
                  <mat-option *ngFor="let industryKey of industryKeys" [value]="industry[industryKey]"
                    [disabled]="expertFormControl.expertIndustries.value?.length > 2 && !expertFormControl.expertIndustries.value?.includes(industry[industryKey])">
                    {{industry[industryKey]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="expertForm.controls.expertIndustries.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="expertCoreCompetencies" i18n>Core Competency*</mat-label>
                <mat-select formControlName="expertCoreCompetencies" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertCoreCompetencies.errors }" multiple>
                  <mat-option *ngFor="let coreCompetencyKey of coreCompetencyKeys" [value]="coreCompetency[coreCompetencyKey]"
                    [disabled]="expertFormControl.expertCoreCompetencies.value?.length > 2 && !expertFormControl.expertCoreCompetencies.value?.includes(coreCompetency[coreCompetencyKey])">
                    {{coreCompetency[coreCompetencyKey]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="expertForm.controls.expertCoreCompetencies.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="yearsExperience" i18n>Years of experience</mat-label>
                <input matInput placeholder="Years of experience" formControlName="yearsExperience" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.yearsExperience.errors }">
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="expertSummary" i18n>Expert summary (Tell us about yourself and your experiences)</mat-label>
                <textarea class="form__message message--large" formControlName="expertSummary"
                  matInput></textarea>
              </mat-form-field>
            </div>
          </div>
          <!-- Right Block-->
          <div class="col-lg-5 location-item__lower">
            <!--h5 class="contacts__title"></h5-->
            <div class="col-lg-12 top-60">
              <mat-form-field appearance="fill">
                <mat-label for="firstName" i18n>First name</mat-label>
                <input matInput placeholder="First name" formControlName="firstName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.firstName.errors }">
                  <mat-error *ngIf="expertForm.controls.firstName.hasError('required')">
                    The field is required.
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="lastName" i18n>Last name</mat-label>
                <input matInput placeholder="Last name" formControlName="lastName" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.lastName.errors }">
                  <mat-error *ngIf="expertForm.controls.lastName.hasError('required')" i18n>
                    The field is required.
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="jobLevel" i18n>Job Level</mat-label>
                <mat-select formControlName="jobLevel" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.jobLevel.errors }">
                  <mat-option *ngFor="let jobLevelKey of jobLevelKeys" [value]="jobLevel[jobLevelKey]">
                    {{jobLevel[jobLevelKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="jobFunction" i18n>Job Function</mat-label>
                <mat-select formControlName="jobFunction" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.jobFunction.errors }">
                  <mat-option *ngFor="let jobFunctionKey of jobFunctionKeys" [value]="jobFunction[jobFunctionKey]">
                    {{jobFunction[jobFunctionKey]}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="countryCallingCode" i18n>Country code*</mat-label>
                <input matInput placeholder="Number after 00" size="3" maxLength="3"
                  formControlName="countryCallingCode" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.countryCallingCode.errors }">
                  <mat-error *ngIf="expertForm.controls.countryCallingCode.hasError('required')" i18n>
                    The field is required.
                  </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="phoneNumber" i18n>Phone number*</mat-label>
                <input matInput placeholder="Phone number" formControlName="phoneNumber" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.phoneNumber.errors }">
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error *ngIf="expertForm.controls.phoneNumber.hasError('required')" i18n>
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-form-field appearance="fill">
                <mat-label for="linkedIn" i18n>LinkedIn</mat-label>
                <input matInput placeholder="LinkedIn" formControlName="linkedIn" class="form-control"
                  [ngClass]="{ 'is-invalid': submitted && expertFormControl.linkedIn.errors }">
              </mat-form-field>
            </div>
            <div class="col-lg-12 top-30">
              <mat-label for="references" i18n>References :</mat-label>
              <div formGroupName="references">
                <div class="row">
                  <div class="col-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="company1" i18n>Company 1</mat-label>
                      <input matInput placeholder="Company 1" formControlName="company1" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && expertForm['controls'].references['controls'].company1.errors }">
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="company2" i18n>Company 2</mat-label>
                      <input matInput placeholder="Company 2" formControlName="company2" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && expertForm['controls'].references['controls'].company2.errors }">
                    </mat-form-field>
                  </div>
                  <div class="col-4">
                    <mat-form-field appearance="fill">
                      <mat-label for="company3" i18n>Company 3</mat-label>
                      <input matInput placeholder="Company 3" formControlName="company3" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && this.expertForm['controls'].references['controls'].company3.errors }">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="button button--green top-20" type="submit" i18n>Confirm 
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
          <button class="button button--green top-20 left-20" routerLink="/expert/account">
            <span i18n>Cancel</span>
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </section>
  <!-- section end-->
</main>
