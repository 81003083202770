import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, FormArray, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { BuyerService } from '../../_services/buyer.service';
import { Buyer } from '../../_models/buyer';

import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';
import { JobFunction, JobLevel, Region, Industry, Size, Position } from '../../_models/enumBO';

@Component({
  selector: 'app-buyer-account-modify',
  templateUrl: './buyer-account-modify.component.html',
  styleUrls: ['./buyer-account-modify.component.css']
})
export class BuyerAccountModifyComponent implements OnInit {

  buyer : Buyer;
  buyerForm: FormGroup;

  loading = false;
  submitted = false;

  // Récupérer les enum de form
  region = Region;
  regionKeys = Object.keys(this.region);
  industry = Industry;
  industryKeys = Object.keys(this.industry);
  jobFunction = JobFunction;
  jobFunctionKeys = Object.keys(this.jobFunction);
  jobLevel = JobLevel;
  jobLevelKeys = Object.keys(this.jobLevel);
  size = Size;
  sizeKeys = Object.keys(this.size);
  position = Position;
  positionKeys = Object.keys(this.position);

  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    // Current buyer
    this.buyer = this.buyerService.buyerValue;

    // Buyer form
    this.buyerForm = this.formBuilder.group({
      companyName: ['', Validators.required],
      companySummary: [''],
      regions: [''],
      industries: [''],
      size: [''],
      positions: [''],
      firstName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      lastName: ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      jobLevel: [''],
      jobFunction: [''],
      //email: ['', Validators.required], used as username
      countryCallingCode: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.maxLength(3)]],
      phoneNumber: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      linkedIn: [''],
      website: [''],
    });

    // Add default value
    this.initbuyerForm();

  }
  
  get buyerFormControl() { return this.buyerForm.controls; }

  // Submit expert
  onBuyerSubmit() {
    
    this.submitted = true;

    if (this.buyerForm.invalid) {
      return;
    }

    this.loading = true;

    //Identifier les modifications
    var modified = false;
    var contentModified= {};
    var buyerModification = this.buyerForm.value;
    var buyerOriginal = this.buyer;

    // Compare company name ignore case
    if (buyerModification.companyName.toLowerCase() != buyerOriginal.companyName.toLowerCase()) {
      contentModified["companyName"] = buyerModification.companyName;
      modified = true;
    }
    if (buyerModification.companySummary != buyerOriginal.companySummary ) {
      contentModified["companySummary"] = buyerModification.companySummary;
      modified = true;
    }
    if (buyerModification.firstName != buyerOriginal.firstName ) {
      contentModified["firstName"] = buyerModification.firstName;
      modified = true;
    }
    if (buyerModification.lastName != buyerOriginal.lastName ) {
      contentModified["lastName"] = buyerModification.lastName;
      modified = true;
    }
    if (buyerModification.countryCallingCode != buyerOriginal.countryCallingCode ) {
      contentModified["countryCallingCode"] = buyerModification.countryCallingCode;
      modified = true;
    }
    if (buyerModification.phoneNumber != buyerOriginal.phoneNumber ) {
      contentModified["phoneNumber"] = buyerModification.phoneNumber;
      modified = true;
    }
    if (buyerModification.linkedIn != buyerOriginal.linkedin ) {
      contentModified["linkedIn"] = buyerModification.linkedIn;
      modified = true;
    }
    if (buyerModification.website != buyerOriginal.websiteUrl ) {
      contentModified["linkedIn"] = buyerModification.website;
      modified = true;
    }

    // Simple choice compare, loose compare to check both null or undefined, all the fields are not required
    if (buyerModification.size == null && buyerOriginal.size != null) {
      contentModified["size"] = null;
      modified = true;
    } else if (buyerModification.size != null && buyerOriginal.size == null) {
      contentModified["size"] = buyerModification.size;
      modified = true;
    } else if (buyerModification.size != null && buyerOriginal.size != null) {
      if (buyerModification.size != buyerOriginal.size.value) {
        contentModified["size"] = buyerModification.size;
        modified = true;
      }
    }

    if (buyerModification.jobFunction == null && buyerOriginal.jobFunction != null) {
      contentModified["jobFunction"] = null;
      modified = true;
    } else if (buyerModification.jobFunction != null && buyerOriginal.jobFunction == null) {
      contentModified["jobFunction"] = buyerModification.jobFunction;
      modified = true;
    } else if (buyerModification.jobFunction != null && buyerOriginal.jobFunction != null) {
      if (buyerModification.jobFunction != buyerOriginal.jobFunction.value) {
        contentModified["jobFunction"] = buyerModification.jobFunction;
        modified = true;
      }
    }

    if (buyerModification.jobLevel == null && buyerOriginal.jobLevel != null) {
      contentModified["jobLevel"] = null;
      modified = true;
    } else if (buyerModification.jobLevel != null && buyerOriginal.jobLevel == null) {
      contentModified["jobLevel"] = buyerModification.jobLevel;
      modified = true;
    } else if (buyerModification.jobLevel != null && buyerOriginal.jobLevel != null) {
      if (buyerModification.jobLevel != buyerOriginal.jobLevel.value) {
        contentModified["jobLevel"] = buyerModification.jobLevel;
        modified = true;
      }
    }

    // Multiple choice compare, all the fields are not required
    if (buyerModification.regions == null && buyerOriginal.regions != null) {
      contentModified["regions"] = null;
      modified = true;
    } else if (buyerModification.regions != null && buyerOriginal.regions == null) {
      contentModified["regions"] = buyerModification.regions;
      modified = true;
    } else if (buyerModification.regions != null && buyerOriginal.regions != null) {
      if (buyerModification.regions.length != buyerOriginal.regions.length 
        || !buyerOriginal.regions.every(element => buyerModification.regions.includes(element.country))) {
        contentModified["regions"] = buyerModification.regions;
        modified = true; 
      }
    }
    
    if (buyerModification.industries == null && buyerOriginal.industries != null) {
      contentModified["industries"] = null;
      modified = true;
    } else if (buyerModification.industries != null && buyerOriginal.industries == null) {
      contentModified["industries"] = buyerModification.industries;
      modified = true;
    } else if (buyerModification.industries != null && buyerOriginal.industries != null) {
      if (buyerModification.industries.length != buyerOriginal.industries.length 
        || !buyerOriginal.industries.every(element => buyerModification.industries.includes(element.value))) {
        contentModified["industries"] = buyerModification.industries;
        modified = true; 
      }
    }
    
    if (buyerModification.positions == null && buyerOriginal.positions != null) {
      contentModified["positions"] = null;
      modified = true;
    } else if (buyerModification.positions != null && buyerOriginal.positions == null) {
      contentModified["positions"] = buyerModification.positions;
      modified = true;
    } else if (buyerModification.positions != null && buyerOriginal.positions != null) {
      if (buyerModification.positions.length != buyerOriginal.positions.length 
        || !buyerOriginal.positions.every(element => buyerModification.positions.includes(element.value))) {
        contentModified["positions"] = buyerModification.positions;
        modified = true; 
      }
    }

    if (modified) {
      console.log(JSON.stringify(contentModified));
      this.buyerService.update(contentModified)
      .subscribe(
        data => {
          console.log("Redirect");
          this.router.navigate(['/buyer/account'], { relativeTo: this.route });
          window.scrollTo(0, 0);
          this.confirmModificationOK();
        },
        error => {
          console.log(error);
          this.buyerModificationErreur(error.error);
          this.loading = false;
        }
      );

    } else {
      console.log("No modification");
      console.log("Redirect");
      this.router.navigate(['/buyer/account'], { relativeTo: this.route });
    }

  }

  // Init the buyer form value
  private initbuyerForm() {
    this.buyerFormControl.companyName.setValue(this.buyer.companyName);
    this.buyerFormControl.companySummary.setValue(this.buyer.companySummary);

    let regions = [];
    this.buyer.regions.forEach(element => regions.push(element.country));
    this.buyerFormControl.regions.setValue(regions);

    let industries = [];
    this.buyer.industries.forEach(element => industries.push(element.value));
    this.buyerFormControl.industries.setValue(industries);

    let positions = [];
    this.buyer.positions.forEach(element => positions.push(element.value));
    this.buyerFormControl.positions.setValue(positions);

    if (this.buyer.size) {
      this.buyerFormControl.size.setValue(this.buyer.size.value);
    }
    this.buyerFormControl.firstName.setValue(this.buyer.firstName);
    this.buyerFormControl.lastName.setValue(this.buyer.lastName);
    if (this.buyer.jobLevel) {
      this.buyerFormControl.jobLevel.setValue(this.buyer.jobLevel.value);
    }
    if (this.buyer.jobFunction) {
      this.buyerFormControl.jobFunction.setValue(this.buyer.jobFunction.value);
    }
    this.buyerFormControl.phoneNumber.setValue(this.buyer.phoneNumber);
    this.buyerFormControl.countryCallingCode.setValue(this.buyer.countryCallingCode);
    this.buyerFormControl.linkedIn.setValue(this.buyer.linkedin);
    this.buyerFormControl.website.setValue(this.buyer.websiteUrl);

  }

  private confirmModificationOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Successful!',
      message: 'Your modifications have been saved!'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
  
  private buyerModificationErreur(error : string): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    var messageErreur = "Something went wrong...Contact us please.";
    if (error == "com.askafox.backend.controller.exception.ModificationException: Company already exists and linked to another buyer") {
      messageErreur = "Company already exists and is linking to another buyer account. Please contact us.";
    }

    dialogConfig.data = {
      title: 'Oops!',
      message: messageErreur
    };
    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
