import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn, ValidationErrors} from '@angular/forms'; 

/* The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 special character */
export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let passwordValue = control.value;
    //At least 1 number 
    let regexNumber = new RegExp('.*[0-9].*');
    let regexUpperCaseLetter = new RegExp('.*[A-Z].*');
    let regexLowerCaseLetter = new RegExp('.*[a-z].*');
    let regexspecialCharacterl = new RegExp('.*[*.!@#$%^&(){}\\[\\]:";\'<>,.?/~_+=|°§`\\\\].*');
    let isPasswordOK = regexNumber.test(passwordValue) && regexUpperCaseLetter.test(passwordValue) 
      && regexLowerCaseLetter.test(passwordValue) && regexspecialCharacterl.test(passwordValue);
    return isPasswordOK ? null : {'passwordError' : true};
  };
}

@Directive({
  selector: '[appPasswordValidator]',
  providers: [{provide: NG_VALIDATORS,  useExisting: PasswordValidatorDirective, multi: true
}]
})
export class PasswordValidatorDirective implements Validator{

  constructor() { }
  validate(control: AbstractControl): { [key: string]: any } | null { 
    return passwordValidator();  
}

}
