import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegistryComponent } from './user-registry/user-registry.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const routes: Routes = [
  { 
    path: 'registry', 
    component: UserRegistryComponent,
    data: {
      title: 'Global Sourcing - ASKaFOX',
    } 
  },
  { 
    path: 'login', 
    component: UserLoginComponent,
    data: {
      title: 'Supplier Search - ASKaFOX',
    } 
  },
  { path: 'forgetpassword', component: ForgetPasswordComponent },
  { path: 'resetpassword', component: ResetPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
