import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Expert } from '../_models/expert';
import { ExpertMatch } from '../_models/expertMatch';
import { EXPERT_MATCH } from '../_mock/mock-expertMatchs';
import { TokenStorageService } from './token-storage.service';

const EXPERT_KEY = 'auth-expert';

@Injectable({
  providedIn: 'root'
})
export class ExpertService {

  private expertSubject$: BehaviorSubject<Expert>;
  public expert$: Observable<Expert>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private tokenStorageService : TokenStorageService
  ) { 
    this.expertSubject$ = new BehaviorSubject<Expert>(JSON.parse(window.sessionStorage.getItem(EXPERT_KEY)) || null);
    this.expert$ = this.expertSubject$.asObservable();
  }

  public get expertValue(): Expert {
    return this.expertSubject$.value;
  }

  login(username: string, password: string): Observable<any> {
    var formData: FormData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    return this.http.post<any>(`${environment.apiUrl}/public/experts/login`, formData)
      .pipe(
        map((data: any) => {
          this.tokenStorageService.saveToken(data.token);
          return data;
        })
      );
  }

  getCurrentExpert(): Observable<Expert> {
    return this.http.get<Expert>(`${environment.apiUrl}/experts/currentExpert`)
      .pipe(
        map((data: Expert) => {
          window.sessionStorage.setItem(EXPERT_KEY, JSON.stringify(data));
          this.expertSubject$.next(data);
          return data;
        })
      )
  }

  logout() {
    // remove user from local storage and set current user to null
    this.expertSubject$.next(null);
    this.tokenStorageService.signOut();
    //this.router.navigate(['/user/login']);
  }

  register(expert: Object) {
    return this.http.post(`${environment.apiUrl}/public/experts/register`, expert);
  }

  update(expert : Object) {
    return this.http.post(`${environment.apiUrl}/experts/modify`, expert)
      .pipe(
        map((data: Expert) => {
          window.sessionStorage.setItem(EXPERT_KEY, JSON.stringify(data));
          this.expertSubject$.next(data);
          return data;
      }));
  }
  
  /*
  getAll() {
    return this.http.get<Expert[]>(`${environment.apiUrl}/experts`);
  }

  getById(expert_id: string) {
    return this.http.get<Expert>(`${environment.apiUrl}/experts/${expert_id}`);
  }
  
  delete(expert_id: string) {
    return this.http.delete(`${environment.apiUrl}/experts/${expert_id}`)
        .pipe(map(x => {
            // auto logout if the logged in user deleted their own record
            if (expert_id == this.expertValue.email) {
                this.logout();
            }
            return x;
        }));
  }*/

  getExpertMatchs(): Observable<ExpertMatch[]> {
    return of(EXPERT_MATCH);
  }

  getExpert(id: number): Observable<ExpertMatch> {
    return of(EXPERT_MATCH.find(ExpertMatch => ExpertMatch.id === id));
  }
}
