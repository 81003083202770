import { Component, OnInit } from '@angular/core';

import { QuestionService } from '../../_services/question.service'
import { Question } from '../../_models/question';

@Component({
  selector: 'app-webcrawling-choice',
  templateUrl: './webcrawling-choice.component.html',
  styleUrls: ['./webcrawling-choice.component.css']
})
export class WebcrawlingChoiceComponent implements OnInit {

  questions : Question[];

  constructor(private questionService : QuestionService) { }

  ngOnInit(): void {
   this.getQuestions();
   console.log(this.questions);
  }

  // Get the webcrawling questions
  getQuestions(): void {
    this.questionService.getWebcrawlingQuestions()
        .subscribe(questions => this.questions = questions);
  }

  // Check if all suboptions are checked
  verifyAllOptions(question : Question) {
    question.allchecked = question.options.subOptions != null && question.options.subOptions.every(t => t.checked);
  }

  // Check or uncheck all suboptions
  setAll(question : Question, checked: boolean) {
    question.allchecked = checked;
    if (question.options.subOptions == null) {
      return;
    }
    question.options.subOptions.forEach(t => t.checked = checked);
  }

}
