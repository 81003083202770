<div *ngIf="dataNumber>0; else elseBlock">
  <div class="Message">Congratulations We have found {{dataNumber}} resultats matching your criterions at x%!</div>
  <div class="Result">
    <div class="ResultImage">Image statique</div>
    <div class="ResultTable">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- No. Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Company Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Company Name </th>
          <td mat-cell *matCellDef="let element"> {{element.supplier.name}} </td>
        </ng-container>
      
        <!-- Region Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef> Region </th>
          <td mat-cell *matCellDef="let element"> {{element.supplier.location}} </td>
        </ng-container>
      
        <!-- Sector Column -->
        <ng-container matColumnDef="sector">
          <th mat-header-cell *matHeaderCellDef> Sector </th>
          <td mat-cell *matCellDef="let element"> {{element.supplier.sector}} </td>
        </ng-container>
      
        <!-- Matching rate Column -->
        <ng-container matColumnDef="matching_rate">
          <th mat-header-cell *matHeaderCellDef> Matching rate </th>
          <td mat-cell *matCellDef="let element"> {{element.matching_rate}} </td>
        </ng-container>

        <!-- Supplier Détail button -->
        <ng-container matColumnDef="supplier_detail">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button [routerLink]="['/webcrawling/supplier-detail', element.id]">See detail</button>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
<ng-template #elseBlock>Not lucky! No resultats matching found. We would improve on that
</ng-template>
<div>
<button mat-button routerLink="/webcrawling/choice">Modify my criterions</button>
</div>