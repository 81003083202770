import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ExpertService } from '../../_services/expert.service'
import { BuyerService } from '../../_services/buyer.service'
import { BuyerMissionsService } from '../../_services/buyer-missions.service'
import { AlertService } from '../../_services/alert.service';
import { Expert } from '../../_models/expert';
import { Buyer } from '../../_models/buyer';
import { Mission, MissionStatus } from '../../_models/mission';
import { MissionType } from '../../_models/enumBO';
import { CURRENCY } from '../../_models/currency';

@Component({
  selector: 'app-send-mission',
  templateUrl: './send-mission.component.html',
  styleUrls: ['./send-mission.component.css']
})
export class SendMissionComponent implements OnInit {

  expert: Expert;
  buyer: Buyer;
  form: FormGroup;
  loading = false;
  submitted = false;

  // Récupérer les enum de form
  missionType = MissionType;
  missionTypeKeys =  Object.keys(this.missionType);

  currencyList: string[] = [];  

  constructor(
    private route: ActivatedRoute,
    private router : Router,
    private expertService: ExpertService,
    private buyerService: BuyerService,
    private buyerMissionsService: BuyerMissionsService,
    private alertService : AlertService,
    private location: Location,
    private builder: FormBuilder
  ) {}

  ngOnInit(): void {

    this.getExpert();
    this.getBuyer();

    /*
      Mission content :          
      mission_id: number; => Generated
      expert_id: number; => Get
      buyer_id: number; => Get
      missoinType: MissionType; => Input
      budget: number; => Input
      deadline: Date; => Input
      description: string; => Input
      livrable?: string; => Later
    */
    this.form = this.builder.group({
      title: ['', Validators.required],
      mission_type: ['', Validators.required],
      budget: ['', Validators.required],
      currency: ['', Validators.required],
      place: ['', Validators.required],
      deadline: ['', Validators.required],
      description: ['', Validators.required],
    });

    // List de currency
    CURRENCY.forEach(objet => {
      if (objet.currency_name) {
        this.currencyList.push(objet.currency_name);
      }
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  getExpert(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.expertService.getExpert(id)
      .subscribe(supplierMatch => this.expert = supplierMatch.expert);
  }

  getBuyer() : void {
    this.buyerService.buyer$.subscribe(x => this.buyer = x);
  }

  onSubmit() : void {

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }

    this.loading = true;
    let mission : Mission;
    mission = this.form.value;
    mission.buyer_id = this.buyer.buyerId;
    mission.expert_id = this.expert.expertId;
    mission.sent_date = new Date();
    mission.status = MissionStatus.MISSION_SENT;

    console.log(mission);

    this.buyerMissionsService.addMission(mission).subscribe(
      data => {
          this.alertService.success('Send mission successful', { keepAfterRouteChange: true });
          console.log("Redirect");
          this.router.navigate(['/buyer/profile'], { relativeTo: this.route });
      },
      error => {
          this.alertService.error(error);
          this.loading = false;
      }
    );

  }

  // Button send => Submit the form
  send() {
    if (this.form.valid) {
      console.log("send");
      this.submitted = true;
      this.onSubmit();
    }
  }

  upload() : void {

  }

  goBack(): void {
    this.location.back();
  }
}
