import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { BuyerService } from '../../_services/buyer.service';
import { ExpertService } from '../../_services/expert.service';
import { AlertService } from '../../_services/alert.service';
import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.css']
})
export class UserLoginComponent implements OnInit {

  buyerForm: FormGroup;
  buyerHide = true;

  expertForm: FormGroup;
  expertHide = true;

  loading = false;
  submitted = false;

  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private buyerService: BuyerService,
    private expertService: ExpertService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService
  ) { }

  ngOnInit() {
    this.buyerForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.expertForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
  }

  // convenience getter for easy access to form fields
  get buyerFormControl() { return this.buyerForm.controls; }
  get expertFormControl() { return this.expertForm.controls; }

  onBuyerSubmit() {

    this.submitted = true;
    this.returnUrl = this.returnUrl || '/buyer/profile';

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.buyerForm.invalid) {
      return;
    }

    this.loading = true;
    this.buyerService.login(this.buyerFormControl.username.value, this.buyerFormControl.password.value)
      .subscribe(
        data => {
          // Get current buyer after login
          this.buyerService.getCurrentBuyer().subscribe(
            data => {
              this.router.navigate([this.returnUrl]);
            }
          );
        },
        error => {
          console.log(error);
          this.loginErreur(error.error);
          this.loading = false;
        }
      );
  }

  onExpertSubmit() {

    this.submitted = true;
    this.returnUrl = this.returnUrl || '/expert/profile';

    // reset alerts on submit
    this.alertService.clear();

    // stop here if form is invalid
    if (this.expertForm.invalid) {
      return;
    }

    this.loading = true;
    this.expertService.login(this.expertFormControl.username.value, this.expertFormControl.password.value)
      .subscribe(
        data => {
          // Get current buyer after login
          this.expertService.getCurrentExpert().subscribe(
            data => {
              this.router.navigate([this.returnUrl]);
            }
          );
        },
        error => {
          console.log(error);
          this.loginErreur(error.error);
          this.loading = false;
        }
      );
  }

  signup() {
    this.googleAnalyticsEventsService.emitEvent("login", "signup_click", "Sign up page link click", 0);
  }

  private loginErreur(error : string): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    var messageErreur = "Something went wrong...Contact us please.";
    if (error == "com.askafox.backend.controller.exception.AuthenticationException: invalid login and/or password") {
      messageErreur = "Login/password incorrect";
    }

    dialogConfig.data = {
      title: 'Oops!',
      message: messageErreur
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }

}
