import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BuyerProfileComponent } from './buyer-profile/buyer-profile.component';
import { BuyerAccountComponent } from './buyer-account/buyer-account.component';
import { SendMissionComponent } from './send-mission/send-mission.component';
import { BuyerAccountResetpwdComponent } from './buyer-account-resetpwd/buyer-account-resetpwd.component';
import { BuyerAccountModifyComponent } from './buyer-account-modify/buyer-account-modify.component';

import { BuyerAuthGuard } from '../_helper/buyer-auth-guard';

const routes: Routes = [
  { path: 'profile', component: BuyerProfileComponent, canActivate: [BuyerAuthGuard] },
  { path: 'account', component: BuyerAccountComponent, canActivate: [BuyerAuthGuard] },
  { path: 'account-modify', component: BuyerAccountModifyComponent, canActivate: [BuyerAuthGuard] },
  { path: 'account-resetpwd', component: BuyerAccountResetpwdComponent, canActivate: [BuyerAuthGuard] },
  { path: 'send-mission/:id', component: SendMissionComponent, canActivate: [BuyerAuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyerRoutingModule { }
