<main class="main">
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
      <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-lg-12 location-item__lower">
            <h5 class="contacts__title">Reset your password</h5>
            <div class="col-12">
              <mat-form-field appearance="fill">
                <mat-label for="userName">Email(username)</mat-label>
                <input matInput placeholder="Company mail only" formControlName="userName" class="form-control"
                  [ngClass]="{ 'is-invalid': loading && forgetPasswordFormControl.userName.errors }">
                <mat-error *ngIf="forgetPasswordForm.controls.userName.hasError('required')">
                  The field is required.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="row">
          <button class="button button--green top-20" type="submit">Confirmer 
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
        </div>
      </form>
    </div>
  </section>
</main>