import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WebcrawlingChoiceComponent } from './webcrawling-choice/webcrawling-choice.component';
import { WebcrawlingResultComponent } from './webcrawling-result/webcrawling-result.component';
import { SupplierDetailComponent } from './supplier-detail/supplier-detail.component';

const routes: Routes = [
  { path: 'choice', component: WebcrawlingChoiceComponent },
  { path: 'result', component: WebcrawlingResultComponent },
  { path: 'supplier-detail/:id', component: SupplierDetailComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebcrawlingRoutingModule { }
