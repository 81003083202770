import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { ExpertRoutingModule } from './expert-routing.module';
import { ExpertProfileComponent } from './expert-profile/expert-profile.component';
import { CounterOfferComponent } from './counter-offer/counter-offer.component';
import { DialogRejectionComponent } from './dialog-rejection/dialog-rejection.component';
import { DialogCounterOfferComponent } from './dialog-counter-offer/dialog-counter-offer.component';
import { DialogAnswerQuizComponent } from './dialog-answer-quiz/dialog-answer-quiz.component';
import { ExpertAccountComponent } from './expert-account/expert-account.component';
import { ExpertAccountModifyComponent } from './expert-account-modify/expert-account-modify.component';
import { ExpertAccountResetpwdComponent } from './expert-account-resetpwd/expert-account-resetpwd.component';

@NgModule({
  declarations: [
    ExpertProfileComponent,
    CounterOfferComponent,
    DialogRejectionComponent,
    DialogCounterOfferComponent,
    DialogAnswerQuizComponent,
    ExpertAccountComponent,
    ExpertAccountModifyComponent,
    ExpertAccountResetpwdComponent
  ],
  imports: [
    CommonModule,
    ExpertRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTableModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatSelectModule
  ]
})
export class ExpertModule { }
