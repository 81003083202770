import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ExpertService } from '../../_services/expert.service'
import { Expert } from '../../_models/expert';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';

@Component({
  selector: 'app-expert-detail',
  templateUrl: './expert-detail.component.html',
  styleUrls: ['./expert-detail.component.css']
})
export class ExpertDetailComponent implements OnInit {

  expert: Expert;
  
  constructor(
    private route: ActivatedRoute,
    private expertService: ExpertService,
    private location: Location,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getExpert();
  }

  getExpert(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.expertService.getExpert(id)
      .subscribe(supplierMatch => this.expert = supplierMatch.expert);
  }

  goBack(): void {
    this.location.back();
  }
}
