<main class="main">
  <!-- section start-->
  <section class="hero-block buyer-block ">
    <!--picture>
      <source srcset="assets/promo_6.jpg" media="(min-width: 992px)" /><img class="img--bg" src="assets/promo_6.jpg"
        alt="img" />
    </picture-->
    <div class="buyer-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item "><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title top-140" i18n>Buyer Profile</h1>
              <H4>{{buyer.lastName}} <strong>{{buyer.firstName}}</strong></H4>
              <div class="row">
                <div class="col-xl-6 top-20 ">
                  <div type="submit" class="button button--promo2" routerLink="/user/login" (click)="logout()"><span i18n>Log out</span>
                    <svg class="icon">
                      <use xlink:href="#arrow"></use>
                    </svg></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="bottom-30">
    <div class="container">
      <div class="row bottom-30">
        <div class="col-12">
          <h5 class="top-40 bottom-20" i18n>Status of ongoing/archived missions</h5>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <table mat-table [dataSource]="buyerMissions" class="table  table--lgray table-responsive bottom-30">
            <!-- Mission title -->
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef i18n> Mission title </th>
              <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container>

            <!-- Mission Type -->
            <ng-container matColumnDef="missoinType">
              <th mat-header-cell *matHeaderCellDef i18n> Type of mission </th>
              <td mat-cell *matCellDef="let element"> {{enumMissionType[element.mission_type]}} </td>
            </ng-container>

            <!-- Deadline -->
            <ng-container matColumnDef="deadline">
              <th mat-header-cell *matHeaderCellDef i18n> Mission execution deadline </th>
              <td mat-cell *matCellDef="let element"> {{element.deadline}} </td>
            </ng-container>

            <!-- Status -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef i18n> Status </th>
              <td mat-cell *matCellDef="let element"> {{element.status}} </td>
            </ng-container>

            <!-- Information -->
            <ng-container matColumnDef="information">
              <th mat-header-cell *matHeaderCellDef i18n> Info </th>
              <td mat-cell *matCellDef="let element" [ngSwitch]="element.status">
                <mat-label
                  *ngSwitchCase="[enumMissionStatus.MISSION_SENT, 
                                          enumMissionStatus.EXPERT_ACCEPTED, 
                                          enumMissionStatus.BUYER_ACCEPTED_COUNTEROFFER,
                                          enumMissionStatus.BUYER_REJECTED_COUNTEROFFER,
                                          enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_SENT,
                                          enumMissionStatus.MISSION_ACCEPTED_BUT_ANSWER_NOT_VALIDATED,
                                          enumMissionStatus.MISSION_ACCEPTED_AND_PAID_FOR].includes(element.status) ? element.status : !element.status">
                </mat-label>
                <mat-label *ngSwitchCase="enumMissionStatus.EXPERT_REJECTED" i18n>Reason for rejection:
                  {{enumRejectionReason[element.information.rejection_reason]}}</mat-label>
                <mat-label *ngSwitchCase="enumMissionStatus.COUNTEROFFER" i18n>You received a counteroffer of
                  {{element.information.new_budget}} {{element.information.new_currency}}</mat-label>
                <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_ANSWERED" i18n>The answer to your quiz:
                  {{element.information.quiz_answer}}</mat-label>
                <mat-label *ngSwitchCase="enumMissionStatus.MISSION_ACCEPTED_AND_ANSWER_VALIDATED" i18n>Please proceed to
                  payment</mat-label>
                <mat-label *ngSwitchDefault></mat-label>
              </td>
            </ng-container>

            <!-- Action -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef i18n>Action </th>
              <td mat-cell *matCellDef="let element" [ngSwitch]="element.status">
                <ng-template
                  [ngSwitchCase]="[enumMissionStatus.EXPERT_ACCEPTED, 
                                              enumMissionStatus.BUYER_ACCEPTED_COUNTEROFFER].includes(element.status) ? element.status : !element.status">
                  <span>
                    <div type="submit" (click)="sendQuiz(element.mission_id)"
                      class="button button--filled top-10 bottom-10" i18n>Send a quiz</div>
                  </span>
                </ng-template>
                <ng-template [ngSwitchCase]="enumMissionStatus.COUNTEROFFER">
                  <div class="row">
                    <div class="col-6">
                      <div type="submit" (click)="acceptCounterOffer(element.mission_id)"
                        class="button button--filled top-10 bottom-10" i18n>Accept</div>
                    </div>
                    <div class="col-6">
                      <div type="submit" (click)="declineCounterOffer(element.mission_id)"
                        class="button button--out top-10 bottom-10" i18n>Decline</div>
                    </div>
                  </div>
                </ng-template>
                <ng-template [ngSwitchCase]="enumMissionStatus.MISSION_ACCEPTED_AND_QUIZ_ANSWERED">
                  <div class="row">
                    <div class="col-6">
                      <div type="submit" (click)="validateQuiz(element.mission_id)"
                        class="button button--filled top-10 bottom-10" i18n>Validate</div>
                    </div>
                    <div class="col-6">
                      <div type="submit" (click)="disapproveQuiz(element.mission_id)"
                        class="button button--out top-10 bottom-10" i18n>Disapprove</div>
                    </div>
                  </div>
                </ng-template>
                <ng-template [ngSwitchCase]="enumMissionStatus.MISSION_ACCEPTED_AND_ANSWER_VALIDATED">
                  <span>
                    <div type="submit" (click)="pay(element.mission_id)" class="button button--filled top-10 bottom-10" i18n>
                      Payment</div>
                  </span>
                </ng-template>
                <ng-template ngSwitchDefault></ng-template>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>