import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ExpertService } from '../../_services/expert.service';
import { ExpertMissionsService } from '../../_services/expert-missions.service'
import { Expert } from '../../_models/expert';
import { Mission, MissionStatus, MissionType, RejectionReason, MissionInformation } from '../../_models/mission';
import { DialogRejectionComponent } from '../dialog-rejection/dialog-rejection.component';
import { DialogCounterOfferComponent } from '../dialog-counter-offer/dialog-counter-offer.component';
import { DialogAnswerQuizComponent } from '../dialog-answer-quiz/dialog-answer-quiz.component';

@Component({
  selector: 'app-expert-profile',
  templateUrl: './expert-profile.component.html',
  styleUrls: ['./expert-profile.component.css']
})
export class ExpertProfileComponent implements OnInit {

  expert : Expert;
  expertMissions : Mission[];
  displayedColumns: string[] = ['title', 'sent_date', 'missoinType', 'budget', 'deadline', 'description', 'status', 'information', 'action'];
  dataNumber : number;
  enumMissionStatus = MissionStatus;
  enumMissionType = MissionType;
  enumRejectionReason = RejectionReason;
  
  constructor(
    private expertService: ExpertService,
    /*private expertMissionsService: ExpertMissionsService,*/
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.expert = this.expertService.expertValue;
    /*this.expertMissionsService.getCurrentExpertMissions().subscribe(
      data => {
        this.expertMissions = this.expertMissionsService.missionsValue;
        this.dataNumber = this.expertMissions.length;
      },
      error => {
        console.error(error);
      } 
    );*/
  }

  /*
  accept(mission_id : number): void {
    this.expertMissionsService.changeStatus(mission_id.toString(), MissionStatus.EXPERT_ACCEPTED).subscribe(
      data => {
        this.expertMissions = this.expertMissionsService.missionsValue;
        this.dataNumber = this.expertMissions.length;
      },
      error => {
        console.error(error);
      } 
    );
  }

  decline(mission_id : number): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DialogRejectionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        // Do nothing if rejection_reason is not set
        if (data.rejection_reason != null) {
          // Transform data with right input
          let informationData : MissionInformation = {
            rejection_reason : data.rejection_reason
          }
          this.expertMissionsService.decline(mission_id.toString(), informationData).subscribe(
            data => {
              this.expertMissions = this.expertMissionsService.missionsValue;
              this.dataNumber = this.expertMissions.length;
            },
            error => {
              console.error(error);
            } 
          );
        }
      }
    );

  }

  counteroffer(mission_id : number): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DialogCounterOfferComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        // Do nothing if budget and currency is not set
        if (data.budget != null && data.currency != null) {
          // Transform data with right input
          let informationData : MissionInformation = {
            new_budget : data.budget,
            new_currency : data.currency
          }
          this.expertMissionsService.counteroffer(mission_id.toString(), informationData).subscribe(
            data => {
              this.expertMissions = this.expertMissionsService.missionsValue;
              this.dataNumber = this.expertMissions.length;
            },
            error => {
              console.error(error);
            } 
          );
        }
      }
    );
  }

  answerQuiz(mission_id : number): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(DialogAnswerQuizComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        // Do nothing if quiz_answer is not set
        if (data.quiz_answer != null) {
          // Transform data with right input
          let informationData : MissionInformation = {
            quiz_answer : data.quiz_answer
          }
          this.expertMissionsService.answerQuiz(mission_id.toString(), informationData).subscribe(
            data => {
              this.expertMissions = this.expertMissionsService.missionsValue;
              this.dataNumber = this.expertMissions.length;
            },
            error => {
              console.error(error);
            } 
          );
        }
      }
    );
  }*/

  logout() {

    if (this.expert) {
      this.expertService.logout();
    }

  }

}
