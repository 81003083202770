import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { ExpertSearchForm } from '../_models/expertSearchForm';
import { EXPERT_SEARCH_FORM } from '../_mock/mock-expertSearchForm';

import { Buyer } from '../_models/buyer';
import { Options } from '../_models/options';

@Injectable({
  providedIn: 'root'
})
export class ExpertSearchService {

  private expertSearchFormSubject: BehaviorSubject<ExpertSearchForm>;
  public expertSearchForm: Observable<ExpertSearchForm>;

  constructor(private http: HttpClient) {
    this.expertSearchFormSubject = new BehaviorSubject<ExpertSearchForm>(EXPERT_SEARCH_FORM);
    this.expertSearchForm = this.expertSearchFormSubject.asObservable();
  }

  public get expertSearchFormValue(): ExpertSearchForm {
    return this.expertSearchFormSubject.value;
  }

  public searchExpert(expertSearchForm: ExpertSearchForm, buyer: Buyer) {
    var searchExpertInfo = this.formatExpertSearchInfo(expertSearchForm, buyer);
    return this.http.post(`${environment.apiUrl}/public/buyers/searchExpert`, JSON.stringify(searchExpertInfo));
  }

  public formatExpertSearchInfo(expertSearchForm: ExpertSearchForm, buyer: Buyer): any {
    var buyerInfo = null;
    if (buyer) {
      var buyerCompanySize = "";
      if (buyer.size) {
        buyerCompanySize = buyer.size.value;
      }
      var buyerJobLevel = "";
      if (buyer.jobLevel) {
        buyerJobLevel = buyer.jobLevel.value;
      }
      var buyerJobFunction = "";
      if (buyer.jobFunction) {
        buyerJobFunction = buyer.jobFunction.value;
      }
      buyerInfo = {
        companyName: buyer.companyName,
        regions: [],
        industries: [],
        size: buyerCompanySize,
        positions: [],
        firstName: buyer.firstName,
        lastName: buyer.lastName,
        jobLevel: buyerJobLevel,
        jobFunction: buyerJobFunction,
        email: buyer.email,
        countryCallingCode: buyer.countryCallingCode,
        phoneNumber: buyer.phoneNumber,
        linkedin: buyer.linkedin,
        websiteUrl: buyer.websiteUrl
      }
      buyer.regions.forEach(
        region => buyerInfo.regions.push(region.country)
      )
      buyer.industries.forEach(
        industry => buyerInfo.industries.push(industry.value)
      )
      buyer.positions.forEach(
        position => buyerInfo.positions.push(position.value)
      )
    };

    var expertSearchFormInfo = {
      technologies: [],
      regions: [],
      industries: [],
      coreCompentencies: [],
      reference: [],
      additionalComment: expertSearchForm.additionalComment
    };

    expertSearchForm.technologies.options.forEach(
      technology => {
        if (technology.checked) {
          expertSearchFormInfo.technologies.push(technology.name);
        }
      }
    );

    // Check all the sub options too.
    this.checkSubOptions(expertSearchForm.regions.options, expertSearchFormInfo.regions);

    expertSearchForm.industries.options.forEach(
      industry => {
        if (industry.checked) {
          expertSearchFormInfo.industries.push(industry.name);
        }
      }
    );

    expertSearchForm.coreCompentencies.options.forEach(
      coreCompentency => {
        if (coreCompentency.checked) {
          expertSearchFormInfo.coreCompentencies.push(coreCompentency.name);
        }
      }
    );

    expertSearchForm.reference.companys.forEach(
      company => expertSearchFormInfo.reference.push(company.input)
    );

    var supplierSearchInfo = {
      buyer : buyerInfo,
      searchForm : expertSearchFormInfo
    }

    return supplierSearchInfo;
  }

  private checkSubOptions(options : Options[], names : String[]) {
    options.forEach(
      option => {
        if (option.checked) {
          names.push(option.name);
        } else if (option.subOptions) {
          this.checkSubOptions(option.subOptions, names);
        }
      }
    );
  }

}
