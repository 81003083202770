import { Component, OnInit, Input } from '@angular/core';

import { CheckboxGroup } from '../../_models/checkboxGroup';
import { Options } from '../../_models/options';

@Component({
  selector: 'app-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.css']
})
export class CheckboxGroupComponent implements OnInit {

  @Input() checkboxGroup: CheckboxGroup;

  constructor() { }

  ngOnInit(): void {
  }

  // Check or uncheck all lvl 1 suboptions as lvl 0 option checked
  setAllOptionsLevel1(subOptionLevel0 : Options, checked: boolean) {
    subOptionLevel0.checked = checked;
    if (subOptionLevel0.subOptions) {
      subOptionLevel0.subOptions.forEach(subOptionLevel1 => {
        subOptionLevel1.checked = checked;
        if (subOptionLevel1.subOptions) {
          subOptionLevel1.subOptions.forEach(subOptionLevel2 => subOptionLevel2.checked = checked);
        }
      });
    }
  }

  // Check if all lvl 3 suboptions are checked and set lvl 2 options from the original lvl 0 checkbox
  verifyAllOptionsLevel2(subOptionLevel0 : Options, subOptionLevel1 : Options) {
    // Check sub options level 2
    this.verifyAllSubOptions(subOptionLevel1);
     // Check sub options level 1
    this.verifyAllSubOptions(subOptionLevel0);
  }

  // Check or uncheck all lvl 3 suboptions
  setAllOptionsLevel2AndVerifyAllOptionsLevel1(subOptionLevel0 : Options, subOptionLevel1 : Options, checked: boolean) {
    subOptionLevel1.checked = checked;
    if (subOptionLevel1.subOptions) {
      subOptionLevel1.subOptions.forEach(subOptionLevel2 => subOptionLevel2.checked = checked);
    }

    // If all lvl 1 suboptions are checked, check lvl 0 option
    this.verifyAllSubOptions(subOptionLevel0);
  }

  // Sub CheckBox action
  // If all lvl 1 suboptions are checked, check lvl 0 option
  private verifyAllSubOptions(option : Options) {
    option.checked = option.subOptions != null && option.subOptions.every(subOption => subOption.checked);
  }
}
