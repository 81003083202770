import { Component, OnInit } from '@angular/core';

import { SupplierService } from '../../_services/supplier.service'
import { SupplierSearchResult } from '../../_models/supplierSearchResult';

@Component({
  selector: 'app-webcrawling-result',
  templateUrl: './webcrawling-result.component.html',
  styleUrls: ['./webcrawling-result.component.css']
})
export class WebcrawlingResultComponent implements OnInit {

  displayedColumns: string[] = ['id', 'name', 'location', 'sector', 'matching_rate', 'supplier_detail'];
  dataSource : SupplierSearchResult[];
  dataNumber : number;

  constructor(private supplierService : SupplierService) { }

  ngOnInit(): void {
    this.getSupplierMatchs();
    this.dataNumber = this.dataSource.length;
   }
 
   // Get the webcrawling questions
   getSupplierMatchs(): void {
     /*this.supplierService.getSupplierMatchs()
         .subscribe(suppliers => this.dataSource = suppliers);*/
   }

}
