import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

HomeComponent
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { GeneralTermsComponent } from './general-terms/general-terms.component';
import { PrivacyTermsComponent } from './privacy-terms/privacy-terms.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { BuyerPresentationComponent } from './buyer-presentation/buyer-presentation.component';
import { ExpertPresentationComponent } from './expert-presentation/expert-presentation.component';
import { SupplierPresentationComponent } from './supplier-presentation/supplier-presentation.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

const routes: Routes = [
  { 
    path: 'home',
    component: HomeComponent,
    data: {
      title: 'Supplier Search and Factory Audit - ASKaFOX',
      description:'Powered by global manufacturing intelligence, ASKaFOX uses big data to scout for most relevant suppliers and qualify the factories via nearby experts.'
    }
  },
  { 
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'Global sourcing and supplier qualification - ASKaFOX',
      description:'ASKaFOX consolidates global supplier data, optimizes supply-demand matchmaking and ensures its liability through a glocal expert talent pool.'
    }
  },
  { 
    path: 'blog', 
    component: BlogComponent,
    data: {
      title: 'ASKaFOX Blog - Read about global sourcing and supply chain insight',
      description:'ASKaFOX blog shares news and insight on procurement, innovation and tech ecosystem. Artificial Intelligence. Big Data. Natural language Processing. Supply Chain Management. E-Procurement. Digital Transformation. Global value chain.'
    }
  },
  { 
    path: 'contact', 
    component: ContactComponent,
    data: {
      title: 'Contact us - ASKaFOX',
      description:'ASKaFOX assists you in all sourcing related missions. Contact us or book a demo.'
    }
  },
  { path: 'product', component: ProductComponent },
  { path: 'general-terms', component: GeneralTermsComponent },
  { path: 'privacy-terms', component: PrivacyTermsComponent },
  { path: 'learn-more', component: LearnMoreComponent },
  { path: 'buyer-presentation', component: BuyerPresentationComponent },
  { path: 'expert-presentation', component: ExpertPresentationComponent },
  { path: 'supplier-presentation', component: SupplierPresentationComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },
  // otherwise redirect to dashboard
  { path: '**', redirectTo: '/information/home' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InformationRoutingModule { }
