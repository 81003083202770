import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { Question } from '../_models/question';
import { WEBCRAWLING_QUESTIONS } from '../_mock/mock-questions';
import { EXPERT_WEB_QUESTIONS } from '../_mock/mock-questions';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  constructor() { }

  getWebcrawlingQuestions(): Observable<Question[]> {
    return of(WEBCRAWLING_QUESTIONS);
  }

  getExpertWebQuestions(): Observable<Question[]> {
    return of(EXPERT_WEB_QUESTIONS);
  }

}
