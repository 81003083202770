<main class="main">
  <!-- section start-->
  <section class="section qoute-section bg--lgray pb-0">
    <div class="login-section__bg"><img class="img--bg" src="assets/login_1.jpg" alt="Global supply chain" /></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 d-flex flex-column justify-content-between">
          <div class="heading "><span class="heading__pre-title" i18n>Sign up</span>
            <h3 class="heading__title" i18n>create an account simply</h3><span
              class="heading__layout layout--lgray" i18n>Sign up</span>
            <p class="heading__text"></p>
          </div>
          <div class="contact-trigger top-50 top-lg-0"><img class="contact-trigger__img"
              src="assets/contact_background.png" alt="img" />
            <h4 class="contact-trigger__title" i18n>How we can help you?</h4><a class="button button--white"
              href="#">
              <span i18n>Contact us</span>
              <svg class="icon">
                <use xlink:href="#arrow"></use>
              </svg></a>
          </div>
        </div>
        <div class="col-lg-7 top-50 top-lg-0">
          <div class="form-wrapper">
            <h6 class="login-form__title" i18n>Sign up</h6>
            <div class="row">
              <div class="col-6 bottom-20">
                <span class="form__text" i18n>
                  <a routerLink="/user/login">Log in,</a> if you have an account
                </span>
              </div>
            </div>
            <mat-tab-group id="sign_in" class="col-12 top-20">
              <mat-tab>
                <ng-template mat-tab-label>
                  <label class="form__radio-label bottom-20"><span class="form__label-text" i18n>Buyer</span>
                    <input class="form__input-radio" type="radio" name="option-select" value="option-1"
                      checked="checked" /><span class="form__radio-mask"></span>
                  </label>
                </ng-template>
                <form [formGroup]="buyerForm" (ngSubmit)="onBuyerSubmit()">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="userName" i18n>Email(username)</mat-label>
                        <input matInput placeholder="Company mail only" formControlName="userName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.userName.errors }">
                        <mat-error *ngIf="buyerForm.controls.userName.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="companyName" i18n>Company name</mat-label>
                        <input matInput placeholder="Company name" formControlName="companyName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.companyName.errors }">
                        <mat-error *ngIf="buyerForm.controls.companyName.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="companySummary" i18n>Company summary (A brief introduction about your activities)</mat-label>
                        <textarea class="form__message message--large" formControlName="companySummary"
                          matInput></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="regions" i18n>Region</mat-label>
                        <mat-select formControlName="regions" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.regions.errors }" multiple>
                          <mat-option *ngFor="let regionKey of regionKeys" [value]="region[regionKey]" 
                            [disabled]="buyerFormControl.regions.value?.length > 2 && !buyerFormControl.regions.value?.includes(region[regionKey])">
                            {{region[regionKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="industries" i18n>Industry</mat-label>
                        <mat-select formControlName="industries" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.industries.errors }" multiple>
                          <mat-option *ngFor="let industryKey of industryKeys" [value]="industry[industryKey]"
                            [disabled]="buyerFormControl.industries.value?.length > 2 && !buyerFormControl.industries.value?.includes(industry[industryKey])">
                            {{industry[industryKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="size" i18n>Size</mat-label>
                        <mat-select formControlName="size" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.size.errors }">
                          <mat-option *ngFor="let sizeKey of sizeKeys" [value]="size[sizeKey]">
                            {{size[sizeKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="positions" i18n>Position</mat-label>
                        <mat-select formControlName="positions" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.positions.errors }" multiple>
                          <mat-option *ngFor="let positionKey of positionKeys" [value]="position[positionKey]">
                            {{position[positionKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="firstName" i18n>First name</mat-label>
                        <input matInput placeholder="First name" formControlName="firstName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.firstName.errors }">
                        <mat-error *ngIf="buyerForm.controls.firstName.hasError('required')">
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="lastName" i18n>Last name</mat-label>
                        <input matInput placeholder="Last name" formControlName="lastName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.lastName.errors }">
                        <mat-error *ngIf="buyerForm.controls.lastName.hasError('required')">
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="jobLevel" i18n>Job Level</mat-label>
                        <mat-select formControlName="jobLevel" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.jobLevel.errors }">
                          <mat-option *ngFor="let jobLevelKey of jobLevelKeys" [value]="jobLevel[jobLevelKey]">
                            {{jobLevel[jobLevelKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="jobFunction" i18n>Job Function</mat-label>
                        <mat-select formControlName="jobFunction" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.jobFunction.errors }">
                          <mat-option *ngFor="let jobFunctionKey of jobFunctionKeys" [value]="jobFunction[jobFunctionKey]">
                            {{jobFunction[jobFunctionKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="countryCallingCode" i18n>Country code</mat-label>
                        <input matInput placeholder="Number after 00" size="3" maxLength="3"
                          formControlName="countryCallingCode" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.countryCallingCode.errors }">
                          <mat-error *ngIf="buyerForm.controls.countryCallingCode.hasError('required')" i18n>
                            The field is required.
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="phoneNumber" i18n>Phone number</mat-label>
                        <input matInput placeholder="Phone number" formControlName="phoneNumber" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.phoneNumber.errors }">
                        <mat-icon matSuffix>phone</mat-icon>
                        <mat-error *ngIf="buyerForm.controls.phoneNumber.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="linkedIn" i18n>LinkedIn</mat-label>
                        <input matInput placeholder="LinkedIn" formControlName="linkedIn" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.linkedIn.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="website" i18n>Web site</mat-label>
                        <input matInput placeholder="Web site" formControlName="website" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.website.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="password" i18n>New password</mat-label>
                        <input matInput placeholder="New Password" formControlName="password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.password.errors }"
                          [type]="buyerHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="buyerHide = !buyerHide"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="buyerHide">
                          <mat-icon>{{buyerHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="buyerForm.controls.password.hasError('required')" i18n>
                          Please enter your new password
                        </mat-error>
                        <mat-error *ngIf="buyerForm.controls.password.hasError('minlength') || buyerForm.controls.password.hasError('passwordError')" i18n>
                          The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="confirmPassword" i18n>Confirm password</mat-label>
                        <input matInput placeholder="Confirm password" formControlName="confirmPassword"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.confirmPassword.errors }"
                          [type]="buyerHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="buyerHide = !buyerHide"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="buyerHide">
                          <mat-icon>{{buyerHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="buyerForm.controls.password.hasError('required')" i18n>
                          Please confirm your new password
                        </mat-error>
                        <mat-error *ngIf="buyerForm.hasError('notSame')" i18n>
                          Passwords do not match
                        </mat-error>
                        <mat-error *ngIf="buyerForm.controls.password.hasError('minlength') || buyerForm.controls.password.hasError('passwordError')" i18n>
                          The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 bottom-10">
                      <!--mat-checkbox formControlName="termServiceAccepted" class></mat-checkbox>
                      <mat-label for="termServiceAccepted" class="form__label-text">I agree with <a href="#">Term of services</a>*
                      </mat-label-->
                    </div>
                    <div class="col-12">
                      <button class="login-form__submit" type="submit" i18n>Register</button>
                    </div>
                  </div>
                </form>
              </mat-tab>
              <mat-tab class="col-12 top-20">
                <ng-template mat-tab-label>
                  <label class="form__radio-label bottom-20"><span class="form__label-text" i18n>Expert</span>
                    <input class="form__input-radio" type="radio" name="option-select" value="option-2" /><span
                      class="form__radio-mask"></span>
                  </label>
                </ng-template>
                <form [formGroup]="expertForm" (ngSubmit)="onExpertSubmit()">
                  <div class="row">
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="userName" i18n>Email(Username)</mat-label>
                        <input matInput placeholder="Email" formControlName="userName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.userName.errors }">
                        <mat-error *ngIf="expertForm.controls.userName.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="companyName" i18n>Company name</mat-label>
                        <input matInput placeholder="Company name" formControlName="companyName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.companyName.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="expertSummary" i18n>Expert summary (Tell us about yourself and your experiences)</mat-label>
                        <textarea class="form__message message--large" formControlName="expertSummary"
                          matInput></textarea>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="expertTechnologies" i18n>Technology</mat-label>
                        <mat-select formControlName="expertTechnologies" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertTechnologies.errors }" multiple>
                          <mat-option *ngFor="let technologyLevelKey of technologyLevelKeys" [value]="technology[technologyLevelKey]"
                            [disabled]="expertFormControl.expertTechnologies.value?.length > 2 && !expertFormControl.expertTechnologies.value?.includes(technology[technologyLevelKey])">
                            {{technology[technologyLevelKey]}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="expertForm.controls.expertTechnologies.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="expertRegions" i18n>Region</mat-label>
                        <mat-select formControlName="expertRegions" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertRegions.errors }" multiple>
                          <mat-option *ngFor="let regionKey of regionKeys" [value]="region[regionKey]"
                            [disabled]="expertFormControl.expertRegions.value?.length > 2 && !expertFormControl.expertRegions.value?.includes(region[regionKey])">
                            {{region[regionKey]}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="expertForm.controls.expertRegions.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="expertIndustries" i18n>Industry</mat-label>
                        <mat-select formControlName="expertIndustries" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertIndustries.errors }" multiple>
                          <mat-option *ngFor="let industryKey of industryKeys" [value]="industry[industryKey]"
                            [disabled]="expertFormControl.expertIndustries.value?.length > 2 && !expertFormControl.expertIndustries.value?.includes(industry[industryKey])">
                            {{industry[industryKey]}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="expertForm.controls.expertIndustries.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="expertCoreCompetencies" i18n>Core Competency</mat-label>
                        <mat-select formControlName="expertCoreCompetencies" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.expertCoreCompetencies.errors }" multiple>
                          <mat-option *ngFor="let coreCompetencyKey of coreCompetencyKeys" [value]="coreCompetency[coreCompetencyKey]"
                            [disabled]="expertFormControl.expertCoreCompetencies.value?.length > 2 && !expertFormControl.expertCoreCompetencies.value?.includes(coreCompetency[coreCompetencyKey])">
                            {{coreCompetency[coreCompetencyKey]}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="expertForm.controls.expertCoreCompetencies.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12">
                      <mat-label for="references" i18n>References :</mat-label>
                      <div formGroupName="references">
                        <div class="row">
                          <div class="col-4">
                            <mat-form-field appearance="fill">
                              <mat-label for="company1" i18n>Company 1</mat-label>
                              <input matInput placeholder="Company 1" formControlName="company1" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && expertForm['controls'].references['controls'].company1.errors }">
                            </mat-form-field>
                          </div>
                          <div class="col-4">
                            <mat-form-field appearance="fill">
                              <mat-label for="company2" i18n>Company 2</mat-label>
                              <input matInput placeholder="Company 2" formControlName="company2" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && expertForm['controls'].references['controls'].company2.errors }">
                            </mat-form-field>
                          </div>
                          <div class="col-4">
                            <mat-form-field appearance="fill">
                              <mat-label for="company3" i18n>Company 3</mat-label>
                              <input matInput placeholder="Company 3" formControlName="company3" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && this.expertForm['controls'].references['controls'].company3.errors }">
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="firstName" i18n>First name</mat-label>
                        <input matInput placeholder="First name" formControlName="firstName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.firstName.errors }">
                          <mat-error *ngIf="expertForm.controls.firstName.hasError('required')" i18n>
                            The field is required.
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="lastName" i18n>Last name</mat-label>
                        <input matInput placeholder="Last name" formControlName="lastName" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.lastName.errors }">
                          <mat-error *ngIf="expertForm.controls.lastName.hasError('required')" i18n>
                            The field is required.
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="jobLevel" i18n>Job Level</mat-label>
                        <mat-select formControlName="jobLevel" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.jobLevel.errors }">
                          <mat-option *ngFor="let jobLevelKey of jobLevelKeys" [value]="jobLevel[jobLevelKey]">
                            {{jobLevel[jobLevelKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="jobFunction" i18n>Job Function</mat-label>
                        <mat-select formControlName="jobFunction" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.jobFunction.errors }">
                          <mat-option *ngFor="let jobFunctionKey of jobFunctionKeys" [value]="jobFunction[jobFunctionKey]">
                            {{jobFunction[jobFunctionKey]}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="countryCallingCode" i18n>Country code</mat-label>
                        <input matInput placeholder="Number after 00" size="3" maxLength="3"
                          formControlName="countryCallingCode" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.countryCallingCode.errors }">
                          <mat-error *ngIf="expertForm.controls.countryCallingCode.hasError('required')" i18n>
                            The field is required.
                          </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="phoneNumber" i18n>Phone number</mat-label>
                        <input matInput placeholder="Phone number" formControlName="phoneNumber" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.phoneNumber.errors }">
                        <mat-icon matSuffix i18n>phone</mat-icon>
                        <mat-error *ngIf="expertForm.controls.phoneNumber.hasError('required')" i18n>
                          The field is required.
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="linkedIn" i18n>LinkedIn</mat-label>
                        <input matInput placeholder="LinkedIn" formControlName="linkedIn" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.linkedIn.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="yearsExperience" i18n>Years of experience</mat-label>
                        <input matInput placeholder="Years of experience" formControlName="yearsExperience" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.yearsExperience.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="password" i18n>New password</mat-label>
                        <input matInput placeholder="New Password" formControlName="password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.password.errors }"
                          [type]="expertHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="expertHide = !expertHide"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="expertHide">
                          <mat-icon>{{expertHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="expertForm.controls.password.hasError('required')" i18n>
                          Please enter your new password
                        </mat-error>
                        <mat-error *ngIf="expertForm.controls.password.hasError('minlength') || expertForm.controls.password.hasError('passwordError')" i18n>
                          The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-6">
                      <mat-form-field appearance="fill">
                        <mat-label for="confirmPassword" i18n>Confirm password</mat-label>
                        <input matInput placeholder="Confirm password" formControlName="confirmPassword"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.confirmPassword.errors }"
                          [type]="expertHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="expertHide = !expertHide"
                          [attr.aria-label]="'Hide password'" [attr.aria-pressed]="expertHide">
                          <mat-icon>{{expertHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                        <mat-error *ngIf="expertForm.controls.password.hasError('required')" i18n>
                          Please confirm your new password
                        </mat-error>
                        <mat-error *ngIf="expertForm.hasError('notSame')" i18n>
                          Passwords do not match
                        </mat-error>
                        <mat-error *ngIf="expertForm.controls.password.hasError('minlength') || expertForm.controls.password.hasError('passwordError')" i18n>
                          The password should contain minimum 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, 1 symbol
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 bottom-10">
                      <!--mat-checkbox formControlName="termServiceAccepted" class></mat-checkbox>
                      <mat-label for="termServiceAccepted" class="form__label-text">I agree with <a href="#">Term of services</a>*
                      </mat-label-->
                    </div>
                    <div class="col-12">
                      <button class="login-form__submit" type="submit" i18n>Register</button>
                    </div>
                  </div>
                </form>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>