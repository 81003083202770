import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { BlogItem } from '../_models/blogItem';
import { BLOG_ITEMS } from '../_mock/mock-blogItems';

@Injectable({
  providedIn: 'root'
})
export class BlogItemService {

  constructor() { }

  getBlogItems(): Observable<BlogItem[]> {
    return of(BLOG_ITEMS);
  }
}
