<main class="main">
		
  <!--section start-->
  <section class="section Account-Profil"><img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
        <div class="row">
          <div class="col-lg-5 location-item__lower">
            <h5 class="contacts__title" i18n>Expert Profile Page</h5>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Compagny name :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">{{expert.companyName}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Technologies :</h6>
              <ul *ngFor="let technology of expert.expertTechnologies" class="list list--check list--reset">
                <li class="list__item">{{technology.value}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Regions :</h6>
              <ul *ngFor="let region of expert.expertRegions" class="list list--check list--reset">
                <li class="list__item">{{region.country}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Industries :</h6>
              <ul *ngFor="let industry of expert.expertIndustries" class="list list--check list--reset">
                <li class="list__item">{{industry.value}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Core competencies :</h6>
              <ul *ngFor="let coreCompetencySingle of expert.expertCoreCompetencies" class="list list--check list--reset">
                <li class="list__item">{{coreCompetencySingle.value}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Years of experience :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item" *ngIf="expert.yearsOfExperience">{{expert.yearsOfExperience}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>References :</h6>
              <ul *ngFor="let expertReference of expert.expertReferences" class="list list--check list--reset">
                <li class="list__item">{{expertReference.reference}}</li>
              </ul>
            </div>
          </div>
          <!-- Right Block-->
          <div class="col-lg-5 location-item__lower">
            <div class="col-lg-12 top-60">
              <h6 class="location-item__subtitle" i18n>Contact person :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">{{expert.firstName}} {{expert.lastName}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Expert summary :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">{{expert.expertSummary}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Job level :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item" *ngIf="expert.jobLevel">{{expert.jobLevel.value}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Job Function :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item" *ngIf="expert.jobFunction">{{expert.jobFunction.value}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Email :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">{{expert.email}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Phone :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">+{{expert.countryCallingCode}} {{expert.phoneNumber}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>LinkedIn :</h6>
              <ul class="list location-list list--reset">
                <li class="list__item">{{expert.linkedin}}</li>
              </ul>
            </div>
            <div class="col-lg-12 top-30">
              <h6 class="location-item__subtitle" i18n>Expert status :</h6>
              <ul class="list list--check list--reset">
                <li class="list__item" *ngIf="expert.expertChartSigned" i18n>Expert Chart signed</li>
                <li class="list__item" *ngIf="expert.emailChecked" i18n>Email checked</li>
                <li class="list__item" *ngIf="expert.phoneChecked" i18n>Phone checked</li>
                <li class="list__item" *ngIf="expert.linkedInVerified" i18n>LinkedIn verified</li>
                <li class="list__item" *ngIf="expert.missionPerformed" i18n>Mission performed on askafox.com</li>
              </ul>
            </div>
          </div>
          <button class="button button--green top-20" routerLink="/expert/account-modify" i18n>Modify
            <svg class="icon">
              <use xlink:href="#arrow"></use>
            </svg>
          </button>
          <!--button class="button button--green top-20 left-20" routerLink="/expert/account-resetpwd">Change password
            <svg class="icon left-20">
              <use xlink:href="#arrow"></use>
            </svg>
          </button-->
        </div>
    </div>
  </section>
  <!-- section end-->
</main>