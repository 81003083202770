<main class="main">
  <!-- section start-->
  <section class="hero-block" style="padding: 0 0 70px 0;">
    <picture>
      <source srcset="assets/become_expert.jfif" media="(min-width: 992px)"/><img class="img--bg" src="assets/become_expert.jfif" alt="img"/>
    </picture>
    <div class="hero-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item"><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title">Become our expert</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgray" style="padding: 70px 0;">
    <div class="container">
      <div class="row bottom-70">
        <div class="col-12">
          <div class="heading heading--center"><span class="heading__pre-title">What we do</span>
            <h3 class="heading__title">We value your know-how</h3><span class="heading__layout">Solutions</span>
          </div>
        </div>
      </div>
      <div class="row offset-70">
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO3.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Turn your expertise to value</h6>
            <p class="advantages-item__text">Sell your knowledge for unlimited times<br>
              Trade off your know-how for rewards on industry-specific assignments</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO3.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Work freely with passion</h6>
            <p class="advantages-item__text">Only truly complex matters need one-on-one attention<br>
              Choose your project-based work on daily basis and pursue the praises that you well deserve</p>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="advantages-item text-center">
            <div class="advantages-item__img"><img src="assets/ASKAFOX_PICTO3.png" alt="icon"/></div>
            <h6 class="advantages-item__subtitle">Birds of a feather flock together</h6>
            <p class="advantages-item__text">Build a name for yourself in your specific industry<br>
              Come distinguish through contribution to community and personal branding </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section" style="padding: 70px 0;">
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-12 top-50 ">
          <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
            <h3 class="heading__title">Leverage the network</h3>
          </div>        
        </div>
        <div class=" row col-12">
            <div class="col-sm-6 col-lg-5 col-xl-6">
              <h6 class="bottom-20">Features</h6>
              <ul class="list list--check list--reset">
                <li class="list__item">Tell us who you are and what you excel at</li>
                <li class="list__item">Showcase your credentials and success</li>
                <li class="list__item">Choose your locations and types of missions</li>
                <li class="list__item">Deliver your expertise and make a happy client</li>
              </ul>
            </div>
             <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/become_expert_1.jfif" alt="img"/></div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section bg--lgrays" style="padding: 70px 0;">
    <div class="container">
      <div class="row flex-column-reverse flex-xl-row align-items-end">
        <div class="col-xl-6 text-center text-xl-left"><img class="truck-img" src="assets/become_expert_2_v1.jfif" alt="img"/></div>
        <div class="col-xl-6 top-50 top-xl-0">
          <div class="heading bottom-20"><span class="heading__pre-title">How we deliver</span>
            <h3 class="heading__title">Trust the matchmaking</h3>
          </div>
          <div class="row top-40">
            <div class="col-sm-6 col-lg-5 col-xl-10">
              <h6 class="bottom-20">Features</h6>
              <ul class="list list--check list--reset">
                <li class="list__item">It's "augmented". The reason the client finds you is because you "fit" together</li>
                <li class="list__item">A thorough capability analyze is done to target your category, functional expertise and geographic coverage</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section start-->
  <section class="section faq">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 class="faq__title">Q & A</h3>
          <div *ngFor="let qA of questionAndAnwsers">
            <mat-accordion class="accordion accordion--primary" multi>
              <mat-expansion-panel class="accordion__text-block" (afterExpand)="expand()">
                <mat-expansion-panel-header>
                  <div class="accordion__title-block">
                    <h5 class="accordion__title">{{qA.question}}</h5>
                  </div>
                </mat-expansion-panel-header>
                  <div [innerHTML]="qA.anwser"></div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>