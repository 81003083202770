<main class="main">
  <!-- section start-->
  <section class="section qoute-section bg--lgray pb-0">
    <div class="login-section__bg"><img class="img--bg" src="assets/login_1.jpg" alt="Global supply chain" /></div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 d-flex flex-column justify-content-between">
          <div class="heading "><span class="heading__pre-title" i18n>Login</span>
            <h3 class="heading__title" i18n>Create an account simply</h3><span
              class="heading__layout layout--lgray" i18n>Login</span>
            <p class="heading__text"></p>
          </div>
          <div class="contact-trigger top-50 top-lg-0"><img class="contact-trigger__img"
              src="assets/contact_background.png" alt="img" />
            <h4 class="contact-trigger__title" i18n>How we can help you?</h4><a class="button button--white"
              href="#">
              <span i18n>Contact us</span>
              <svg class="icon">
                <use xlink:href="#arrow"></use>
              </svg></a>
          </div>
        </div>
        <div class="col-lg-7 top-50 top-lg-0">
          <div class="form-wrapper">
            <h5 class="bottom-20" i18n>Log in</h5>
            <div class="row align-items-center">
              <div class="col-12 bottom-20">
                <span class="form__text" i18n>
                  <a routerLink="/user/registry" (click)="signup()">Sign up,</a>
                  if you don’t have an account
                </span>
              </div>
              <mat-tab-group id="sign_in" class="col-12 top-20">
                <mat-tab>
                  <ng-template mat-tab-label>
                    <label class="form__radio-label bottom-20"><span class="form__label-text" i18n>Buyer</span>
                      <input class="form__input-radio" type="radio" name="option-select" value="option-1"
                        checked="checked" /><span class="form__radio-mask"></span>
                    </label>
                  </ng-template>
                  <form [formGroup]="buyerForm" (ngSubmit)="onBuyerSubmit()">
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="username" i18n>User name (Email)</mat-label>
                        <input matInput placeholder="User name" formControlName="username" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.username.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="password" i18n>Password</mat-label>
                        <input matInput placeholder="Password" formControlName="password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && buyerFormControl.password.errors }"
                          [type]="buyerHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="buyerHide = !buyerHide" [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="buyerHide">
                          <mat-icon>{{buyerHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                    <div class="col-6 top-30">
                      <button type="submit" [disabled]="loading" class="button button--green" type="submit" i18n>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Log in
                        <svg class="icon">
                          <use xlink:href="#arrow"></use>
                        </svg>
                      </button>
                    </div>
                    <div class="col-6 top-10"><span class="form__text"><a routerLink="/user/forgetpassword" i18n>I forgot my
                      password</a></span></div>
                  </form>
                  <!--div class="col-xl-6">
                    <label class="form__checkbox-label"><span class="form__label-text">Remember me</span>
                      <input class="form__input-checkbox" type="checkbox" checked="checked" /><span
                        class="form__checkbox-mask"></span>
                    </label>
                  </div-->
                </mat-tab>
                <mat-tab class="col-12 top-20">
                  <ng-template mat-tab-label>
                    <label class="form__radio-label bottom-20"><span class="form__label-text" i18n>Expert</span>
                      <input class="form__input-radio" type="radio" name="option-select" value="option-2" /><span
                        class="form__radio-mask"></span>
                    </label>
                  </ng-template>
                  <form [formGroup]="expertForm" (ngSubmit)="onExpertSubmit()">
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="username" i18n>User name (Email)</mat-label>
                        <input matInput placeholder="User name" formControlName="username" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.username.errors }">
                      </mat-form-field>
                    </div>
                    <div class="col-12">
                      <mat-form-field appearance="fill">
                        <mat-label for="password" i18n>Password</mat-label>
                        <input matInput placeholder="Password" formControlName="password" class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && expertFormControl.password.errors }"
                          [type]="expertHide ? 'password' : 'text'">
                        <button mat-icon-button matSuffix (click)="expertHide = !expertHide" [attr.aria-label]="'Hide password'"
                          [attr.aria-pressed]="expertHide">
                          <mat-icon>{{expertHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                      </mat-form-field>
                    </div>
                    <div class="col-12 top-30">
                      <button [disabled]="loading" class="button button--green" type="submit" i18n>
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Log in
                        <svg class="icon">
                          <use xlink:href="#arrow"></use>
                        </svg>
                      </button>
                    </div>
                    <div class="col-6 top-10"><span class="form__text"><a routerLink="/user/forgetpassword" i18n>I forgot my
                      password</a></span></div>
                  </form>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>