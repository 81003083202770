import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-terms',
  templateUrl: './general-terms.component.html',
  styleUrls: ['./general-terms.component.css']
})
export class GeneralTermsComponent implements OnInit {

  mainslides = [
    {
      img: "assets/promo_6.jpg",
      title : "I am Buyer",
      description : "Je trouver quoi dans dans aska pour repond à mon besoin",
      link : "/information/buyer-presentation"
    },
    {
      img: "assets/promo_2.jpg",
      title : "I am Expert",
      description : "Je trouver quoi dans dans aska pour repond à mon besoin",
      link : "/information/expert-presentation"
    },
    {
      img: "assets/promo_3.jpg",
      title : "I am Supplier",
      description : "Je trouver quoi dans dans aska pour repond à mon besoin",
      link : "/information/supplier-presentation"
    }
  ];

  subslides = [
    {
      img: "assets/AskaFox-3.svg",
      title : "I am Buyer",
      index : 0
    },
    {
      img: "assets/AskaFox-3.svg",
      title : "I am Expert",
      index : 1
    },
    {
      img: "assets/AskaFox-3.svg",
      title : "I am Supplier",
      index : 2
    }
  ];

  subslidessStatus = [
    true,
    false,
    false
  ]

  mainsliderConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "fade" : true,
    "adaptiveHeight": true,
    "infinite" : true,
    "speed": 1200
  };

  constructor() { }

  goToIndex(index : number, mainSlider) {
    mainSlider.slickGoTo(index);
    //Indicate which subslide is active
    for (var i = 0; i < this.subslidessStatus.length; i++) {
      if (i==index) {
        this.subslidessStatus[i] = true;
      } else {
        this.subslidessStatus[i] = false;
      }
    }
  }

  ngOnInit(): void {
  }

}
