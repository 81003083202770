<h1>Expert Profile Page</h1>
<div *ngIf=" expert && mission">
  <h2>Expert name : {{expert.user_name | uppercase}}</h2>
  <div>
    <span>Buy ID : </span>{{mission.buyer_id}}
  </div>
  <div>
    <span>Missoin type : </span>{{mission.missoinType}}
  </div>
  <div>
    <span>Deadline : </span>{{mission.deadline}}
  </div>
  <div>
    <span>Description : </span>{{mission.description}}
  </div>
  <div>
    <span>status : </span>{{mission.status}}
  </div>
  <div>
    <span>Current budget : </span>{{mission.budget}}
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label for="newBudget">New Budet</label>
      <input type="text" formControlName="newBudget" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.newBudget.errors }" />
      <div *ngIf="submitted && f.newBudget.errors" class="invalid-feedback">
          <div *ngIf="f.newBudget.errors.required">New budget is required</div>
      </div>
    </div>
    <p>
      Form Status: {{ form.status }}
    </p>
    <div class="form-group">
      <button [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Send
      </button>
    </div>
  </form>
</div>