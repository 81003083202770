import { Component, OnInit } from '@angular/core';

import { BlogItemService } from '../../_services/blog-item.service';
import { BlogItem } from '../../_models/blogItem';

import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  blogItems : BlogItem[];

  constructor(
    private blogItemService : BlogItemService,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService
    ) { }

  ngOnInit(): void {
    this.getBlogItems();
  }

  // Get the webcrawling questions
  getBlogItems(): void {
    this.blogItemService.getBlogItems()
        .subscribe(blogItems => this.blogItems = blogItems);
  }

  linkClick() {
    this.googleAnalyticsEventsService.emitEvent("blog", "external_link_click", "External link click", 0);
  }

}
