import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import configLocal  from './../assets/config.json';

import { SeoService } from './_services/seo.service';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'askafox';
  private config: {version: string};
  //keep refs to subscriptions to be able to unsubscribe later
  private statusChangeSubscription!: Subscription;

  constructor(
    public router: Router,
    private activatedRoute : ActivatedRoute,
    private httpClient: HttpClient,
    private ccService: NgcCookieConsentService,
    private seoService : SeoService){}

  ngOnInit() {
    // Config page_path for pages
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          gtag('config', 'G-9B3BEBM5MD', 
            {
              'page_path': event.urlAfterRedirects
            }
            );
          }
        }
    )
    // Force refresh with version update
    this.config = configLocal;
    const headers = new HttpHeaders()
    .set('Cache-Control', 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0')
    .set('Pragma', 'no-cache')
    .set('Expires', '0');

    this.httpClient.get<{version: string}>('/assets/config.json', {headers})
    .subscribe(config => {
      if (config.version !== this.config.version) {
        this.httpClient
          .get("", { headers, responseType: "text" })
          .subscribe(() => location.reload())
      }
    });
    
    // subscribe to cookieconsent observables to react to main events
    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        //Button parameter is link to status dismiss
        if (event.status == 'dismiss') {
          console.log("Paramétrer cookies")
        } else if (event.status == 'allow') {
          console.log("Cookies acceptées");
        } else {
          console.log("Cookies refusées");
        }
      });
    // SEO setting on route
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
     )
     .subscribe((event) => {
       this.seoService.updateTitle(event['title']);
       this.seoService.updateDescription(event['description'])
     });
  }
}
