import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ContactFormService } from '../../_services/contact-form.service';
import { GoogleAnalyticsEventsService } from '../../_services/google-analytics-events.service';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';

import { JobFunction, JobLevel, Region, Industry } from '../../_models/enumBO';

interface Gender {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  loading = false;
  submitted = false;

  // Récupérer les enum de form
  jobFunction = JobFunction;
  jobFunctionKeys =  Object.keys(this.jobFunction);
  jobLevel = JobLevel;
  jobLevelKeys = Object.keys(this.jobLevel);
  region = Region;
  regionKeys = Object.keys(this.region);
  industry = Industry;
  industryKeys = Object.keys(this.industry);

  genders: Gender[] = [
    {value: 'M', viewValue: 'Mr'},
    {value: 'F', viewValue: 'Mme'}
  ];

  constructor(
    private route: ActivatedRoute,
    private router : Router,
    private builder: FormBuilder,
    private contactFormService : ContactFormService,
    private dialog: MatDialog,
    private googleAnalyticsEventsService : GoogleAnalyticsEventsService
  ) { }

  ngOnInit(): void {
    /*
      gender: string;
      first_name: string;
      last_name: string;
      company_name: string;
      email: string;
      country_calling_code: number;
      phone_number: number;
      job_function: JobFunction;
      job_level: JobLevel;
      region: Region;
      industry: Industry;
      message: string;
      receive_news: boolean;
      terms_accept: boolean;
    */
    this.form = this.builder.group({
      gender : [''],
      first_name : ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      last_name : ['', [Validators.required, Validators.pattern("[a-zA-ZÀ-ÿ ]*")]],
      company_name: [''],
      email: ['', [Validators.required, Validators.email]],
      country_calling_code: ['', [Validators.required, Validators.pattern("[0-9]*"), Validators.maxLength(3)]],
      phone_number: ['', [Validators.required, Validators.pattern("[0-9]*")]],
      job_function: [''],
      job_level: [''],
      region: [''],
      industry: [''],
      message: [''],
      receive_news: ['', Validators.required],
      //terms_accept: ['', Validators.requiredTrue]
    });
  }

  // convenience getter for easy accesss to form fields
  get f() { return this.form.controls; }

  onSubmit() {

    this.googleAnalyticsEventsService.emitEvent("contact", "send_message", "Contact page send message", 0);

    if (!this.loading) {
      this.loading = true;
      this.contactFormService.addContactForm(this.form.value).subscribe(
        data => {
          console.log("contactForm sent");
          this.addContactFormOK();
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  addContactFormOK(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Your information is sent!',
      message: 'Thanks for contacting us. We will get back to you within 24 hours!'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
