<main class="main" *ngIf="expert">
  <!--section start-->
  <section class="section bg--lgray">
    <img class="contacts__bg" src="assets/contacts-map.png" alt="map"/>
    <div class="container">
      <div class="col-lg-12">
        <div class="location-item">
          <div class="location-item__lower">
            <h4 class="location-item__title"><a href="#">Self-introduction :</a></h4>
            <span >{{expert.expert_summary}}</span>
            <div class="row top-30">
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">Company name :</h6>
                <ul class="list list--check  list--reset">
                  <li class="list__item">{{expert.company_name}}</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">Technologies :</h6>
                <ul *ngFor="let technology of expert.technologies" class="list list--check list--reset ">
                  <li class="list__item">{{technology}}</li>
                </ul>
              </div>
            </div>
            <div class="row top-30">
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">Regions :</h6>
                <ul *ngFor="let region of expert.regions" class="list list--check list--reset ">
                  <li class="list__item">{{region}}</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">Core competencies :</h6>
                <ul *ngFor="let coreCompetencySingle of expert.coreCompetency" class="list list--check list--reset ">
                  <li class="list__item">{{coreCompetencySingle}}</li>
                </ul>
              </div>
            </div>
            <div class="row top-30">
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">Industries :</h6>
                <ul *ngFor="let industry of expert.industries" class="list list--check  list--reset">
                  <li class="list__item">{{industry}}</li>
                </ul>
              </div>
              <div class="col-sm-6 top-30 top-sm-0">
                <h6 class="location-item__subtitle">Years of experience :</h6>
                <ul class="list list--check  list--reset">
                  <li class="list__item">{{expert.yearsExperience}}</li>
                </ul>
              </div>
            </div>
            <div class="row top-30">
              <div class="col-sm-6">
                <h6 class="location-item__subtitle">References :</h6>
                <ul *ngFor="let reference of expert.references" class="list list--check  list--reset">
                  <li class="list__item">{{reference}}</li>
                </ul>
              </div>
              <div class="col-sm-6 top-30 top-sm-0">
                <h6 class="location-item__subtitle">Expert status :</h6>
                <ul class="list list--check  list--reset">
                  <li *ngIf="expert.user_status.user_chart_signed" class="list__item">Expert Chart signed</li>
                  <li *ngIf="expert.user_status.email_checked" class="list__item">Email checked</li>
                  <li *ngIf="expert.user_status.phone_checked" class="list__item">Phone checked</li>
                  <li *ngIf="expert.user_status.linkedIn_verified" class="list__item">LinkedIn verified</li>
                  <li *ngIf="expert.user_status.is_customer" class="list__item">Mission performed on Askafox.com</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row"><div class="col-xl-3 col-md-push-3">
            <div type="submit" class="button button--filled" [routerLink]="['/buyer/send-mission', expert.expert_id]"><span>Send a mission proposal</span> 
            </div>
          </div>
          <div class="col-xl-2 col-md-pull-9">
            <div type="submit" class="button button--green" (click)="goBack()"><span>Go Back</span>
              <svg class="icon">
                <use xlink:href="#arrow"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>