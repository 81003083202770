<main class="main mobile-top">
  <section class="section blog">
    <div class="container">
      <div class="row">
        <div class="col-md-10 mx-auto">
          <h1>Privacy policy</h1>
          <h2>POLITIQUE DE CONFIDENTIALITÉ ET DE GESTION DES COOKIES</h2>
          <h2>(Dernière mise à jour : 25 octobre 2021)</h2>
          <p>La société ASKAFOX, dont le siège social est situé 2bis Rue Tête d'Or, 69006 LYON, France, en sa qualité de responsable de traitement (ci-après « ASKAFOX »), représentée par Monsieur Hanyin CHEN, Président, attache une grande importance à la protection et au respect de votre vie privée.  </p>
          <p>La présente politique de confidentialité, conformément au Règlement n°2016-679 du 27 avril 2016 relatif à la protection des données à caractère personnel, a pour but de vous transmettre des précisions sur les engagements et mesures pratiques pris par ASKAFOX concernant la collecte et l’utilisation des informations et données à caractère personnel que vous êtes amenés à nous fournir par le biais de la plateforme logicielle Saas ASKAFOX que nous vous proposons.</p>
          <p>Cette présente politique de confidentialité vous permet d’obtenir des informations sur les catégories de données à caractère personnel susceptibles d’être collectées directement via la plateforme ou indirectement via les cookies que nous installons et la manière dont nous les utilisons, quels en sont les destinataires et avec qui nous les partageons, la durée pendant laquelle nous les conservons et comment nous les protégeons, et enfin les droits dont vous disposez sur vos données à caractère personnel.</p>
          <p>La présente politique de confidentialité est susceptible d’être modifiée à tout moment par ASKAFOX. Toute modification de la présente politique de confidentialité prendra effet lors de la publication de sa version modifiée lors de votre prochaine connexion à la plateforme.</p>
          <h3>ARTICLE 1.	À QUOI LA PRÉSENTE POLITIQUE S’APPLIQUE-T-ELLE ? </h3>
          <p>La présente politique de confidentialité s’applique à tous les traitements de données à caractère personnel, mis en œuvre par ASKAFOX via la plateforme, qu’il s’agisse de données à caractère personnel déclarée directement par les utilisateurs via la plateforme ou collectées indirectement via l’usage de cookies.</p>
          <p>La présente politique de confidentialité ne s’applique pas aux sites internet de tiers, y compris à ceux éventuellement mentionnés sur notre plateforme, ou autre support, notamment par l’intermédiaire d’un lien internet.</p>
          <h3>ARTICLE 2.	DÉFINITIONS </h3>
          <p>« Acheteur » « Vous » « Vos » « Votre » : désigne tout acheteur agissant au nom et pour le compte d’un professionnel au sens du Code de la consommation, souhaitant s’inscrire sur la Plateforme aux fins de bénéficier des services et fonctionnalités qu’elle propose, et ayant accepté la présente Politique de Confidentialité lors de la collecte de ses Données à Caractère Personnel ;</p>
          <p>« Compte » : désigne le compte ouvert gratuitement au nom d’un Utilisateur lors de son inscription sur le Site, lui permettant d’utiliser la Plateforme et d’accéder aux services et fonctionnalités qu’elle propose ;</p>
          <p>« Données à Caractère Personnel » ou « Données »: désigne toute donnée identifiant directement ou indirectement un Utilisateur, au sens du Règlement ;</p>
          <p>« Expert » « Vous » « Vos » « Votre » : désigne tout expert agissant au nom et pour le compte d’un professionnel au sens du Code de la consommation dans le domaine de l’audit souhaitant s’inscrire sur la Plateforme aux fins de bénéficier des services et fonctionnalités qu’elle propose, et ayant accepté la présente Politique de Confidentialité lors de la collecte de ses Données à Caractère Personnel ;</p>
          <p>« Traitement(s) » : consiste en une ou des opérations réalisées sur des Données à Caractère Personnel comme notamment la collecte, l’enregistrement, l’utilisation, la transmission ou la communication de ces Données à Caractère Personnel ;</p>
          <p>« Plateforme » : désigne la plateforme logicielle ASKAFOX, accessible en mode Saas, éditée et fournie par ASKAFOX ;</p>
          <p>« Politique de Confidentialité » : désigne la présente politique de confidentialité de la Plateforme ;</p>
          <p>« Règlement » : désigne le Règlement n°2016-679 du 27 avril 2016 relatif à la protection des données à caractère personnel ;</p>
          <p>« Site » : désigne le site internet https://www.askafox.com édité et fourni par la Société, à partir duquel est accessible la Plateforme ;</p>
          <p>« Utilisateur » « Vous » « Vos » « Votre » : désigne indistinctement l’ensemble des utilisateurs de la Plateforme à savoir les Acheteurs et les Experts.</p>
          <h3>ARTICLE 3.	DESCRIPTION DES TRAITEMENTS DE DONNÉES À CARACTÈRE PERSONNEL DONT NOUS SOMMES RESPONSABLES</h3>
          <p>&nbsp;</p>
          <h4>3.1.	LORSQUE LA SOCIÉTÉ ADRESSE À UN EXPERT UN COURRIER ÉLECTRONIQUE POUR RECCUEILLIR SON CONSENTEMENT DANS LE CAS D’UNE COLLECTE INDIRECTE VIA DES SOURCES TIERCES (PAR EXEMPLE : VIA LINKEDIN)</h4>
          <table >
            <tbody>
              <tr>
                <td >
                    <p ><strong>Nature des Donn&eacute;es et cat&eacute;gorie des personnes concern&eacute;es&nbsp;</strong></p>
                </td>
                <td >
                    <p ><strong>Base L&eacute;gale</strong></p>
                </td>
                <td >
                    <p ><strong>Finalit&eacute;s</strong></p>
                </td>
                <td >
                    <p ><strong>Destinataire externe (sous-traitant)</strong></p>
                </td>
                <td >
                    <p ><strong>Dur&eacute;e de conservation</strong></p>
                </td>
              </tr>
              <tr>
                <td >
                  <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Expert</strong> : nom, pr&eacute;nom</p>
                  <p ><strong>Donn&eacute;es de contactabilit&eacute; d&rsquo;un Expert</strong> : num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel&nbsp;</p>
                  <p >&nbsp;</p>
                </td>
                <td >
                  <p >Consentement</p>
                </td>
                <td >
                  <div >
                    <ul >
                        <li >Recueillir le consentement d&rsquo;un Expert &agrave; &ecirc;tre sollicit&eacute; par la Soci&eacute;t&eacute;&nbsp;</li>
                        <li >Enregistrer les Donn&eacute;es d&rsquo;un Expert dans la base de donn&eacute;es de la Soci&eacute;t&eacute;&nbsp;</li>
                    </ul>
                  </div>
                </td>
                <td >
                  <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante : h&eacute;bergement des Donn&eacute;es de l&rsquo;Expert et de la Plateforme&nbsp;</p>
                </td>
                <td >
                  <p >3 ans &agrave; compter du dernier contact</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>3.2.	LORS DE LA CRÉATION ET MISE À JOUR DU COMPTE D’UN UTILISATEUR</h4>
          <table >
            <tbody>
              <tr>
                <td >
                    <p ><strong>Nature des Donn&eacute;es et cat&eacute;gorie des personnes concern&eacute;es&nbsp;</strong></p>
                </td>
                <td >
                    <p ><strong>Base L&eacute;gale</strong></p>
                </td>
                <td >
                    <p ><strong>Finalit&eacute;s</strong></p>
                </td>
                <td >
                    <p ><strong>Destinataire externe (sous-traitant)</strong></p>
                </td>
                <td >
                    <p ><strong>Dur&eacute;es de conservation</strong></p>
                </td>
              </tr>
              <tr>
                <td >
                    <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Utilisateur</strong> : nom, pr&eacute;nom</p>
                    <p ><strong>Donn&eacute;es de contactabilit&eacute; d&rsquo;un Utilisateur</strong> : num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel&nbsp;</p>
                    <p ><strong>Donn&eacute;es de connexion d&rsquo;un Utilisateur</strong> : mot de passe, identifiant</p>
                </td>
                <td >
                    <p >Contrat</p>
                </td>
                <td >
                    <p >Cr&eacute;er et g&eacute;rer Votre Compte en ligne et Vous permettre d&rsquo;acc&eacute;der aux services fournis par la Plateforme&nbsp;</p>
                </td>
                <td >
                    <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante : h&eacute;bergement des Donn&eacute;es d&rsquo;un Utilisateur et de la Plateforme&nbsp;</p>
                </td>
                <td >
                    <p >Jusqu&rsquo; &rsquo;&agrave; la suppression de Votre Compte et au plus tard 3 ans &agrave; compter de la derni&egrave;re activit&eacute; de Votre Compte&nbsp;</p>
                </td>
              </tr>
              <tr>
                <td >
                    <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Acheteur :&nbsp;</strong>nom, pr&eacute;nom</p>
                    <p ><strong>Donn&eacute;es de contactabilit&eacute; d&rsquo;un Acheteur :&nbsp;</strong>num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel</p>
                    <p ><strong>Donn&eacute;es de facturation d&rsquo;un Acheteur</strong> : adresse de facturation</p>
                </td>
                <td >
                    <p >Contrat</p>

                </td>
                <td >
                    <p >G&eacute;rer, ex&eacute;cuter, suivre la commande d&rsquo;un Acheteur&nbsp;</p>
                </td>
                <td >
                    <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante&nbsp;: h&eacute;bergement des Donn&eacute;es d&rsquo;un Acheteur et de la Plateforme</p>
                </td>
                <td >
                    <p >Dur&eacute;e de la relation contractuelle et 3 ans &agrave; compter de la derni&egrave;re commande</p>
                </td>
              </tr>
              <tr>
                <td >
                    <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Expert :&nbsp;</strong>nom, pr&eacute;nom</p>
                    <p ><strong>Donn&eacute;es de contactabilti&eacute; d&rsquo;un Expert :&nbsp;</strong>num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel</p>
                </td>
                <td >
                    <p >Contrat</p>
                </td>
                <td >
                    <p >Organiser la r&eacute;alisation de prestations de service en sous-traitance&nbsp;</p>
                </td>
                <td >
                    <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante&nbsp;: h&eacute;bergement des Donn&eacute;es d&rsquo;un Expert et de la Plateforme</p>
                </td>
                <td >
                    <p >Dur&eacute;e de la relation contractuelle et 3 ans &agrave; compter de la fin de relation contractuelle</p>
                </td>
              </tr>
              <tr>
                <td >
                    <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Utilisateur</strong> : nom, pr&eacute;nom</p>
                    <p ><strong>Donn&eacute;es de contactabilit&eacute; d&rsquo;un Utilisateur</strong> : num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel&nbsp;</p>
                    <p ><strong>Donn&eacute;es de connexion d&rsquo;un Utilisateur</strong> : mot de passe</p>
                </td>
                <td >
                    <p >Obligation l&eacute;gale</p>
                </td>
                <td >
                    <p >G&eacute;rer les demandes de droit d&rsquo;acc&egrave;s, de rectification et d&rsquo;opposition</p>
                </td>
                <td >
                    <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante&nbsp;: h&eacute;bergement des Donn&eacute;es d&rsquo;un Utilisateur et de la Plateforme</p>
                </td>
                <td >
                    <p >De 1 ou 6 ans selon le droit d&rsquo;exercice concern&eacute;</p>
                </td>
              </tr>
              <tr>
                <td >
                  <p ><strong>Donn&eacute;es d&rsquo;identification d&rsquo;un Utilisateur</strong> : nom, pr&eacute;nom</p>
                  <p ><strong>Donn&eacute;es de contactabilit&eacute; d&rsquo;un Utilisateur</strong> : num&eacute;ro de t&eacute;l&eacute;phone, adresse de courriel&nbsp;</p>
                  <p ><strong>Donn&eacute;es de facturation d&rsquo;un Acheteur</strong> : adresse de facturation</p>
                </td>
                <td >
                  <p >Int&eacute;r&ecirc;t l&eacute;gitime</p>
                  <p >Obligations l&eacute;gales</p>
                </td>
                <td >
                  <p >G&eacute;rer les impay&eacute;s et le contentieux et des litiges&nbsp;</p>
                </td>
                <td >
                  <p >OVH, 2, rue Kellermann, 59100 Roubaix, France pour l&rsquo;activit&eacute; de Traitement suivante&nbsp;: h&eacute;bergement des Donn&eacute;es d&rsquo;un Utilisateur et de la Plateforme</p>
                </td>
                <td >
                    <p ><span >Pendant la dur&eacute;e du contentieux avec suppression une fois l&rsquo;ensemble des recours &eacute;puis&eacute;s et la d&eacute;cision devenue ex&eacute;cutoire ou prescription de de l&rsquo;action judiciaire</span><br>&nbsp;</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>&nbsp;</p>
          <h4>3.3.	LORSQUE VOUS NAVIGUEZ SUR NOTRE PLATEFORME PAR L’INTERMÉDIAIRE DE COOKIES TIERS</h4>
          <div class="table-container">
            <table>
              <tbody>
                  <tr>
                      <td>
                          <p><strong>Type de cookie&nbsp;</strong></p>
                      </td>
                      <td>
                          <p><strong>Nom du cookie</strong></p>
                      </td>
                      <td>
                          <p><strong>Cat&eacute;gorie / description du cookie</strong></p>
                      </td>
                      <td>
                          <p><strong>soci&eacute;t&eacute; &eacute;mettrice</strong></p>
                      </td>
                      <td>
                          <p><strong>Sous-traitant (transfert)</strong></p>
                      </td>
                      <td>
                          <p><strong>Dur&eacute;es de vie du cookie</strong></p>
                      </td>
                      <td>
                          <p><strong>Base l&eacute;gale</strong></p>
                      </td>
                      <td>
                          <p><strong>Liens utiles</strong></p>
                      </td>
                      <td>
                          <p><strong>Finalit&eacute;</strong></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Mesure d&rsquo;audience</strong></p>
                      </td>
                      <td>
                          <p>_ga</p>
                      </td>
                      <td>
                          <p><strong>Mesure d&rsquo;audience :&nbsp;</strong>ce cookie est install&eacute; pour distinguer les Utilisateurs</p>
                      </td>
                      <td>
                          <p>Google Analytics</p>
                      </td>
                      <td>
                          <p><span>Google LLC, 1600 Amphitheatre Parway Mountain View, CA 94043 &Eacute;tats-Unis; Google LLC &nbsp; &eacute;tablit aux &Eacute;tats-Unis a mis en place des Clauses Contractuelles Types (CCT) avec certains pays vers lesquels Vos Donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre transf&eacute;r&eacute;es : https://privacy.google.com/businesses/compliance/?hl=fr</span></p>
                      </td>
                      <td>
                          <p>6 mois<span>&nbsp;</span></p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                          <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></p>
                          <p>P<span>our obtenir plus d&rsquo;informations sur la fa&ccedil;on dont Google Analytics g&egrave;re Vos Donn&eacute;es, veuillez consulter&nbsp;:</span></p>
                          <p><a href="https://support.google.com/analytics/answer/6004245"><span>https://support.google.com/analytics/answer/6004245</span></a></p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Mesure d&rsquo;audience</strong></p>
                      </td>
                      <td>
                          <p>_gid</p>
                      </td>
                      <td>
                          <p><strong>Mesure d&rsquo;audience</strong> : ce cookie est install&eacute; pour distinguer les Utilisateurs</p>
                      </td>
                      <td>
                          <p>Google Analytics</p>
                      </td>
                      <td>
                          <p><span>Google LLC, 1600 Amphitheatre Parway Mountain View, CA 94043 &Eacute;tats-Unis; Google LLC &nbsp; &eacute;tablit aux &Eacute;tats-Unis a mis en place des Clauses Contractuelles Types (CCT) avec certains pays vers lesquels Vos Donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre transf&eacute;r&eacute;es : https://privacy.google.com/businesses/compliance/?hl=fr</span></p>
                      </td>
                      <td>
                          <p>24 heures</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                          <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></p>
                          <p>P<span>our obtenir plus d&rsquo;informationsur la fa&ccedil;on dont Google Analytics g&egrave;re Vos Donn&eacute;es, veuillez consulter&nbsp;:</span></p>
                          <p><a href="https://support.google.com/analytics/answer/6004245"><span>https://support.google.com/analytics/answer/6004245</span></a></p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Mesure d&rsquo;audience</strong></p>
                      </td>
                      <td>
                          <p>_ga_&lt;container-id&gt;</p>
                      </td>
                      <td>
                          <p><strong>Mesure d&rsquo;audience :&nbsp;</strong>ce cookie est install&eacute; pour maintenir l&rsquo;&eacute;tat de Votre session Utilisateur</p>
                      </td>
                      <td>
                          <p>Google Analytics</p>
                      </td>
                      <td>
                          <p><span>Google LLC, 1600 Amphitheatre Parway Mountain View, CA 94043 &Eacute;tats-Unis; Google LLC &nbsp; &eacute;tablit aux &Eacute;tats-Unis a mis en place des Clauses Contractuelles Types (CCT) avec certains pays vers lesquels Vos Donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre transf&eacute;r&eacute;es : https://privacy.google.com/businesses/compliance/?hl=fr</span></p>
                      </td>
                      <td>
                          <p>2 ans</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                          <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></p>
                          <p>P<span>our obtenir plus d&rsquo;informationsur la fa&ccedil;on dont Google Analytics g&egrave;re Vos Donn&eacute;es, veuillez consulter&nbsp;:</span></p>
                          <p><a href="https://support.google.com/analytics/answer/6004245"><span>https://support.google.com/analytics/answer/6004245</span></a></p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Mesure d&rsquo;audience</strong></p>
                      </td>
                      <td>
                          <p><span>_gac_gb_&lt;container-id&gt;</span></p>
                      </td>
                      <td>
                          <p><strong>Mesure d&rsquo;audience :&nbsp;</strong>ce cookie est install&eacute; pour maintenir l&rsquo;&eacute;tat de Votre session Utilisateur</p>
                      </td>
                      <td>
                          <p>Google Analytics</p>
                      </td>
                      <td>
                          <p><span>Google LLC, 1600 Amphitheatre Parway Mountain View, CA 94043 &Eacute;tats-Unis; Google LLC &nbsp; &eacute;tablit aux &Eacute;tats-Unis a mis en place des Clauses Contractuelles Types (CCT) avec certains pays vers lesquels Vos Donn&eacute;es sont susceptibles d&rsquo;&ecirc;tre transf&eacute;r&eacute;es : https://privacy.google.com/businesses/compliance/?hl=fr</span></p>
                      </td>
                      <td>
                          <p>90 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                          <p><a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></p>
                          <p>P<span>our obtenir plus d&rsquo;informatio sur la fa&ccedil;on dont Google Analytics g&egrave;re Vos Donn&eacute;es, veuillez consulter&nbsp;:</span></p>
                          <p><a href="https://support.google.com/analytics/answer/6004245"><span>https://support.google.com/analytics/answer/6004245</span></a></p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p>{{'hjSessionUser {site_id}'}}</p>
                      </td>
                      <td>
                          <p><strong>Performance&nbsp;</strong>: ce cookie est install&eacute; pour la premi&egrave;re fois lorsqu&rsquo;un Utilisateur arrive sur la Plateforme et est utilis&eacute; pour conserver l&rsquo;identifiant de connexion d&rsquo;un Utilisateur&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                          <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>{{'_hjSession{site_id}'}}</span></p>
                      </td>
                      <td>
                          <p><strong>Performance&nbsp;</strong>: c&rsquo;est un cookie qui contient les Donn&eacute;es de la session en cours d&rsquo;un Utilisateur. Cela signifie que les demandes ult&eacute;rieures dans la fen&ecirc;tre de session seront attribu&eacute;es &agrave; la m&ecirc;me session&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>30 minutes</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjClosedSurveyInvites</span></p>
                      </td>
                      <td>
                          <p><strong>Performance&nbsp;</strong>: il est d&eacute;fini lorsqu&apos;un Utilisateur interagit avec une invitation &agrave; un sondage &agrave; une &nbsp;enqu&ecirc;te par lien externe. Il est utilis&eacute; pour s&apos;assurer que la m&ecirc;me invitation ne r&eacute;appara&icirc;t pas si elle a d&eacute;j&agrave; &eacute;t&eacute; affich&eacute;e</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                        <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                        <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                        <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                        <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, ll nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjDonePolls</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: il est d&eacute;fini une fois qu&apos;un Utilisateur a r&eacute;pondu &agrave; une enqu&ecirc;te &agrave; l&apos;aide du widget d&apos;enqu&ecirc;te sur la Plateforme. Il est utilis&eacute; pour s&apos;assurer que la m&ecirc;me enqu&ecirc;te ne r&eacute;appara&icirc;t pas si elle a d&eacute;j&agrave; &eacute;t&eacute; remplie.</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                        </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjMinimizedPolls</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong> il est d&eacute;fini lorsqu&apos;un Utilisateur r&eacute;duit le widget d&apos;une enqu&ecirc;te sur la Plateforme ; Il est utilis&eacute; pour s&apos;assurer que le widget reste r&eacute;duit lorsque l&apos;Utilisateur navigue sur la Plateforme</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                        </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjShownFeedbackMessage</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong> : il est d&eacute;fini lorsqu&apos;un Utilisateur r&eacute;duit ou termine un feedback entrant. Ainsi, le feedback entrant sera imm&eacute;diatement charg&eacute; comme r&eacute;duit si l&apos;Utilisateur navigue vers une autre page o&ugrave; il est configur&eacute; pour &ecirc;tre affich&eacute;.</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                        </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjSessionTooLarge</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: il permet &agrave; Hotjar d&apos;arr&ecirc;ter la collecte de Donn&eacute;es si une session Utilisateur devient trop importante. Ceci est d&eacute;termin&eacute; automatiquement par un signal du serveur WebSocket si la taille de la session d&eacute;passe la limite.</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjSessionRejected</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: S&apos;il est pr&eacute;sent, ce cookie sera d&eacute;fini sur &apos;1&apos; pour la dur&eacute;e de la session d&apos;un Utilisateur, si Hotjar a rejet&eacute; la connexion de la session &agrave; WebSocket en raison d&apos;une surcharge du serveur. Ce cookie n&apos;est appliqu&eacute; que dans des situations extr&ecirc;mement rares pour &eacute;viter de graves probl&egrave;mes de performance.</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre pPateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjSessionResumed</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: il est d&eacute;fini lorsqu&apos;une session/un enregistrement est reconnect&eacute; aux serveurs Hotjar apr&egrave;s une interruption de la connexion.</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjid</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ce cookie Hotjar est d&eacute;fini lorsque l&rsquo;Utilisateur arrive pour la premi&egrave;re fois sur une page contenant le script Hotjar. Il est utilis&eacute; pour conserver l&apos;identifiant de connexion Utilisateur unique pour la Plateforme, sur le navigateur. Cela garantit que le comportement lors des visites ult&eacute;rieures de la Plateforme sera attribu&eacute; au m&ecirc;me identifiant de connexion d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>365 jours</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjRecordingLastActivity</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: il doit &ecirc;tre trouv&eacute; dans le stockage de la session (par opposition aux cookies). Il est mis &agrave; jour lorsqu&apos;un enregistrement d&apos;Utilisateur commence et lorsque des donn&eacute;es sont envoy&eacute;es par le WebSocket (l&apos;Utilisateur effectue une action que Hotjar enregistre).</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjTLDTest</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: Lorsque le script Hotjar s&apos;ex&eacute;cute, Hotjar essaie de d&eacute;terminer le chemin le plus g&eacute;n&eacute;rique &agrave; utiliser pour les cookies, au lieu du nom d&apos;h&ocirc;te de la page. Ceci est fait pour que les cookies puissent &ecirc;tre partag&eacute;s entre les sous-domaines (le cas &eacute;ch&eacute;ant). Pour le d&eacute;terminer, Hotjar essaie de stocker le cookie _hjTLDTest pour diff&eacute;rentes alternatives de substrats d&apos;URL jusqu&apos;&agrave; ce qu&apos;il &eacute;choue. Apr&egrave;s cette v&eacute;rification, le cookie est supprim&eacute;.</p>


                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjUserAttributesHash</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: les attributs de l&apos;Utilisateur envoy&eacute;s par l&apos;API Hotjar Identify sont mis en cache pour la dur&eacute;e de la session afin de savoir quand un attribut a chang&eacute; et doit &ecirc;tre mis &agrave; jour</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjCachedUserAttributes</span></p>
                      </td>
                      <td>
                          <p><strong>Performance :&nbsp;</strong>:Ce cookie stocke les attributs de l&apos;Utilisateur qui sont envoy&eacute;s par l&apos;API Hotjar Identify, chaque fois que l&apos;Utilisateur n&apos;est pas dans l&apos;&eacute;chantillon. Les attributs collect&eacute;s ne seront enregistr&eacute;s sur les serveurs Hotjar que si l&apos;Utilisateur interagit avec un outil de feedback Hotjar, mais le cookie sera utilis&eacute; ind&eacute;pendamment de la pr&eacute;sence d&apos;un outil de feedback</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjLocalStorageTest</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ce cookie est utilis&eacute; pour v&eacute;rifier si le script de suivi Hotjar peut utiliser le stockage local. S&apos;il le peut, une valeur de 1 est d&eacute;finie dans ce cookie. Les Donn&eacute;es stock&eacute;es dans_hjLocalStorageTest n&apos;ont pas de temps d&apos;expiration, mais elles sont supprim&eacute;es presque imm&eacute;diatement apr&egrave;s leur cr&eacute;ation.</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Moins de 100 ms</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjIncludedInPageviewSample</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ce cookie est d&eacute;fini pour permettre &agrave; Hotjar de savoir si cet Utilisateur est inclus dans l&apos;&eacute;chantillonnage de Donn&eacute;es d&eacute;fini par la limite de pages vues de la Plateforme</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>30 minutes</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjIncludedInSessionSample</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ce cookie est d&eacute;fini pour permettre &agrave; Hotjar de savoir si cet Utilisateur est inclus dans l&apos;&eacute;chantillonnage de Donn&eacute;es d&eacute;fini par la limite de session quotidienne de la Plateforme</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>30 minutes</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjAbsoluteSessionInProgress</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ce cookie est utilis&eacute; pour d&eacute;tecter la premi&egrave;re session de consultation de page d&apos;un Utilisateur. Il s&apos;agit d&apos;un indicateur True/False d&eacute;fini par le cookie.</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>

                      </td>
                      <td>
                          <p>30 minutes</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjFirstSeen</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: ceci est d&eacute;fini pour identifier la premi&egrave;re session d&apos;un nouvel Utilisateur. Il stocke une valeur vrai/faux, indiquant si c&apos;&eacute;tait la premi&egrave;re fois que Hotjar voyait cet Utilisateur. Il est utilis&eacute; par les filtres d&apos;enregistrement pour identifier les sessions des nouveaux Utilisateurs</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjViewportId</span></p>
                      </td>
                      <td>
                          <p><strong>Performance</strong>: Il stocke des informations sur la fen&ecirc;tre d&apos;affichage de l&apos;Utilisateur, telles que la taille et les dimensions</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>&nbsp;</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>

                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, lle nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>

                      </td>
                  </tr>
                  <tr>
                      <td>
                          <p><strong>Performance</strong></p>
                      </td>
                      <td>
                          <p><span>_hjRecordingEnabled</span></p>
                      </td>
                      <td>
                          <p><strong>Performance&nbsp;</strong>: ceci est ajout&eacute; lorsqu&apos;un enregistrement d&eacute;marre et est lu lorsque le module d&apos;enregistrement est initialis&eacute; pour voir si l&apos;Utilisateur est d&eacute;j&agrave; dans un enregistrement dans une session particuli&egrave;re</p>
                      </td>
                      <td>
                          <p>Hotjar script</p>
                      </td>
                      <td>
                          <p>Hotjar Ltd Dragonara Business Center 5th Floor, Dragonara Road, Paceville St Julian&rsquo;s STJ 3141 Malta, Europe : Hotjar Ltd est susceptible de transf&eacute;rer certaines de Vos Donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne avec certains pays vers lesquels Hotjar a mis en place des Clauses Contractuelles Type (CCT)&nbsp;: https://www.hotjar.com/legal/policies/privacy/</p>
                      </td>
                      <td>
                          <p>Pour la dur&eacute;e de la session d&rsquo;un Utilisateur</p>
                      </td>
                      <td>
                          <p>Consentement</p>
                      </td>
                      <td>
                        <p><a href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies">https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies</a></p>
                          <p>Pour obtenir plus d&rsquo;information sur la fa&ccedil;on dont Hotjar g&egrave;re Vos Donn&eacute;es, veuillez consulter :&nbsp;</p>
                          <p><a href="https://www.hotjar.com/legal/policies/terms-of-service">https://www.hotjar.com/legal/policies/terms-of-service</a></p>
                      </td>
                      <td>
                          <p><span>Aux fins de mesurer&nbsp;l&apos;exp&eacute;rience de nos Utilisateurs (par exemple, le nombre de liens cliqu&eacute;s sur notre Plateforme, et plus g&eacute;n&eacute;ralement les habitudes de navigation etc.). Cela nous permet d&rsquo;am&eacute;liorer notre service</span></p>
                      </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <p>Les Traitements mis en œuvre par ASKAFOX répondent à des finalités explicites, légitimes et déterminées. </p>
          <p>Les Données des Utilisateurs collectées directement ou indirectement par nos soins sont les Données que Vous décidez de communiquer sciemment à ASKAFOX par le biais du formulaire de création de Compte de la Plateforme ou via les cookies.</p>
          <p>Si Vous remplissez un formulaire aux fins de créer Votre Compte via notre Plateforme, certaines informations demandées ont un caractère obligatoire et sont marquées d’un astérisque. Certaines informations ont un caractère facultatif et sont dépourvues d’astérisque. Si Vous choisissez de ne pas communiquer les informations obligatoires, ASKAFOX ne sera pas en mesure de traiter Votre demande.</p>
          <h3>ARTICLE 4.	QU’EST-CE QU’UN COOKIE ET POURQUOI EST-CE UTILE ? </h3>
          <p>Un cookie est un petit fichier de données que certains sites écrivent sur Votre disque dur lorsque Vous les visitez. Ce n’est ni un programme exécutable, ni un virus. Un fichier de cookie contient des informations telles que l’identifiant des Utilisateurs, ainsi que des informations sur Votre parcours sur la Plateforme. Un cookie ne peut lire les informations sur Votre disque dur, ni lire les autres fichiers de cookies créés par d’autres sites. La Plateforme installe les cookies pour Vous fournir les fonctionnalités de base de la Plateforme.  Ils sont installés aux fins aux fins d’obtenir des informations sur le trafic généré sur la Plateforme, et aux fins d’assurer la sécurité de la Plateforme.</p>
          <p>Nous sommes ainsi en mesure de déterminer l’usage que Vous faites des informations mises à Votre disposition sur la Plateforme, ainsi que pour vérifier la pertinence de notre schéma de navigation avec ses informations. Ces cookies ne permettent pas de Vous identifier. ASKAFOX ne fait pas de corrélation entre les cookies et les Données que Vous avez pu fournir, et ne vend pas ces Données à une tierce partie. Dans le cas où ASKAFOX déciderait d’installer des cookies sur sa Plateforme e elle informera préalablement l’Utilisateur de la finalité des cookies et recueillera, si besoin, son consentement.</p>
          <p>Vous pouvez refuser les cookies, ou être informé lorsque la Plateforme veut écrire un cookie en réglant les préférences de Votre navigateur.</p>
          <p>Tout Utilisateur est informé par un bandeau se trouvant en bas de son écran que des informations relatives à sa navigation sur la Plateforme sont susceptibles d’être enregistrées sur son terminal d’ordinateur dans des fichiers dénommés « cookies ». La présente Politique de Confidentialité et de gestion des cookies permet de comprendre quels dispositifs sont mis en œuvre par ASKAFOX sur la Plateforme.</p>
          <h3>ARTICLE 5.	VOS DONNÉES SONT ELLES TRANSFÉRÉES EN DEHORS DE L’UNION EUROPÉENNE ? </h3>
          <p>Les Données que nous collectons peuvent être transférées hors de l’Union européenne. Lorsque nous les transférons en dehors de l’Union européenne, nous le faisons conformément au Règlement et aux lois applicables en matière de protection des Données : </p>
          <ul>
            <li>Soit en transférant les Données à une entreprise tierce située dans un pays ayant fait l’objet d’une décision d’adéquation de la Commission européenne certifiant que ledit pays dispose d’un niveau de protection adéquat par rapport aux exigences du Règlement ;</li>
            <li>Soit en mettant en place des Clauses Contractuelles Types approuvées par la Commission européenne ;</li>
            <li>Soit en ayant recours à des règles contraignantes d’entreprise (Binding Corporate Rules) ;</li>
            <li>Soit en recourant à toutes garanties visées à l’article 46 du Règlement.</li>
          </ul>
          <h3>ARTICLE 6.	QUI SONT LES DESTINATAIRES DE VOS DONNÉES ? À QUI VOS DONNÉES PERSONNELLES SONT - ELLES TRANSFÉRÉES ET POURQUOI ? </h3>
          <p>Les destinataires internes de vos Données sont les services concernés de ASKAFOX.</p>
          <p>Vos Données sont transférées à des entreprises tierces avec lesquelles nous travaillons, qui peuvent avoir accès à vos Données et notamment celles listées ci-avant à l’article 3 en qualité de sous-traitant.</p>
          <p>Nous ne transmettons à ces entreprises tierces que les Données qui leur sont strictement nécessaires aux fins d’effectuer les prestations qui leur sont confiées par ASKAFOX. Lesdites entreprises tierces ne peuvent utiliser les Données à d’autres fins et nous nous assurons que des mesures techniques et organisationnelles appropriées sont mises en place pour protéger Vos Données.</p>
          <p>Ces entreprises tierces ont elle-même recours à des sous-traitants ultérieurs avec lesquelles celles-ci travaillent aux fins d’effectuer les prestations qui leur sont confiées par ASKAFOX. Vos Données sont transférées à ces sous-traitants ultérieurs, qui peuvent avoir accès à Vos Données.</p>
          <p>ASKAFOX fait également appel à des sous-traitants pour des prestations techniques ciblées pouvant donner lieu à l’accès à certaines de Vos Données ; si le Traitement effectué par lesdits prestataires donne lieu au transfert de vos Données à Caractère Personnel en dehors de l’Union Européenne, la Société garantit un tel transfert par la mise en place d’un engagement de conformité au Règlement. </p>
          <h3>ARTICLE 7.	COMBIEN DE TEMPS VOS DONNÉES À CARACTÈRE PERSONNEL SONT CONSERVÉES ? </h3>
          <p>Vos Données sont conservées aussi longtemps que nécessaire dans le respect de chacune des finalités définies dans la présente Politique de Confidentialité et dans les limites définies et autorisées par le Règlement et lois applicables. Les durées de conservation de vos Données sont indiquées ci-avant à l’article 3.</p>
          <p>Au terme des durées de conservation de Vos Données, celles-ci sont soit effacées soit anonymisées de manière à pouvoir les exploiter sans porter atteinte à vos droits. Cependant, vos Données pourront être archivées au-delà des durées de conservation pour les besoins de la constatation et de la poursuite d’infractions pénales dans le seul but de permettre la mise à disposition de vos Données à l’autorité judiciaire. L’archivage signifie que vos Données ne seront plus consultables en ligne sur notre Plateforme mais seront extraites et conservées sur un support sécurisé dont l’accès sera restreint. </p>
          <h3>ARTICLE 8.	LES MESURES DE PROTECTION ET DE GESTION DE VOS DONNÉES À CARACTÈRE PERSONNEL</h3>
          <p>ASKAFOX assure la sécurité de Vos Données en mettant en place une protection des Données par l’utilisation de moyens de sécurisation physiques et logiques.</p>
          <p>Lors de la transmission de Vos Données sous forme électronique via notre Plateforme, Vos Données sont transmises sur notre serveur via une connexion Internet sécurisée (SSL). Les courriels qui sont envoyés à, ou depuis la Plateforme, en particulier, peuvent être peu sûrs ; c’est pourquoi nous Vous recommandons d’être très vigilant si Vous décidez de nous envoyer ces informations par courriel. Il Vous incombe également de protéger les mots de passe, les numéros ID ou d’autres dispositifs d’accès spécifiques lors de leur utilisation.</p>
          <p>Nous Vous informons, qu’à l’exception des Données visées ci-dessus, la Plateforme n’est pas destinée à recevoir des informations à caractère confidentiel de Votre part.</p>
          <p>La présente Politique de Confidentialité pourra être amenée à évoluer en fonction du contexte légal et réglementaire.</p>
          <p>Enfants et mineurs : notre Plateforme ne sont pas destinées aux enfants et personnes de moins de 18 ans. Si Vous n’êtes pas encore majeur, veuillez n’utiliser la présente Plateforme qu’avec l’accord d’une personne investie de l’autorité parentale. Nous ne collectons aucune Donnée de mineurs de manière intentionnelle si les personnes investies de l’autorité parentale n'ont pas donné leur accord. Si nous découvrons que des mineurs fournissent des Données sans l’accord des personnes investies de l’autorité parentale, nous supprimerons ces Données.</p>
          <p>Données sensibles : À moins que nous ne Vous le demandions ou ne Vous y invitions expressément, nous Vous prions de ne pas nous remettre ou nous transmettre, au moyen de Plateforme ou par d’autres voies, de Données dites « sensibles », c’est-à-dire de Données sur vos origines raciale ou ethnique, sur vos opinions politiques, vos convictions philosophiques ou religieuses ou d’autres croyances, sur Votre santé, Votre sphère intime, un passé criminel (par exemple : sur des poursuites et sanctions administratives et pénales), sur Votre appartenance syndicale ou sur des mesures de l’aide sociale.</p>
          <h3>ARTICLE 9.	QUELS SONT LES TRAITEMENTS POUR LESQUELS NOUS NE SOMMES PAS RESPONSABLES DE TRAITEMENT ? </h3>
          <p>Des partenaires tiers peuvent collecter et traiter une partie de Vos Données pour leurs propres besoins. Dans ce cas, ces tiers collectent des Données directement depuis Votre navigateur et le Traitement de vos Données est soumis à leurs propres chartes de protection des données à caractère personnel. Nous n’en sommes pas responsables. </p>
          <h3>ARTICLE 10.	VOS DROITS SUR VOS DONNÉES À CARACTÈRE PERSONNEL</h3>
          <p>En application du Règlement et dans ses limites, Vous bénéficiez des droits d’accès, de rectification, d’effacement ou de portabilité de vos Données ainsi que du droit de Vous opposer au Traitement de Vos Données (ou en demander la limitation), et du droit d’introduire une réclamation auprès de la Commission Nationale de l’Informatique et des Libertés (CNIL), et de donner des directives concernant le sort de Vos Données après Votre décès, dans les limites autorisées par le Règlement et les lois applicables en matière de protection des Données. </p>
          <p>Lorsque le Traitement de vos Données repose sur Votre consentement, Vous pouvez le retirer à tout moment. </p>
          <p>Vous pouvez également Vous opposer à la réception de nos communications par courriel électronique en cliquant sur le lien de désinscription ou en suivant les instructions de désabonnement mentionnées dans nos messages.</p>
          <p>Pour exercer Vos droits, veuillez nous contacter à l’adresse suivante : <a href="mailto:contact@askafox.com">contact@askafox.com</a></p>
        </div>
      </div>
    </div>
  </section>
</main>