import { Component, OnInit } from '@angular/core';

import { ExpertService } from '../../_services/expert.service';
import { Expert } from '../../_models/expert';

@Component({
  selector: 'app-expert-account',
  templateUrl: './expert-account.component.html',
  styleUrls: ['./expert-account.component.css']
})
export class ExpertAccountComponent implements OnInit {

  expert : Expert;

  constructor(private expertService: ExpertService) { }

  ngOnInit(): void {
    this.expert = this.expertService.expertValue;
  }

}
