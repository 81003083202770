import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule }from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';

import { BuyerRoutingModule } from './buyer-routing.module';
import { BuyerProfileComponent } from './buyer-profile/buyer-profile.component';
import { SendMissionComponent } from './send-mission/send-mission.component';
import { DialogSendQuizComponent } from './dialog-send-quiz/dialog-send-quiz.component';
import { BuyerAccountComponent } from './buyer-account/buyer-account.component';
import { BuyerAccountResetpwdComponent } from './buyer-account-resetpwd/buyer-account-resetpwd.component';
import { BuyerAccountModifyComponent } from './buyer-account-modify/buyer-account-modify.component';

@NgModule({
  declarations: [
    BuyerProfileComponent,
    SendMissionComponent,
    DialogSendQuizComponent,
    BuyerAccountComponent,
    BuyerAccountResetpwdComponent,
    BuyerAccountModifyComponent
  ],
  imports: [
    CommonModule,
    BuyerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTableModule,
    MatChipsModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    MatSelectModule
  ]
})
export class BuyerModule { }
