import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactFormService {

  constructor(private http: HttpClient) { }

  public addContactForm(contactForm: any) {
    return this.http.post(`${environment.apiUrl}/public/addContactForm`, JSON.stringify(contactForm));
  }
}
