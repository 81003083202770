<main class="main mobile-top">
  <!-- section start-->
  <section class="hero-block">
      <picture>
      <source srcset="assets/blog.jfif" media="(min-width: 992px)"/><img class="img--bg" src="assets/blog.jfif" alt="Factory"/>
    </picture>
    <div class="hero-block__layout"></div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="align-container">
            <div class="align-container__item"><span class="hero-block__overlay">Aska</span>
              <h1 class="hero-block__title" i18n>Blog</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
  <!-- section Blog start-->
  <section class="section blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="row">
            <div *ngFor="let blogItem of blogItems" class="col-md-6 col-xl-4">
              <div class="blog-item">
                <div class="blog-item__img"><img class="img--bg" src="{{blogItem.imagePath}}" alt="img"/></div><span class="blog-item__category">{{blogItem.category}}</span>
                <h6 class="blog-item__title"> <a href="{{blogItem.link}}" (click)="linkClick()">{{blogItem.title}}</a></h6>
                <div class="blog-item__text">{{blogItem.title}}</div>
                <div class="blog-item__details"><span>{{blogItem.date | date:'mediumDate'}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- section end-->
</main>