import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { SupplierService } from '../../_services/supplier.service'
import { Supplier } from '../../_models/supplier';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.css']
})
export class SupplierDetailComponent implements OnInit {

  supplier: Supplier;

  constructor(
    private route: ActivatedRoute,
    private supplierService: SupplierService,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getSupplier();
  }

  getSupplier(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    //this.supplierService.getSupplier(id).subscribe(supplierMatch => this.supplier = supplierMatch.supplier);
  }

  goBack(): void {
    this.location.back();
  }
}
