import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { BuyerService } from '../_services/buyer.service';

@Injectable({ providedIn: 'root' })
export class BuyerAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private buyerService: BuyerService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const buyer = this.buyerService.buyerValue;
        if (buyer) {
            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/buyer/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}