import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(private http: HttpClient) { }

  public addNewsletterEmail(emailForm: any) {
    return this.http.post(`${environment.apiUrl}/public/addNewsletterEmail`, JSON.stringify(emailForm));
  }

}
