import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { AuthService } from '../../_services/auth.service';
import { DialogMessageComponent } from '../../shared/dialog-message/dialog-message.component';


@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  forgetPasswordForm: FormGroup;
  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService : AuthService,
    private route: ActivatedRoute,
    private router : Router,
    private dialog : MatDialog
  ) { }

  ngOnInit(): void {
    // Buyer form
    this.forgetPasswordForm = this.formBuilder.group({
      userName: ['', [Validators.required, Validators.email]],
    });
  }

  // convenience getter for easy accesss to form fields
  get forgetPasswordFormControl() { return this.forgetPasswordForm.controls; }

  onSubmit(): void {
    if (!this.loading && this.forgetPasswordForm.valid) {
      this.loading = true;

      //Add current URL
      var value = this.forgetPasswordForm.value;
      value["url"] = window.location.origin;

      this.authService.forgetPassword(value).subscribe(
        data => {
          console.log("email sent");
          if (data) {
            this.router.navigate(['/user/login'], { relativeTo: this.route });
            window.scrollTo(0, 0);
            this.emailResetPasswordSent();
          } else {
            this.errorResetPassword();
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
        }
      )
    }
  }

  private emailResetPasswordSent(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      title: 'Email sent',
      message: 'An email has been sent to reset your password.'
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }

  private errorResetPassword(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    var messageErreur = "Something went wrong...Contact us or try later please.";

    dialogConfig.data = {
      title: 'Oops!',
      message: messageErreur
    };

    this.dialog.open(DialogMessageComponent, dialogConfig);
  }
}
