import { BlogItem } from '../_models/blogItem';

export const BLOG_ITEMS: BlogItem[] = [
  { 
    item_id: 1, 
    category : 'Sourcing',
    title: 'What does China think of MADE IN CHINA?',
    link: 'https://www.linkedin.com/pulse/what-does-china-think-made-hanyin-chen/?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_post_details%3B8xD6q%2FHxQ0WS3Ap65DK%2BzQ%3D%3D',
    description : "An annual study on major manufacturing power around the world",
    imagePath: "assets/blogs/What_does_china_think_of_made_in_china.jpg", 
    date: new Date("2021-01-26") 
  },
  { 
    item_id: 2, 
    category : 'Sourcing',
    title: 'Covid 19: Practical guide on procuring medical supply from China',
    link: 'https://www.linkedin.com/pulse/covid-19-practical-advice-procuring-medical-supply-from-hanyin-chen/',
    description : "A field report on how to get your hands on safe and sure supply during a crisis",
    imagePath: "assets/blogs/Practical_guide_on_procuring_medical_supply_from_china.jpg", 
    date: new Date("2020-04-16") 
  },
  { 
    item_id: 3, 
    category : 'Sourcing',
    title: 'Two faces of future procurement agent',
    link: 'https://www.linkedin.com/pulse/two-faces-future-procurement-agent-hanyin-chen/',
    description : "A look into the future of procurement",
    imagePath: "assets/blogs/Two_faces_of_future_procurement_agent.jpg", 
    date: new Date("2020-11-18") 
  }
];
